<template>
  <div>
    <div class="section-chart">
      <div class="container">
        <div class="div-flex">
          <div class="col-left">
            <label>PRIZE</label>
          </div>
          <div class="col-right txt-right">
            <select v-model="selectedCategory">
              <option
                v-for="(category, cat) of categories"
                :key="cat"
                :value="category.value"
                >{{ category.label }}</option
              >
            </select>
          </div>
        </div>
        <!--tabs start-->
        <div class="tab-chart-wrpr">
          <div class="container" style="padding:0px;">
            <div class="tab-chart">
              <button
                v-for="(heading, h) of categoryWinners"
                :key="h"
                :class="h === 0 ? ' active tablinks-btn' : 'tablinks-btn'"
                @click="openChart($event, convertToSlug(heading.heading))"
              >
                {{ heading.heading }}
              </button>
            </div>
            <div
              v-for="(winners, w) of categoryWinners"
              :id="convertToSlug(winners.heading)"
              :key="w"
              :class="w === 0 ? ' block' : ''"
              class="tab-chartcontent"
            >
              <div class="chart-bar-wrpr">
                <div
                  class="bar-position-2"
                  :class="
                    topThreeWinners(winners.winners)[2] ? '' : 'disable-bar'
                  "
                >
                  <p class="prize">{{ topThreeWinners(winners.winners)[2] }}</p>
                  <h1>2<span>nd</span></h1>
                </div>
                <div
                  class="bar-position-1"
                  :class="
                    topThreeWinners(winners.winners)[1] ? '' : 'disable-bar'
                  "
                >
                  <p class="prize">{{ topThreeWinners(winners.winners)[1] }}</p>
                  <h1>1<span>st</span></h1>
                </div>
                <div
                  class="bar-position-3"
                  :class="
                    topThreeWinners(winners.winners)[3] ? '' : 'disable-bar'
                  "
                >
                  <p class="prize">{{ topThreeWinners(winners.winners)[3] }}</p>
                  <h1>3<span>rd</span></h1>
                </div>
              </div>
              <div class="list-prize">
                <ul>
                  <li
                    v-for="(rWinner, rw) in remainingWinners(winners.winners)"
                    :key="rw"
                  >
                    <span class="num"
                      >{{ rw.replace("winner_", "") }}
                      <div class="pos-number">th</div></span
                    ><span class="money">{{ rWinner }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!--tabs end-->
      </div>
    </div>
    <EventLinks :links="getLinks" :new_version="true" v-if="showLink" />
  </div>
</template>

<script>
import { commonMixin } from "@/mixins/commonMixin";
import EventLinks from "@/components/event/EventLinks";
import _ from "lodash";

export default {
  name: "PriceWidget",
  components: { EventLinks },
  props: ["winners", "eventData", "showLink"],
  mixins: [commonMixin],

  data() {
    return {
      categories: [],
      firstCategory: "general",
      selectedCategory: "general",
    };
  },
  computed: {
    makeWinners() {
      return [];
    },

    categoryWinners() {
      const getWinnerData = this.winners.map((a) => ({ ...a }));
      const winnersData = _.filter(getWinnerData, (w) => {
        if (this.selectedCategory == "general") {
          return (
            w?.event_category == "general" ||
            typeof w?.event_category == "undefined"
          );
        }
        return w?.event_category == this.selectedCategory;
      });

      const data = [];

      winnersData.forEach((w) => {
        const heading = w.heading;
        const category = w?.event_category || "general";

        delete w.heading;
        delete w.event_category;

        data.push({
          heading: heading,
          category: category,
          winners: w,
        });
      });

      return data;
    },

    getLinks() {
      return this.eventData.links;
      // return {
      //   result: this.event.event_result_link,
      //   guideline: this.event.technical_guide_link,
      //   ruleset: this.event.ruleset_link,
      //   requirements: this.event.requirements,
      //   course_details: this.event.course_details,
      // }
    },
  },

  mounted() {
    this.getCategories();
  },

  watch: {
    categories(v) {
      if (v.length > 0) {
        this.selectedCategory = v[0].value;
      }
    },
  },

  methods: {
    convertToSlug(text) {
      if (!text) return;
      return text
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    },

    topThreeWinners(winners) {
      return JSON.parse(
        JSON.stringify({
          1: winners.winner_1,
          2: winners.winner_2,
          3: winners.winner_3,
        })
      );
    },

    getCategories() {
      const categories = [];
      const getWinnerData = this.winners;

      getWinnerData.map((winner) => {
        const category = winner?.event_category || "general";
        const categoryElement = {
          label: category.replace(/-/g, " ").replace(/^./, function(x) {
            return x.toUpperCase();
          }),
          value: category,
        };

        const categoryExist = categories.findIndex((x) => x.value == category);

        categoryExist === -1
          ? categories.push(categoryElement)
          : console.log("object already exists");
      });

      this.categories = categories;
    },

    remainingWinners(winners) {
      delete winners.winner_1;
      delete winners.winner_2;
      delete winners.winner_3;

      return winners;
    },

    openChart(evt, cityName) {
      var i, tabContent, tablinks;
      tabContent = document.getElementsByClassName("tab-chartcontent");
      for (i = 0; i < tabContent.length; i++) {
        tabContent[i].style.display = "none";
      }
      tablinks = document.getElementsByClassName("tablinks-btn");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }
      document.getElementById(cityName).style.display = "block";
      evt.currentTarget.className += " active";
    },
  },
};
</script>

<style scoped>
.default-heading {
  font-size: 34px !important;
}

.nav-tabs .nav-item {
  width: 100%;
}

.prize-section .nav-tabs .nav-link {
  border-bottom: 1px solid wheat;
}

.prize-section .list-inline-item:not(:last-child) {
  margin-right: 60px;
}

.prize-section .stretch-list .list-inline-item:not(:last-child) {
  padding: 0 20px;
}
.block {
  display: block;
}
</style>
