<template>
  <footer class="footer-wrap clearfix" v-if="setting">
      <div class="max-width-wrap clearfix">
        <div class="tablet-wrap clearfix">
            <div class="column-one">
                <a :href="wpURL" class="logo">
                    <img src="/assets/images/my-whoosh-logo-white-v3.png" alt="MyWhoosh Logo">
                </a>
                <div class="address-wrap">
                  <img src="/assets/images/pin-icon-v1.svg" alt="" class="pin-icon">
                  <div class="address">
                    <p>Abu Dhabi, UAE</p>
                  </div>
                </div>
                <div class="email-wrap">
                  <img src="/assets/images/mail-icon-v1.svg" alt="" class="mail-icon">
                  <a href="mailto:support@mywhoosh.com" class="email">Support@mywhoosh.com</a>
                </div>
                <div class="copyright-wrap-desktop">
                  <p>&copy; 2023 MyWhoosh. All rights reserved.</p>
                </div>
            </div>
            <div class="column-two">
                <h2>Navigation</h2>
                <a class="page-hyper-link" :href="`${wpWebUrl()}/how-it-works/?login=${loggedIn}&profile=${getUserName}`">Get Started</a>
                <a class="page-hyper-link" :href="`${wpWebUrl()}/about/?login=${loggedIn}&profile=${getUserName}`">About Us</a>
                <a class="page-hyper-link" :href="`${wpWebUrl()}/getting-started-mywhoosh-cycling-app/?login=${loggedIn}&profile=${getUserName}`">Download App</a>
                <a class="page-hyper-link" :href="`${wpWebUrl()}/terms-and-conditions/?login=${loggedIn}&profile=${getUserName}`">Terms &amp; Conditions</a>
                <a class="page-hyper-link" :href="`${wpWebUrl()}/privacy-policy/?login=${loggedIn}&profile=${getUserName}`">Privacy Policy</a>
            </div>
            <div class="column-three">
                <h2>Community</h2>
                <a class="page-hyper-link" :href="`${wpWebUrl()}/events/?login=${loggedIn}&profile=${getUserName}`">Events</a>
                <a class="page-hyper-link" :href="`${wpWebUrl()}/blog/?login=${loggedIn}&profile=${getUserName}`">Blog</a>
                <a class="page-hyper-link" :href="`${wpWebUrl()}/routes/?login=${loggedIn}&profile=${getUserName}`">Routes</a>
                <a class="page-hyper-link" :href="`${wpWebUrl()}/results/?login=${loggedIn}&profile=${getUserName}`">Results</a>
                <a class="page-hyper-link" :href="`${wpWebUrl()}/media/?login=${loggedIn}&profile=${getUserName}`">Media</a>
                <a class="page-hyper-link" :href="`${wpWebUrl()}/contact-us/?login=${loggedIn}&profile=${getUserName}`">Contact Us</a>
            </div>
        </div>
        <div class="column-four clearfix">
            <h2>Find Us On Social Media</h2>
            <a class="social-link" :href="setting.facebook">
                <img src="/assets/images/facebook-logo-v1.svg" alt="Facebook">
            </a>
            <a class="social-link" href="setting.twitter">
                <img src="/assets/images/twitter-logo-v1.svg" alt="Twitter">
            </a>
            <a class="social-link" :href="setting.linkedin">
                <img src="/assets/images/linkedin-logo-v1.svg" alt="LinkedIn">
            </a>
            <a class="social-link" :href="setting.instagram">
                <img src="/assets/images/instagram-logo-v1.svg" alt="Instagram">
            </a>
            <a class="social-link" :href="setting.youtube">
                <img src="/assets/images/youtube-logo-v1.svg" alt="YouTube">
            </a>
            <a class="social-link" :href="setting.strava">
                <img src="/assets/images/strava-logo-v1.svg" alt="Strava">
            </a>
        </div>
        <div class="copyright-wrap-mobile">
            <p>&copy; 2023 MyWhoosh. All rights reserved.</p>
        </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from "vuex";
import { commonMixin } from "@/mixins/commonMixin";
import { email, required } from "vuelidate/lib/validators";

export default {
  name: "FooterComponent",
  mixins: [commonMixin],

  data() {
    return {
      submitted: false,
      form: {
        email: null,
      },
      year: new Date().getFullYear(),
      wpURL: process.env.VUE_APP_WEB_URL,
    };
  },

  computed: {
    ...mapGetters("auth", ["user", "loggedIn"]),
    ...mapGetters("page", ["setting"]),
    ...mapGetters("common", ["getSubscribed"]),

    getUserName() {
      if (this.user) {
        return this.user.firstname + " " + this.user.lastname;
      }

      return null;
    },
  },

  validations: {
    form: {
      email: { required, email },
    },
  },

  mounted() {
    this.$store.dispatch("page/setting");
  },

  methods: {
    submitHandler() {
      this.submitted = true;
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;

      this.$store.dispatch("common/subscribe", this.form);
    },
  },
};
</script>