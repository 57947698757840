<template>
  <div v-if="page">
    <Banner :page="page" />

    <section class="description events-page-wrap clearfix">
      <div class="container">
        <div class="row">
          <section class="col-md-12" v-html="page.details">
          </section>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Banner from '@/components/shared/Banner'

export default {
  name: 'Privacy',
  components: {
    Banner
  },

  computed: {
    ...mapGetters('page', ['page']),
  },

  destroyed() {
    document.body.classList.remove('contact')
  },

  mounted() {
    document.body.id = 'Whoosh';
    document.body.classList.add('contact')
    this.$store.dispatch('page/view', 'privacy-policy');
  },

}
</script>
