<template>
  <div class="outer">

    <div class="error" v-if="!isClientExists && !getLoading">Client not found.</div>

    <div class="main" v-if="isClientExists && !getLoading">
      <h1>Authorize "TRAINERDAY" to "MYWHOOSH"</h1>
      <h3>"HTTPS://TRAINERDAY.COM"</h3>

      <div class="action">
        <button type="button" @click="authorize" class="btn btn-authorize">Authorize</button>
        <button type="button" @click="cancel" class="btn btn-cancel">Cancel</button>
      </div>

      <p>
        To revoke access to an application, please visit your settings at any time.
      </p>
      <p>
        By authorizing an application you continue to operate under our
        <a href="/terms-and-conditions" target="_blank">Terms of Service</a>.
      </p>
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'fitnessNetworkAuthorize',
  data() {
    return {
      params: {},
      isClientExists: false,
    }
  },

  computed: {
    ...mapGetters("common", ['getLoading', 'getMainLoading']),
    ...mapGetters('auth', ['user', 'loggedIn']),
  },

  mounted() {

    this.$store.dispatch('auth/loggedIn');
    if(!this.loggedIn) {
      localStorage.setItem('redirectTo', this.$route.fullPath);
      this.$router.push({name: 'login'});
      return ;
    }

    const {client_id, redirect_uri} = this.$route.query
    this.params = {client_id, redirect_uri}
    this.checkClient();
  },

  methods: {
    cancel() {
      location.href = this.params.url
    },

    checkClient() {
      this.$store.dispatch('oauth/checkNetworkAuthorize', this.params).then(res => {
        if(res.status) {
          this.isClientExists = true;
        }
      });
    },

    authorize() {
      this.$store.dispatch('oauth/networkAuthorize', this.params).then(res => {
        if(res.status) {
          location.href = res.data.redirect
          return;
        }
        alert('An Error has occured. Please try again.')
      });

    },

  }

}
</script>

<style>
.main {
  width: 460px;
  padding: 20px;
  margin: 100px auto;
  text-align: center;
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid #e7e7e7;
}

.main h1 {
  font-size: 1.3em;
  text-transform: uppercase;
}

.main h3 {
  font-size: .8em;
  letter-spacing: 2px;
}

.main .action {
  margin: 50px 0;
}

.main p {
  font-size: 14px;
}

.btn {
  outline: 0;
  width: 40%;
  color: #ffffff;
  padding: 0 20px;
  margin: 0 10px;
  font-size: 17px;
  cursor: pointer;
  font-weight: 700;
  line-height: 35px;
  text-align: center;
  display: inline-block;
  background-color: #fcb813;
}

.btn-authorize:hover {
  color: #333333;
}

.btn-cancel:hover {
  color: #000000;
}

.btn-cancel {
  color: #000000;
  border: 1px solid #000;
  background-color: #ffffff;
}

.error {
  color: red;
  text-align: center;
  margin-top: 50px;
}
</style>
