<template>
  <div class="event-reg-form">
    <div class="row align-items-center">
      <section class="col-md-6 pr-lg-5">

        <div class="basic-info">
          <div class="input-field form-group">
            <input v-model="height" class="form-control" type="text"/>
            <label :class="height ? 'top-label':''">Height (cm)</label>
<!--            <div v-if="getError('height')" class="error-message">required</div>-->

            <div class="error-message" v-if="getError('height') && !$v.height.required">required</div>
            <div class="error-message" v-if="getError('height') && !$v.height.integer">Height should be numbers only.</div>
            <div class="error-message" v-if="getError('height') && !$v.height.between">Height must be between {{$v.height.$params.between.min}} and {{$v.height.$params.between.max}}.</div>
          </div>

          <div class="input-field form-group">
            <input v-model="weight" class="form-control" type="text"/>
            <label :class="weight ? 'top-label':'' ">Weight (kg)</label>
<!--            <div v-if="getError('weight')" class="error-message">required</div>-->

            <div class="error-message" v-if="getError('weight') && !$v.weight.required">required</div>
            <div class="error-message" v-if="getError('weight') && !$v.weight.integer">Weight should be numbers only.</div>
            <div class="error-message" v-if="getError('weight') && !$v.weight.between">Weight must be between {{$v.weight.$params.between.min}} and {{$v.weight.$params.between.max}}.</div>
          </div>

          <div class="input-field form-group font">
            <input v-model="ftp" class="form-control" type="text"/>
            <label :class="ftp ? 'top-label':'' " style="font-size:0.8rem">FTP
              <small>(max. avg power you can sustain for an hour)</small>
            </label>
            <div class="error-message" v-if="getError('ftp') && !$v.ftp.required">required</div>
            <div class="error-message" v-if="getError('ftp') && !$v.ftp.integer">FTP should be numbers only.</div>
            <small class="text-help-info">
              Please make sure the FTP you enter here accurately reflects your power.
              Riders who perform vastly different from their stated FTP may have their result adjusted post-race.
            </small>
          </div>

          <div class="input-field form-group">
            <input v-model="avg_watt" :disabled="true" class="form-control" type="text"/>
            <label class="top-label">W/kg</label>
            <div v-if="getError('avg_watt')" class="error-message">required</div>

            <div v-if="event.cap && isWrongCategoryRegistration" class="error-message">
                Oops! Looks like you are registering for the wrong category. Kindly re-register for
                the right race category as per your W/kg.
            </div>
<!--            <small class="text-help-info">-->
<!--              Please make sure you are registering for the correct race as per your W/kg-->
<!--            </small>-->
          </div>

<!--          <template>-->
<!--            <div class="custom-control custom-radio custom-control-inline">-->
<!--              <input v-model="weightType" value="video" type="radio" id="videoTypeVideo" class="custom-control-input"/>-->
<!--              <label class="custom-control-label" for="videoTypeVideo">Upload Weigh-In Video</label>-->
<!--            </div>-->
<!--            <div class="custom-control custom-radio custom-control-inline">-->
<!--              <input v-model="weightType" value="link" type="radio" id="videoTypeLink" class="custom-control-input"/>-->
<!--              <label class="custom-control-label" for="videoTypeLink">Private YouTube Link</label>-->
<!--            </div>-->

<!--            <div class="mt-3 input-field form-group" v-if="weightType === 'link'">-->
<!--              <input v-model="weightLink" class="form-control" type="text"/>-->
<!--              <label :class="weightLink ? 'top-label':''" class="top-label">YouTube Video Link</label>-->
<!--              <div v-if="getError('weightLink')" class="error-message">required</div>-->
<!--            </div>-->

<!--            <div class="mt-3 custom-file form-group" v-else>-->
<!--              <input-->
<!--                  id="weightFile"-->
<!--                  accept="video/*"-->
<!--                  class="custom-file-input"-->
<!--                  type="file"-->
<!--                  @change="handleFileUpload('weightFile', $event)"-->
<!--              />-->
<!--              <label class="custom-file-label" for="weightFile">-->
<!--                WEIGH-IN Video ( 10MB maximum size )-->
<!--              </label>-->

<!--              <div class="fileName">{{ getFileName('weightFile') }}</div>-->
<!--              <div v-if="getError('weightFile')" class="error-message">-->
<!--                required-->
<!--              </div>-->

<!--              <small class="text-help-info pointer" style="color: #fbb814;" @click="openPopoverWeight">-->
<!--                <i class="fas fa-info-circle"></i> Click for more info-->
<!--              </small>-->
<!--            </div>-->
<!--          </template>-->
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {required, between, integer} from "vuelidate/lib/validators";
import sweetAlert from "@/services/sweet-alert";
import Swal from "sweetalert2";

export default {
  name: "EventPhysicalForm",
  props: ["user", "event"],

  computed: {
    isWrongCategoryRegistration() {
      if (this.event.cap) {
        return this.avg_watt && this.avg_watt < this.event.min_cap || this.avg_watt > this.event.max_cap;
      }
      return false;
    }
  },

  data() {
    return {
      files: [],
      weightType: 'video',
      ftp: this.user.ftp,
      height: this.user.height,
      weight: this.user.weight,
      // weightFile: this.user.weightFile || null,
      // weightLink: (this.user.weight_link && this.user.weight_link !== 'null' ? this.user.weight_link : ''),
      avg_watt: this.avgWatt(this.user.ftp, this.user.weight),
    }
  },

  validations: {
    ftp: {required, integer},
    height: {required, integer, between: between(100, 300)},
    weight: {required, integer, between: between(40, 140)},
    avg_watt: {required},
    // weightType: {required},
    // weightFile: {
    //   required: requiredIf(function () {
    //     return this.weightType === 'video' || false
    //   }),
    // },
    // weightLink: {
    //   required: requiredIf(function () {
    //     return this.weightType === 'link' || false
    //   }),
    // },
    form: ['height', 'weight', 'ftp', 'avg_watt'] //, 'weightFile', 'weightLink'
  },

  watch: {
    'ftp': {
      handler: function (val) {
        this.avg_watt = 0;
        if (val && this.weight) {
          this.avg_watt = this.avgWatt(val, this.weight)
        }
      }
    },

    'weight': {
      handler: function (val) {
        this.avg_watt = 0;
        if (val && this.ftp) {
          this.avg_watt = this.avgWatt(this.ftp, val)
        }
      }
    },
  },

  methods: {
    validate() {
      this.$v.form.$touch();
      const isValid = !this.$v.form.$invalid && !this.isWrongCategoryRegistration;
      this.$emit('on-validate', this.$data, isValid)
      return isValid
    },

    getError(field) {
      return this.$v[field].$error;
    },

    avgWatt(ftp, weight) {
      return Number((ftp / weight).toFixed(2)) || 0
    },

    getFileName(model) {
      const file = this.files.find(file => file.model === model)
      if (!file) return;
      return file.name;
    },

    //just for reference, can be deleted later
    // handleFileUpload(name, $event) {
    //   const target = $event.target;
    //   this.files = this.files.filter(file => file.model !== name)
    //
    //   if (target.files.length > 0) {
    //     this.files.push({
    //       model: name,
    //       name: target.files[0].name
    //     });
    //   }
    //   this[name] = target.files[0];
    // },
    handleFileUpload(name, $event) {
      this.files = this.files.filter(file => file.model !== name);

      if ($event.target.files.length > 0) {
        const file = $event.target.files[0];

        // Check if file size is greater than 8MB (8 * 1024 * 1024 bytes)
        const maxSize = 8 * 1024 * 1024;
        if (file.size > maxSize) {
          Swal.fire({
            icon: "error",
            text: "File size exceeds 8MB. Please select a smaller file.",
          }).then(() => {
            // Reset the input field to allow for re-selection
            $event.target.value = '';
          });
          return; // Exit the function if the file is too large
        }

        this.files.push({
          model: name,
          name: file.name
        });

        this[name] = file;
      }
    },

    openPopoverWeight() {
      const text = '<p class="text-left font-weight-light text-14">1. Video must show face/weight while standing on scale and must be a new video <br><br> 2. Refer to ruleset for more information</p>';
      sweetAlert.alert(text, '', false);
    },

  }
}
</script>
