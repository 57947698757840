const encryption_key = process.env.VUE_APP_ENCRYPTION_KEY || null
const crypto = require('crypto');

const algorithm = 'aes-256-cbc';
const encryptionKey = encryption_key; // Must be 32 bytes (256 bits)
const iv = crypto.randomBytes(16); // 16 bytes for AES
export const encryptionMixin = {
    methods:{
        encrypt(text) {
            const cipher = crypto.createCipheriv(algorithm, Buffer.from(encryptionKey, 'utf8'), iv);
            let encrypted = cipher.update(text, 'utf8', 'hex');
            encrypted += cipher.final('hex');
            return iv.toString('hex') + ':' + encrypted;
        }
    }
}