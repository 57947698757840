import FrontLayout from '@/layouts/FrontLayout'
// import Home from '@/views/pages/Home'
import Routes from '@/views/pages/Routes'
import Events from '@/views/pages/Events'
import Event from '@/views/pages/JoinEvent'
import EventDetails from '@/views/pages/EventDetails'
import Support from '@/views/pages/Support'
import ContactUs from '@/views/pages/ContactUs'
import Terms from '@/views/pages/Terms'
import Faqs from '@/views/pages/Faqs'
import AboutUs from "@/views/pages/AboutUs";
import TeamUae from "@/views/pages/TeamUae";
import TeamAue from "@/views/pages/TeamAue";
import Privacy from "@/views/pages/Privacy";
import Article from "@/views/pages/Article";
import Ruleset from "@/views/pages/Ruleset";
import Login from "@/views/auth/Login";
import Profile from "@/views/user/Profile";
import Results from "@/views/user/Results";
import Register from "@/views/auth/Register";
import Verification from "@/views/auth/Verification";
import ForgotPassword from "@/views/auth/ForgotPassword";
import ForgotPassword2 from "@/views/auth/ForgotPassword2";
import VerifyJavaUser from "@/views/auth/verifyJavaUser";
import DeleteUser from "@/views/auth/DeleteUser";
import EventThankYou from '@/views/pages/EventThankYou'
import fitnessNetworkCallback from "@/views/oauth/fitnessNetworkCallback";
import fitnessNetworkAuthorize from "@/views/oauth/fitnessNetworkAuthorize";
import IframeWarning from '@/components/IframeWarning.vue'
import AlUla from "../views/pages/AlUla";
import NewActivities from "@/views/user/NewActivities";
// import {commonMixin} from "@/mixins/commonMixin";

export default [
    {
        path: '/',
        component: FrontLayout,
        meta: { requiresAuth: false },
        children: [
            { path: '', name: 'home', component: Events ,  meta:{authRequired:false}},
            { path: 'home', name: 'home', component: Events,  meta:{authRequired:false} },
            { path: 'routes', name: 'routes', component: Routes,  meta:{authRequired:false} },
            {
                path: '/iframe-warning',
                component: IframeWarning
                ,  meta:{authRequired:false}
            },
            {
                path: 'events',
                name: 'events',
                component: Events,
                meta:{authRequired:false}
                // beforeEnter() {
                    // window.location.href = `${process.env.VUE_APP_WEB_URL}/events`;
                // }
            },
            { path: 'event/:id', name: 'event', component: Event,  meta:{authRequired:true} },
            { path: 'event/:id/thank-you', name: 'EventThankYou', component: EventThankYou,  meta:{authRequired:true} },
            { path: 'event/detail/:id', name: 'event-details', component: EventDetails,  meta:{authRequired:false} },
            { path: 'support', name: 'support', component: Support,  meta:{authRequired:false} },
            { path: 'contact-us', name: 'contacts', component: ContactUs,  meta:{authRequired:false} },
            { path: 'terms-and-conditions', name: 'terms', component: Terms ,  meta:{authRequired:false}},
            { path: 'faqs', name: 'faqs', component: Faqs ,  meta:{authRequired:false}},
            { path: 'about-us', name: 'about', component: AboutUs ,  meta:{authRequired:false}},
            { path: 'team-uae', name: 'team-uae', component: TeamUae,  meta:{authRequired:false} },
            { path: 'sunday-race-club', name: 'sunday-race-club', component: TeamAue,  meta:{authRequired:false} },
            { path: 'al-ula', name: 'team-uae', component: AlUla,  meta:{authRequired:false} },
            { path: 'privacy-policy', name: 'privacy', component: Privacy,  meta:{authRequired:false} },
            { path: 'article/:slug', name: 'article', component: Article,  meta:{authRequired:false} },
            { path: 'mywhoosh-ruleset', name: 'Ruleset', component: Ruleset,  meta:{authRequired:false} },
            { path: '/verify-user', name: 'VerifyJavaUser', component: VerifyJavaUser,  meta:{authRequired:false} },
            { path: '/delete-user/:code', name: 'DeleteUser', component: DeleteUser,  meta:{authRequired:false} },
        ]
    },
    {
        path: '/auth',
        component: FrontLayout,
        meta: { requiresAuth: false },
        children: [
            { path: 'login', name: 'login', component: Login,  meta:{authRequired:false} },
            { path: 'register', name: 'register', component: Register,  meta:{authRequired:false} },
            { path: 'verification', name: 'verification', component: Verification,  meta:{authRequired:false} },
            { path: 'forgot-password', name: 'forgetpassword', component: ForgotPassword,  meta:{authRequired:false} },
            { path: 'forgot-password2', name: 'forgotpassword2', component: ForgotPassword2,  meta:{authRequired:false} },
        ]
    },


    // 3rd party oAuth2.0 e.g, trainerDays
    {
        path: '/oauth/authorize',
        name: 'fitnessNetworkAuthorize',
        component: fitnessNetworkAuthorize
        ,  meta:{authRequired:false}
    },


    // 3rd party oAuth authorization e.g, strava/training_peaks
    {
        path: '/oauth/:network/:source?',
        name: 'fitnessNetworkCallback',
        component: fitnessNetworkCallback
        ,  meta:{authRequired:false}
    },
    {
        path: '/',
        component: FrontLayout,
        meta: { requiresAuth: false },
        //beforeEnter: commonMixin.methods.checkForWhooshUuidCookieOrLogout(),
        children: [
            { path: 'user/profile', name: 'profile', component: Profile,  meta:{authRequired:true} },
            { path: 'user/activities', name: 'new_activities', component: NewActivities,  meta:{authRequired:true} },
            { path: 'race-results', name: 'results', component: Results,  meta:{authRequired:true} },
        ]
    },

    { path: '*', redirect: '/',  meta:{authRequired:false} }
];
