export const FrameSet = [
    {
        "Name": "Colnago V4Rs UAE",
        "ID": 3,
        "Brandtitle": "",
        "Description": "Colnago V4Rs UAE Team Emirates, built to win! Lighter, stiffer and more Aero. Developed from feedback and testing of the worlds best riders, the Colnago V4Rs took the best bike and made it better.",
        "FixedFrontTyreId": -1,
        "FixedBackTyreId": -1,
        "Aerodynamics": 60,
        "Weight": 5.9000000953674316,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "(INVALID)",
        "UnlockingValue": 0,
        "isEnabled": true,
        "MeshId": 7,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.25,
        "UI_Icon": 6,
        "WeightUI": 80,
        "FrameBodyMaterialID": 2,
        "FrameGroupSetMaterialID": 2,
        "FrameAlphaMaterialID": 2,
        "BikeType": "E_RoadBike",
        thumbnail: "Colnago V4Rs UAE.png"
    },
    {
        "Name": "Colnago V4Rs ADQ",
        "ID": 4,
        "Brandtitle": "",
        "Description": "Colnago V4Rs Team ADQ, built to win! Lighter, stiffer and more Aero. Developed from feedback and testing of the worlds best riders, the Colnago V4Rs took the best bike and made it better.",
        "FixedFrontTyreId": -1,
        "FixedBackTyreId": -1,
        "Aerodynamics": 60,
        "Weight": 5.9000000953674316,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "(INVALID)",
        "UnlockingValue": 0,
        "isEnabled": true,
        "MeshId": 6,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.25,
        "UI_Icon": 5,
        "WeightUI": 80,
        "FrameBodyMaterialID": 2,
        "FrameGroupSetMaterialID": 2,
        "FrameAlphaMaterialID": 2,
        "BikeType": "E_RoadBike",
        thumbnail: "Colnago V4Rs ADQ.png"
    },
    {
        "Name": "Colnago V3Rs",
        "ID": 5,
        "Brandtitle": "",
        "Description": "Colnago V3Rs Red is a true racing frame, no compromises. Every detail has been studied and developed to obtain the maximum in terms of performance while keeping an eye to comfort. ",
        "FixedFrontTyreId": -1,
        "FixedBackTyreId": -1,
        "Aerodynamics": 55,
        "Weight": 5.9000000953674316,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "(INVALID)",
        "UnlockingValue": 0,
        "isEnabled": true,
        "MeshId": 4,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.25,
        "UI_Icon": 3,
        "WeightUI": 75,
        "FrameBodyMaterialID": 1,
        "FrameGroupSetMaterialID": 1,
        "FrameAlphaMaterialID": 1,
        "BikeType": "E_RoadBike",
        thumbnail: "Colnago V3Rs.png"
    },
    {
        "Name": "Colnago C68",
        "ID": 7,
        "Brandtitle": "",
        "Description": "The C68 represents the history of a company that has collected successes and innovations for more than 68 years. Lighter, stiffer, and faster than previous models, the C68 is for the true cycling enthusiast, seeking performance and something unique. ",
        "FixedFrontTyreId": -1,
        "FixedBackTyreId": -1,
        "Aerodynamics": 55,
        "Weight": 5.8000001907348633,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "(INVALID)",
        "UnlockingValue": 0,
        "isEnabled": true,
        "MeshId": 5,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.25,
        "UI_Icon": 4,
        "WeightUI": 75,
        "FrameBodyMaterialID": 2,
        "FrameGroupSetMaterialID": 2,
        "FrameAlphaMaterialID": 2,
        "BikeType": "E_RoadBike",
        thumbnail: "Colnago C68.png"
    },
    {
        "Name": "Colnago TT1",
        "ID": 11,
        "Brandtitle": "",
        "Description": "The Prototype Colnago TT1 continues the development of iconic and desirable bikes with exceptional performance. Developed through extensive testing in the wind tunnel, Colnago has obtained unprecedented results in terms of performance at high speeds.",
        "FixedFrontTyreId": -1,
        "FixedBackTyreId": -1,
        "Aerodynamics": 90,
        "Weight": 7.1999998092651367,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "(INVALID)",
        "UnlockingValue": 0,
        "isEnabled": true,
        "MeshId": 12,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.25,
        "UI_Icon": 10,
        "WeightUI": 40,
        "FrameBodyMaterialID": 3,
        "FrameGroupSetMaterialID": 3,
        "FrameAlphaMaterialID": 3,
        "BikeType": "E_TTBike",
        thumbnail: "Colnago TT1.png"
    },
    {
        "Name": "Ventum NS1",
        "ID": 13,
        "Brandtitle": "",
        "Description": "Ventum NS1, pure road performance. A bike designed for speed and ride quality. Its stiff, nimble, and responsive frame lets the road disappear beneath your tires.",
        "FixedFrontTyreId": -1,
        "FixedBackTyreId": -1,
        "Aerodynamics": 65,
        "Weight": 6.0999999046325684,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "(INVALID)",
        "UnlockingValue": 0,
        "isEnabled": true,
        "MeshId": 13,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.25,
        "UI_Icon": 12,
        "WeightUI": 70,
        "FrameBodyMaterialID": 2,
        "FrameGroupSetMaterialID": 2,
        "FrameAlphaMaterialID": 2,
        "BikeType": "E_RoadBike",
        thumbnail: "Ventum NS1.png"
    },
    {
        "Name": "Ventum GS1",
        "ID": 14,
        "Brandtitle": "",
        "Description": "Ventum GS1 takes you further and faster wherever you choose to ride. Dynamic, exciting and aesthetically stunning, the GS1 is a bike that handles any adventure you choose to take.",
        "FixedFrontTyreId": -1,
        "FixedBackTyreId": -1,
        "Aerodynamics": 50,
        "Weight": 6.1999998092651367,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "(INVALID)",
        "UnlockingValue": 0,
        "isEnabled": true,
        "MeshId": 14,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.25,
        "UI_Icon": 13,
        "WeightUI": 60,
        "FrameBodyMaterialID": 2,
        "FrameGroupSetMaterialID": 2,
        "FrameAlphaMaterialID": 2,
        "BikeType": "E_RoadBike",
        thumbnail: "Ventum GS1.png"
    },
    {
        "Name": "Look 795 Blade",
        "ID": 15,
        "Brandtitle": "",
        "Description": "Look 795 Blade RS, more technology, outstanding aerodynamics. The 795 encompasses all of LOOK's expertise for premium stiffness, comfort and aerodynamics while retaining its superlight frame. The fastest and most comfortable Look bike ever designed.",
        "FixedFrontTyreId": -1,
        "FixedBackTyreId": -1,
        "Aerodynamics": 65,
        "Weight": 6,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "(INVALID)",
        "UnlockingValue": 0,
        "isEnabled": true,
        "MeshId": 15,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.25,
        "UI_Icon": 14,
        "WeightUI": 75,
        "FrameBodyMaterialID": 2,
        "FrameGroupSetMaterialID": 2,
        "FrameAlphaMaterialID": 2,
        "BikeType": "E_RoadBike",
        thumbnail: "Look 795 Blade.png"
    },
    {
        "Name": "Look 785 Chameleon",
        "ID": 16,
        "Brandtitle": "",
        "Description": "With its modern, aggressive design and upgraded setup,  the 785 Chameleon Huez RS offers performance and aerodynamics in a lightweight package. Perfect for blasting up your next climb!",
        "FixedFrontTyreId": -1,
        "FixedBackTyreId": -1,
        "Aerodynamics": 50,
        "Weight": 5.8000001907348633,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "(INVALID)",
        "UnlockingValue": 0,
        "isEnabled": true,
        "MeshId": 16,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.25,
        "UI_Icon": 15,
        "WeightUI": 75,
        "FrameBodyMaterialID": 3,
        "FrameGroupSetMaterialID": 2,
        "FrameAlphaMaterialID": 2,
        "BikeType": "E_RoadBike",
        thumbnail: "Look 785 Chameleon.png"
    },
    {
        "Name": "BMC SLR 01 One",
        "ID": 17,
        "Brandtitle": "",
        "Description": "The Teammachine SLR 01 One incorporates a TCC Race-engineered Premium Carbon frame, SRAM RED AXS Drivetrain, Aerocore Design system, ICS technology and Stealth Cable Routing. Lighter, faster and more responsive, the Teammachine SLR 01 still leads where others follow.",
        "FixedFrontTyreId": -1,
        "FixedBackTyreId": -1,
        "Aerodynamics": 60,
        "Weight": 5.8000001907348633,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "(INVALID)",
        "UnlockingValue": 0,
        "isEnabled": true,
        "MeshId": 17,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.25,
        "UI_Icon": 16,
        "WeightUI": 75,
        "FrameBodyMaterialID": 4,
        "FrameGroupSetMaterialID": 4,
        "FrameAlphaMaterialID": 4,
        "BikeType": "E_RoadBike",
        thumbnail: "BMC SLR 01 One.png"
    },
    {
        "Name": "BMC SLR 01 Two",
        "ID": 18,
        "Brandtitle": "",
        "Description": "The Teammachine SLR 01 Two incorporates a TCC Race-engineered Premium Carbon frame, Shimano Dura-Ace Drivetrain, Aerocore Design system, ICS technology and Stealth Cable Routing. Lighter, faster and more responsive, the Teammachine SLR 01 still leads where others follow.",
        "FixedFrontTyreId": -1,
        "FixedBackTyreId": -1,
        "Aerodynamics": 60,
        "Weight": 5.9000000953674316,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "(INVALID)",
        "UnlockingValue": 0,
        "isEnabled": true,
        "MeshId": 18,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.25,
        "UI_Icon": 17,
        "WeightUI": 75,
        "FrameBodyMaterialID": 4,
        "FrameGroupSetMaterialID": 4,
        "FrameAlphaMaterialID": 4,
        "BikeType": "E_RoadBike",
        thumbnail: "BMC SLR 01 Two.png"
    },
    {
        "Name": "Van Rysel RCR",
        "ID": 19,
        "Brandtitle": "",
        "Description": "Van Rysel RCR was developed to be aerodynamic, light, stiff and to win at the highest level. Whether you’re an amateur or a top level professional this bike offers efficient performance in both flat and mountainous terrain and is designed for a single purpose: to win.",
        "FixedFrontTyreId": -1,
        "FixedBackTyreId": -1,
        "Aerodynamics": 55,
        "Weight": 6,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "(INVALID)",
        "UnlockingValue": 0,
        "isEnabled": true,
        "MeshId": 19,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.25,
        "UI_Icon": 18,
        "WeightUI": 70,
        "FrameBodyMaterialID": 4,
        "FrameGroupSetMaterialID": 4,
        "FrameAlphaMaterialID": 4,
        "BikeType": "E_RoadBike",
        thumbnail: "Van Rysel RCR.png"
    },
    {
        "Name": "Colnago V4Rs Giro edition",
        "ID": 24,
        "Brandtitle": "",
        "Description": "Colnago V4Rs build to win. Designed to celebrate Tadej Pogacar win in the 2024 Giro d’Italia",
        "FixedFrontTyreId": -1,
        "FixedBackTyreId": -1,
        "Aerodynamics": 60,
        "Weight": 5.9000000953674316,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "(INVALID)",
        "UnlockingValue": 0,
        "isEnabled": true,
        "MeshId": 24,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.25,
        "UI_Icon": 23,
        "WeightUI": 80,
        "FrameBodyMaterialID": 5,
        "FrameGroupSetMaterialID": 2,
        "FrameAlphaMaterialID": 2,
        "BikeType": "E_RoadBike",
        thumbnail: "Colnago V4Rs Giro edition.png"
    },
    {
        "ID": 26,
        "Brandtitle": "",
        "Name": "ENVE Melee Aegean Blue",
        "Description": "A modern road racer that delivers on ENVE's Real-World Fast design philosophy. Agile and responsive, the Melee Aegean Blue is ready for racing and adventure.",
        "FixedFrontTyreId": -1,
        "FixedBackTyreId": -1,
        "Aerodynamics": 60,
        "Weight": 6,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "(INVALID)",
        "UnlockingValue": 0,
        "isEnabled": true,
        "MeshId": 26,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.25,
        "UI_Icon": 25,
        "WeightUI": 60,
        "FrameBodyMaterialID": 5,
        "FrameGroupSetMaterialID": 2,
        "FrameAlphaMaterialID": 2,
        "BikeType": "E_RoadBike",
        thumbnail: "ENVE Melee Aegean Blue.png"
    },
    {
        "ID": 27,
        "Brandtitle": "",
        "Name": "ENVE Melee Damascus",
        "Description": "A modern road racer that delivers on ENVE's Real-World Fast design philosophy. Agile and responsive, the Melee Damascus Silver is ready for racing and adventure.",
        "FixedFrontTyreId": -1,
        "FixedBackTyreId": -1,
        "Aerodynamics": 60,
        "Weight": 6,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "(INVALID)",
        "UnlockingValue": 0,
        "isEnabled": true,
        "MeshId": 27,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.25,
        "UI_Icon": 26,
        "WeightUI": 60,
        "FrameBodyMaterialID": 5,
        "FrameGroupSetMaterialID": 2,
        "FrameAlphaMaterialID": 2,
        "BikeType": "E_RoadBike",
        thumbnail: "ENVE Melee Damascus.png"
    },
    {
        "ID": 28,
        "Brandtitle": "",
        "Name": "Giant TCR Advanced SL",
        "Description": "The World Tour Level TCR Advanced SL is the pro's choice for all-rounder performance. Delivering system integration and innovative construction methods to deliver a clear advantage on the road.",
        "FixedFrontTyreId": -1,
        "FixedBackTyreId": -1,
        "Aerodynamics": 65,
        "Weight": 6,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "(INVALID)",
        "UnlockingValue": 0,
        "isEnabled": true,
        "MeshId": 28,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.25,
        "UI_Icon": 27,
        "WeightUI": 60,
        "FrameBodyMaterialID": 5,
        "FrameGroupSetMaterialID": 2,
        "FrameAlphaMaterialID": 2,
        "BikeType": "E_RoadBike",
        thumbnail: "Giant TCR Advanced SL.png"
    },
    {
        "ID": 29,
        "Brandtitle": "",
        "Name": "Liv Langma Advanced SL",
        "Description": "The queen of all-rounder performance. The Langma Advanced SL has a race-engineered frameset and integrated components, delivering efficient power transfer to rule the mountains, and the aerodynamics to finish in a blur of glory.",
        "FixedFrontTyreId": -1,
        "FixedBackTyreId": -1,
        "Aerodynamics": 65,
        "Weight": 6,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "(INVALID)",
        "UnlockingValue": 0,
        "isEnabled": true,
        "MeshId": 29,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.25,
        "UI_Icon": 28,
        "WeightUI": 60,
        "FrameBodyMaterialID": 5,
        "FrameGroupSetMaterialID": 2,
        "FrameAlphaMaterialID": 2,
        "BikeType": "E_RoadBike",
        thumbnail: "Liv Langma Advanced SL.png"
    },
    {
        "ID": 30,
        "Brandtitle": "",
        "Name": "Colnago Fleur-de-Lys C68",
        "Description": "The name and the livery of gold fleurs-de-lys on a blue field,  a heraldic element symbolising royalty and pride. Lighter, stiffer, and faster than previous models, the C68 is for the true cycling enthusiast, seeking performance and something unique.",
        "FixedFrontTyreId": -1,
        "FixedBackTyreId": -1,
        "Aerodynamics": 55,
        "Weight": 5.8,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "(INVALID)",
        "UnlockingValue": 0,
        "isEnabled": true,
        "MeshId": 30,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.25,
        "UI_Icon": 29,
        "WeightUI": 80,
        "FrameBodyMaterialID": 0,
        "FrameGroupSetMaterialID": 0,
        "FrameAlphaMaterialID": 0,
        "BikeType": "E_RoadBike",
        thumbnail: "Colnago Fleur-de-Lys C68.png"
    },
    {
        "ID": 31,
        "Brandtitle": "",
        "Name": "Colnago V4Rs TDF edition",
        "Description": "Colnago V4Rs build to win. Designed to celebrate Tadej Pogacar win in the 2024 Tour de France",
        "FixedFrontTyreId": -1,
        "FixedBackTyreId": -1,
        "Aerodynamics": 60,
        "Weight": 5.9,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "(INVALID)",
        "UnlockingValue": 0,
        "isEnabled": true,
        "MeshId": 30,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.25,
        "UI_Icon": 29,
        "WeightUI": 80,
        "FrameBodyMaterialID": 0,
        "FrameGroupSetMaterialID": 0,
        "FrameAlphaMaterialID": 0,
        "BikeType": "E_RoadBike",
        thumbnail: "Colnago V4Rs TDF edition.png"
    }
]