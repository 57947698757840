<template>
  <div>
    <header class="header-wrapper clearfix">
      <nav class="mobile-menu closed">
        <a :href="makePageLink()" class="logo">
          <img class="black-logo" src="/assets/images/my-whoosh-logo-black-v2.png" alt="MyWhoosh Logo">
          <img class="white-logo" src="/assets/images/my-whoosh-logo-white-v3.png" alt="MyWhoosh Logo">
        </a>
        <div class="inner-mobile-menu-wrap">
          <div class="site-nav-links" @click="closeSidebar">
            <a class="page-hyper-link" :href="makePageLink('home')">Home</a>
            <a class="page-hyper-link" :href="makePageLink('events')">Events</a>
            <a class="page-hyper-link" :href="makePageLink('routes')">Routes</a>
            <a class="page-hyper-link" :href="makePageLink('blog')">Blog</a>
            <a class="page-hyper-link" :href="makePageLink('results')">Results</a>
            <a class="page-hyper-link" :href="makePageLink('docs')">Support</a>
            <a class="page-hyper-link" target="_blank" :href="makePageLink('uci')">2024 UCI CEWC</a>
            <a class="page-hyper-link" target="_blank" :href="makePageLink('store')">Shop</a>
            <a class="page-hyper-link" :href="makePageLink('podcast')">Podcast</a>
            <a class="page-hyper-link" :href="makePageLink('getting-started-mywhoosh-cycling-app')">Download App</a>
            <a class="page-hyper-link" :href="makePageLink('how-it-works')">Get Started</a>
            <a class="page-hyper-link" :href="makePageLink('about')">About Us</a>
            <span v-if="loggedIn">
                <router-link class="page-hyper-link" :to="{ name: 'new_activities',hash:'#profile' }"  >View Profile</router-link>
                 <router-link class="page-hyper-link" :to="{ name: 'new_activities',hash:'#activities' }"  >Activities</router-link>
<!--                    <router-link class="page-hyper-link" :to="{ name: 'profile' }" v-if="loggedIn">{{`${user.firstname} ${user.lastname}`}}</router-link>-->
                    <a class="page-hyper-link" href="javascript:void(0)" @click="logout">Logout</a>
            </span>
            <span v-else>
                    <router-link :to="{ name: 'login' }"  class="page-hyper-link">LOGIN</router-link>
                    <router-link :to="{ name: 'register' }" class="page-hyper-link">SIGNUP</router-link>
                  </span>
          </div>
        </div>
      </nav>
      <a href="#" title="menu" class="mobile-menu-trigger closed" ref="close_btn" id="close_btn">
        <span></span>
        <span></span>
        <span></span>
      </a>
      <a :href="makePageLink()" class="mobile-logo">
        <img class="black-logo" src="/assets/images/my-whoosh-logo-black-v2.png" alt="MyWhoosh Logo">
        <img class="white-logo" src="/assets/images/my-whoosh-logo-white-v3.png" alt="MyWhoosh Logo">
      </a>
      <div class="max-width-wrap">
        <nav class="header-nav clearfix">
          <div class="left-menu-items-wrap menu-items-wrap clearfix">
            <a class="page-hyper-link" :href="makePageLink('events')">Events</a>
            <a class="page-hyper-link" :href="makePageLink('routes')">Routes</a>
            <a class="page-hyper-link" :href="makePageLink('blog')">Blog</a>
            <a class="page-hyper-link" :href="makePageLink('results')">Results</a>
            <a class="page-hyper-link" :href="makePageLink('docs')">Support</a>
            <a class="page-hyper-link" target="_blank" :href="makePageLink('uci')">2024 UCI CEWC</a>
            <a class="page-hyper-link" target="_blank" :href="makePageLink('store')">Shop</a>
          </div>
          <a :href="makePageLink()" class="desktop-logo">
            <img class="black-logo" src="/assets/images/my-whoosh-logo-black-v2.png" alt="MyWhoosh Logo">
            <img class="white-logo" src="/assets/images/my-whoosh-logo-white-v3.png" alt="MyWhoosh Logo">
          </a>
          <div class="right-menu-items-wrap menu-items-wrap clearfix" >
            <a class="page-hyper-link" :href="makePageLink('podcast')">Podcast</a>
            <a class="page-hyper-link" :href="makePageLink('getting-started-mywhoosh-cycling-app')">Download App</a>
            <a class="page-hyper-link" :href="makePageLink('how-it-works')">Get Started</a>
            <a class="page-hyper-link" :href="makePageLink('about')">About Us</a>

            <a class="dropdown icon-name" v-if="loggedIn">
              <button class="btn dropdown-toggle profile-icon-letter" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span class="p-name-letter rounded-circle">{{  user.firstname.substring(0, 1) }}</span>
              </button>
              <ul class="dropdown-menu">
                <li><router-link class="page-hyper-link" :to="{ name: 'new_activities',hash:'#profile' }"  >View Profile</router-link></li>
                <li><router-link class="page-hyper-link" :to="{ name: 'new_activities',hash:'#activities' }"  >Activities</router-link></li>
                <li><a class="page-hyper-link" href="javascript:void(0)" @click="logout">Logout</a></li>
              </ul>
            </a>

            <!--                    <router-link class="page-hyper-link" :to="{ name: 'profile' }" v-if="loggedIn">{{`${user.firstname} ${user.lastname}`}}</router-link>-->
            <!--                    <a class="page-hyper-link" href="javascript:void(0)" @click="logout">Logout</a>-->

            <span v-else>
                    <router-link :to="{ name: 'login' }"  class="page-hyper-link">LOGIN</router-link>
                    <router-link :to="{ name: 'register' }"     class="page-hyper-link">SIGNUP</router-link>
                  </span>
          </div>
        </nav>
      </div>
    </header>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {commonMixin} from "@/mixins/commonMixin";

export default {
  name: "HeaderComponent",
  mixins: [commonMixin],
  head: {
    script: [
      { src: '/assets/js/activities/bootstrap.bundle.min.js', type: 'text/javascript' }
    ]
  },
  data() {
    return {
      wpURL: process.env.VUE_APP_WEB_URL,
      storeBaseUrl: process.env.VUE_APP_WHOOSH_STORE_BASE_URL || ''
    };
  },

  computed: {
    ...mapGetters("auth", ["user", "loggedIn"]),
    ...mapGetters("user", ["avatar" ,"profile"]),

    getUserAvatar() {
      const def = this.avatar.split("/");
      if (def[def.length - 1] === "avatar.png") {
        return this.assetsUrl() + "/images/" + this.avatar;
      }
      return this.makeImage(this.avatar, 350, 350, true);
    },

    getUserName() {
      if (!this.profile) return;
      return this.profile.firstname + " " + this.profile.lastname;
    },

    getUserNameForMenu() {
      if (this.user) {
        return this.user.firstname + " " + this.user.lastname;
      }

      return null;
    },

    makeQueryString() {
      if (!this.user) return `?login=false`;
      return `?login=${this.loggedIn}&profile=${this.getUserNameForMenu}`
    }
  },

  mounted() {
    // check if player is logged in
    this.$store.dispatch("auth/loggedIn");
  },

  methods: {

    closeSidebar(){
      if(document.getElementById('close_btn')){
        document.getElementById('close_btn').click()
      }
    },

    makePageLink(slug = null) {
      // Define supported hostnames and their corresponding prefixes and domains
      const hostnamesWithPrefixes = [
        { hostname: 'service20-dev03.my-whoosh.com', prefix: 'dev03', domain: ".my-whoosh.com" },
        { hostname: 'service20-dev02.my-whoosh.com', prefix: 'dev02', domain: ".my-whoosh.com" },
        { hostname: 'service20-dev01.my-whoosh.com', prefix: 'dev01', domain: ".my-whoosh.com" },
        { hostname: 'service20-stage.my-whoosh.com', prefix: 'stage', domain: ".my-whoosh.com" },
        { hostname: 'service20-qa.my-whoosh.com', prefix: 'qa', domain: ".my-whoosh.com" },
        { hostname: 'service20-int.my-whoosh.com', prefix: 'int', domain: ".my-whoosh.com" },
        { hostname: 'event.mywhoosh.com', prefix: '', domain: ".mywhoosh.com" } // Production has no prefix
      ];

      const currentHostname = window.location.hostname;

      // Use find to get the matching hostname object
      const envPrefixObj = hostnamesWithPrefixes.find(item => currentHostname === item.hostname);
      const isDevOrStage = !!envPrefixObj; // Check if a matching hostname was found
      const envPrefix = envPrefixObj ? (envPrefixObj.prefix ? `-${envPrefixObj.prefix}` : '') : '';
      const domain = envPrefixObj ? envPrefixObj.domain : ''; // Get the domain

      // Define URLs based on slug and environment
      if (slug === 'uci' && isDevOrStage) {
        return `https://uci${envPrefix}${domain}`;
      } else if (slug === 'store' && isDevOrStage) {
        return `https://store${envPrefix}${domain}`;
      } else if (slug) {
        return `${this.wpWebUrl()}/${slug}/${this.makeQueryString}`;
      }

      return `${this.wpWebUrl()}/${this.makeQueryString}`;
    },

    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push({name: "events"});
    },
  },

};
</script>

<style scoped>
header.header-wrapper nav.header-nav .right-menu-items-wrap {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.icon-name{
  display: inline-block;
  width: auto;
}
.icon-name button {
  margin-left: 15px;
}
.profile-icon-letter {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  width: 62px;
}
.p-name-letter {
  background-color: #fcb913;
  width: 62px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  color: #282828;
  text-align: center;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 75% */
  text-transform: uppercase;
}
.icon-name .dropdown-toggle::after {
  display: none !important;
}
.icon-name ul{
  padding: 12px 23px !important;
  border-radius: 1.25rem;
  margin-top: 17px !important;
  left: -93px !important;
}
.icon-name ul li{
  padding: 0 0 1.563px 0 !important;
}
.icon-name ul li a.page-hyper-link{
  margin: 0px auto !important;
  color: #282828 !important;
  font-family: Inter !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 31.5px !important; /* 175% */
  text-transform: uppercase !important;
  letter-spacing: initial !important;
}
</style>