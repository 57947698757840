<template>
  <section>
    <h3>CONNECTIONS</h3>
    <p class="px-5">Enjoy all the benefits of your fitness apps by pairing them with MyWhoosh. Connecting data means one
      less
      thing for you to do, leaving more time for you to focus on riding and enhancing your fitness.</p>

    <div class="connection" v-if="userNetworks">
      <div class="row pt-md-5 mt-md-5">
        <section class="col-md-4 circle-info">
          <img alt="" class="img-fluid" :src="`${assetsUrl()}/images/strava.jpg`">

          <div class="text-center">
            <button v-if="userNetworks.includes('strava')" class="btn-universal btn-universal-social-disconnect"
                    @click="btnSocialHandler('strava', 'rem')">Disconnect
            </button>
            <button v-else class="btn-universal" @click="btnSocialHandler('strava', 'add')">Connect</button>
          </div>
        </section>

        <section class="col-md-4 circle-info">
          <img alt="" class="img-fluid" :src="`${assetsUrl()}/images/todayplan.png`">

          <div class="text-center">
            <button v-if="userNetworks.includes('todaysplan')" class="btn-universal btn-universal-social-disconnect"
                    @click="btnSocialHandler('todaysplan', 'rem')">Disconnect
            </button>
            <button v-else class="btn-universal" @click="btnSocialHandler('todaysplan', 'add')">Connect</button>
          </div>
        </section>

        <section class="col-md-4 circle-info">
          <img alt="" class="img-fluid" :src="`${assetsUrl()}/images/training-peaks.png`">

          <div class="text-center">
            <button v-if="userNetworks.includes('trainingpeaks')" class="btn-universal btn-universal-social-disconnect"
                    @click="btnSocialHandler('trainingpeaks', 'rem')">Disconnect
            </button>
            <button v-else class="btn-universal" @click="btnSocialHandler('trainingpeaks', 'add')">Connect</button>
          </div>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters} from 'vuex';
import {commonMixin} from '@/mixins/commonMixin';

export default {
  name: 'ProfileSocial',
  props: ['linkedNetworks'],
  mixins: [commonMixin],

  data() {
    return {
      networks: {
        strava: process.env.VUE_APP_STRAVA_CLIENT,
        todaysplan: process.env.VUE_APP_TODAYSPLAN_CLIENT,
        trainingpeaks: process.env.VUE_APP_TRAINING_PEAK_CLIENT,
      }
    }
  },

  computed: {
    ...mapGetters('common', ['getTempKey']),

    userNetworks() {
      if (this.linkedNetworks.length === 0) return [];
      return this.linkedNetworks
    }

  },

  mounted() {
    // this.userNetworks = this.linkedNetworks
  },

  methods: {
    async btnSocialHandler(account, act) {
      const url = this.networks[account];
      if (act === 'add') {
        location.href = url;
      }

      if (act === 'rem') {
        if (confirm(`Are you sure you want to remove ${account.toUpperCase()}`)) {
          await this.$store.dispatch('user/removeConnection', {account}).then(res => {
            if (res && res.status) {
              const index = this.userNetworks.indexOf(account);
              if (index > -1) {
                this.userNetworks.splice(index, 1);
              }
            }
          });
        }
      }
    }
  }
}
</script>