<template>


    <b-modal id="third_party_modal"
             no-close-on-backdrop
             :title="title" :static="true" @hidden="resetTab" hide-footer>


    <div class="example-modal-content modal-custom-css">
      <ul class="errors" v-if="errors" style="margin-left: 10%;
    margin-top: 2%;
    color: red;">
            <li v-for="(error,key) in errors" :key="key">{{ error[0] }}</li>
      </ul>

      <form-wizard  v-if="!submissionComplete" ref="wizard" :start-index="0"  color="#70C95A"
                   stepSize="sm"
                   @on-change="tabChanged"
                   @on-complete="submitMigrationForm" finishButtonText="Submit"
                   :hideButtons="currentTab === 0 && !selectedPlatform"
      >

          <wizard-step
              slot-scope="props"
              slot="step"
              :tab="props.tab"
              custom-buttons-right="ju"
              :transition="props.transition"
              :index="props.index">
          </wizard-step>


        <tab-content v-for="(step,index) in formSteps" :key="index" :title="step.name" icon="fa fa-check"
                     :before-change="() => validateStep(step.name)"  >

              <step-one :ref="step.name" @selected-platform="changeTheTab" v-if="index === 0"
                        @on-validate="mergePartialModels"  />
              <step-two :ref="step.name" v-else-if="index === 1"   @on-validate="mergePartialModels"    />
              <step-three :ref="step.name" v-else-if="index === 2"   @on-validate="mergePartialModels"   />

        </tab-content>

      </form-wizard>

      <div v-else class="d-flex justify-content-center align-items-center msgSent" >
        <img src="/assets/images/steps-form-logos/sendMsg.png" />
        <div class="titleSuccess">Your Request has been submitted successfully !</div>
        <p>We will get back to you soon within 7 business days.</p>
        <button @click="closeModal" type="button" class="wizard-btn" style="cursor:pointer;background-color: rgb(112, 201, 90); border-color: rgb(112, 201, 90); color: white;">
          Done
        </button>
      </div>

      </div>

    </b-modal>


<!--  </modal>-->
</template>

<script>
import StepOne from "@/components/rider/third_party_form/StepOne";
import StepTwo from "@/components/rider/third_party_form/StepTwo";
import StepThree from "@/components/rider/third_party_form/StepThree";
import { FormWizard, TabContent, WizardStep } from "vue-form-wizard";

import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { BModal } from 'bootstrap-vue'
export default {
  data(){
    return {
      currentTab:0,
      formSteps:[
        {index:0,name:"migration_select_screen"},
        {index:1,name:"details_entering_screen"},
        {index:2,name:"proof_screen"},
      ],
      formData: new FormData(),
      submissionComplete:false,
      errors:[],
      titleMessages : [
          "Select your migration platform",
          " / Enter your ride stats",
          " / Enter your ride stats / Proof"
      ],
      title:"Select your migration platform",
      selectedPlatform:""
    }
  },
  computed:{
  },
  name: "ModalThirdPartyImport",
  components: {
    FormWizard,
    TabContent,
    StepOne,
    StepTwo,
    StepThree,
    WizardStep,
    'b-modal':BModal
  },
  methods:{
    validateStep(name) {
      return this.$refs[name][0].validate();
    },
    mergePartialModels(model, isValid) {
      if (isValid) {
        for (let [key, value] of Object.entries(model)) {
          if(key === 'total_time_spent' || key === 'overall_distance'){
             value = JSON.stringify(value)
          }else if(key === 'migration_platform'){
            this.selectedPlatform = value
          }else if(key === 'proof_file'){
            value.forEach((file,k) => {
              this.formData.set("proof_files["+k+"]", file);
            })

          }
          this.formData.set(key, value);
        }
      }
    },
    openModal(){
      this.$bvModal.show('third_party_modal')
    },
    closeModal(){
      this.$bvModal.hide('third_party_modal')
      window.location.reload()
    },
    submitMigrationForm(){
      this.$store
          .dispatch("user/submitThirdPartyImport", this.formData)
          .then((res) => {
            if(res.status){
             this.submissionComplete = true
              this.errors = [];
            }else{
            this.submissionComplete = false
              this.errors = res.errors

            }
          });
    },
    resetTab(){

      window.location.reload()
     this.$refs.wizard?.reset()

      this.formSteps.forEach((val) => {

        this.$refs[val.name][0]?.resetData()

      })

    },
    // eslint-disable-next-line no-unused-vars
    tabChanged(prevIndex,nextIndex){
      this.currentTab = nextIndex
      if(this.titleMessages[nextIndex]){
        this.title = this.selectedPlatform + this.titleMessages[nextIndex]
      }else {
        this.title = "Congratulations!!"
      }
    },
    changeTheTab(){
      this.$refs.wizard.nextTab()
    }
  },

}
</script>

<style scoped>

</style>