<template>
    <div>
        <vue-title title="Account verification | MyWhoosh - Your Indoor Cycling Solution"></vue-title>

        <section class="login-wrap">
            <div class="container">
                <div class="row">
                    <section class="col-md-8 mx-auto login-box">

                        <div class="text-center validation">
                            <img class="icon" src="assets/images/icon_check.svg" alt="" v-if="verified === 'success'">
                            <img class="icon" src="assets/images/icon_warning.svg" alt="" v-if="verified === 'failed'">

                            <div class="mt-5">
                                {{ text }}

                                <div v-if="verified === 'failed'">
                                    <button @click="resendHandler" role="button" class="btn-universal mt-5" href="">Resend</button>
                                </div>
                                <div v-if="verified === 'success'">
                                    <span style="color:#000;font-size:21px;">Thank you!</span> <br /><br />
                                    <router-link :to="{name: 'login'}" class="btn-universal mt-5">ENJOY YOUR RIDE</router-link>
                                </div>

                                <span v-show="verified === 'pending'" class="validation_dots">
                                    <span>.</span>
                                    <span>.</span>
                                    <span>.</span>
                                </span>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </section>
    </div>

</template>

<script>
import Service from "@/services";
export default {
    name: 'VerifyJavaUser',

    data() {
        return {
            code: this.$route.query.es,
            verified: 'pending',
            text: 'please hold. verifying your account '
        };
    },

    mounted() {
        this.verify();
    },

    methods: {
        verify() {
            Service().post('../api/auth/verify-user', { code: this.code }).then(res => {
                this.text = res.message;
                this.verified = res.data.verified;
            }).catch(err => {
                this.text = err.message;
                this.verified = err.data.verified;
            });
        },

        resendHandler() {
            Service().post('../api/auth/verify-user-resend', { code: this.code }).then(res => {
                this.text = res.message;
                this.verified = res.data.verified;
            }).catch(err => {
                this.text = err.message;
                this.verified = err.data.verified;
            });
        }
    },
}
</script>


<style scoped>
div.needsclick {
    display: none !important;
}

.login-wrap {
    margin-top: 100px;
}

div.validation {
    font-size: 24px;
    padding: 80px 20px 105px;
    border-radius: 5px;
    /* background-color: #ffffff; */
    text-transform: uppercase;
    border: 0px solid rgb(165, 204, 232);
    color: #000;
    text-align: center;
}

div.pass {
    border: 0px solid #0a0;
    color: #0a0;
}

div.fail {
    border: 0px solid #c00;
    color: #f00;
}

.validation_dots span {
    font-size: 25px;
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}

.validation_dots span:nth-child(2) {
    animation-delay: .1s;
}

.validation_dots span:nth-child(3) {
    animation-delay: .9s;
}

@keyframes blink {
    0% {
        opacity: .2;
    }

    20% {
        opacity: 1;
    }

    100% {
        opacity: .2;
    }
}

.validation img.icon {
    width: 10%;
}

.btn-universal {
    font-size: 24px;
    padding: 20px 40px;
    height: 50px;
    line-height: 12px;
    font-weight: 500;
    border-width: 0;
}
</style>