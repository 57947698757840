<template>
  <section>
    <EventCategoryTimes
      :data="event"
      @doToggle="doToggle"
      :details="details"
      :new_version="false"
    />
    <div class="row">
      <section class="col-md-4 event-listing-img"></section>
      <section class="col-md-8 event-listing-detail pt-4">
        <div v-if="loggedIn">
          <template v-if="event.accept">
            <template v-if="event.ustatus === 'pending'">
              <p class="alert alert-warning alert-12">
                Your registration for this event is in process, soon you will
                receive a confirmation email.
              </p>
            </template>
            <template v-else-if="event.ustatus === 'approved'">
              <p class="alert alert-success alert-12">
                Congratulations! Your registration for this event has been
                approved.
              </p>
            </template>
            <template v-else-if="event.ustatus === 'declined' && join === true">
              <p class="alert alert-danger alert-12">
                Your request for this event is declined. please contact support
                for further details
              </p>
              <a
                class="btn-universal"
                href="javascript:void(0)"
                @click="joinEvent(event.id)"
                >Re Join</a
              >
            </template>
            <template v-else>
              <a
                v-if="join && checkEmptySlots() && canParticipate"
                class="btn-universal"
                href="javascript:void(0)"
                @click="joinEvent(event.id)"
                >Join</a
              >
              <p v-if="!checkEmptySlots()" class="alert alert-warning alert-12">
                The room is already fill for this event.
              </p>

              <p v-if="!canParticipate" class="alert alert-warning alert-12">
                {{ eventMessage }}
              </p>
            </template>
          </template>
          <template v-else>
            <p class="alert alert-warning alert-12">
              Registration for this event has been closed.
            </p>
          </template>
        </div>

        <div v-else class="tidw text-danger text-center mb-2">
          Login or create an account to register for this event.
        </div>

        <EventLinks :links="getLinks" :new_version="false" />
      </section>
    </div>

    <transition name="slide">
      <div class="row" v-if="toggle">
        <section class="col-md-12">
          <div class="event-description">
            <h4>DESCRIPTION</h4>
            <div v-html="event.details"></div>
          </div>
        </section>
      </div>
    </transition>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
import EventLinks from "@/components/event/EventLinks";
import EventCategoryTimes from "@/components/event/EventCategoryTimes";
import { commonMixin } from "@/mixins/commonMixin";
import MinMaxCap from "@/json/min_max_cap.json";

export default {
  name: "EventSummary",
  props: ["event", "join", "details"],
  mixins: [commonMixin],
  components: { EventLinks, EventCategoryTimes },

  data() {
    return {
      eventMessage: null,
      canParticipate: false,
      toggle: false,
      MinMaxCap,
    };
  },

  computed: {
    ...mapGetters("auth", ["user", "loggedIn"]),
    ...mapGetters("auth", ["loggedIn"]),

    getUser() {
      if (!this.user) return;
      return Object.assign({}, this.user);
    },

    getLinks() {
      return this.event.links;
    },
  },

  mounted() {
    dayjs.extend(utc);
    this.checkEligibility();
  },

  filters: {
    dateFormat: function (value) {
      return dayjs(value).format("dddd, DD MMMM YYYY");
    },

    timeFormat: function (value) {
      return dayjs(value).format("hh:mm A");
    },
  },

  methods: {
    joinEvent(eventId) {
      this.$router.push({ name: "event", params: { id: eventId } });
    },

    doToggle() {
      this.toggle = !this.toggle;
    },

    checkEligibility() {
      if ( this.event.gender == 2) {
        this.canParticipate = true;
        return;
      }

      if (this.user.gender == this.event.gender) {
        this.canParticipate = true;
        return;
      }

      let gender = [
        { id: 0, label: "Males" },
        { id: 1, label: "Females" },
      ];
      this.eventMessage =
        "This is a " +
        gender.find((g) => g.id == this.event.gender)?.label +
        " only event.";
    },

    checkEmptySlots() {
      if (!this.event.participants || !this.event.participants.booked)
        return 160;

      return this.event.participants.booked < this.event.participants.capacity;
    },
  },
};
</script>

<style scoped>
.min_max_cap {
  font-size: 13px;
}
</style>
