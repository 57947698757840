<template>
  <div class="form-fields">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6">
        <h1>Total time spent</h1>

        <div class="inputsWrpr">
          <div class="">
            <div class="input-group mb-3">
              <input
                  type="number"
                  class="form-control"
                  placeholder=""
                  @blur="$v.total_time_spent.days.$touch"
                  v-model="total_time_spent.days"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
              />
              <span class="input-group-text" id="basic-addon21">Days</span>
            </div>
          </div>

          <div class="">
            <div class="input-group mb-3">
              <input
                  type="number" step="0.1" min="0"
                  class="form-control"
                  placeholder=""
                  @blur="$v.total_time_spent.hours.$touch"
                  v-model="total_time_spent.hours"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
              />
              <span class="input-group-text" id="basic-addon23">Hours</span>
            </div>
          </div>

          <div class="">
            <div class="input-group mb-3">
              <input
                  type="number" step="0.1" min="0"
                  class="form-control"
                  placeholder=""
                  @blur="$v.total_time_spent.minutes.$touch"
                  v-model="total_time_spent.minutes"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
              />
              <span class="input-group-text" id="basic-addon244">Mins</span>
            </div>
          </div>
        </div>

        <div v-if="getError('total_time_spent')" class="error-message">
          You need to fill total time spent
        </div>

        <div  class="error-message"
              v-if="!$v.total_time_spent.hours.integer">
          You need to fill hours in integer</div>

        <div  class="error-message"
              v-if="!$v.total_time_spent.hours.minValue
              || !$v.total_time_spent.hours.maxValue">
          Hours should be between 0 and 23</div>

        <div  class="error-message"
              v-if="!$v.total_time_spent.days.integer">
          You need to fill days in integer</div>

        <div  class="error-message"
              v-if="!$v.total_time_spent.days.minValue">
          Days should be not be less than zero</div>


        <div  class="error-message"
              v-if="!$v.total_time_spent.minutes.integer">
          You need to fill minutes in integer</div>

        <div  class="error-message"
              v-if="!$v.total_time_spent.minutes.minValue
              || !$v.total_time_spent.minutes.maxValue">
          Minutes should be between 0 and 59
        </div>

      </div>



      <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3">
        <h1>Overall Distance</h1>

        <div class="inputsWrpr">
          <div class="input-group">
            <input
                type="number"
                step="0.1"
                min="0"
                class="form-control"
                v-model="overall_distance.value"
            />
            <div class="input-group-append">
              <select
                  v-model="overall_distance.unit"
                  class="btn btn-outline-secondary dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
              >
                <option class="dropdown-item" value="kilometers">
                  km
                </option>
                <option class="dropdown-item" value="miles">mi</option>
              </select>
            </div>
          </div>
        </div>

        <div v-if="getError('overall_distance')" class="error-message">
          You need to fill over all distance
        </div>

      </div>

      <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3">
        <h1>Overall Elevation</h1>

        <div class="inputsWrpr">
          <div class="">
            <div class="input-group mb-3">
              <input
                  type="number" step="0.1" min="0"
                  class="form-control"
                  placeholder=""
                  v-model="overall_elevation_meter"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
              />
              <span class="input-group-text" id="basic-addon2">Meter</span>
            </div>
          </div>
        </div>

        <div v-if="getError('overall_elevation_meter')" class="error-message">
          You need to fill over all elevation
        </div>
      </div>
    </div>
  </div>

  <!--    <select v-model="overall_distance.unit">-->
  <!--      <option value="kilometer">Kilometer</option>-->
  <!--      <option value="miles">miles</option>-->
  <!--    </select>-->
</template>

<script>
let that;
import { required, integer, minValue, between } from "vuelidate/lib/validators";
export default {
  name: "StepTwo",
  data() {
    return {
      total_time_spent: {
        days: "",
        hours: "",
        minutes: "",
      },
      overall_distance: {
        value: "",
        unit: "kilometers",
      },
      overall_elevation_meter: "",
    };
  },
  validations: {
    total_time_spent: {
      days: {
        required,
        integer,
        minValue: minValue(0),
      },
      hours: {
        required,
        integer,
        minValue: minValue(0),
        maxValue: between(0, 23),
      },
      minutes: {
        required,
        integer,
        minValue: minValue(0),
        maxValue: between(0, 59),
      },
    },
    overall_distance: {
      value: {
        required,
      },
      unit: {
        required,
      },
    },
    overall_elevation_meter: { required },
    form: ["total_time_spent", "overall_distance", "overall_elevation_meter"],
  },
  methods: {
    resetData() {
      this.total_time_spent = {
        days: "",
        hours: "",
        minutes: "",
      };
      this.overall_distance = {
        value: "",
        unit: "kilometer",
      };
      this.overall_elevation_meter = "";
    },
    validate() {
      this.$v.form.$touch();
      const isValid = !this.$v.form.$invalid;
      this.$emit("on-validate", this.$data, isValid);
      return isValid;
    },
    getError(field) {
      return this.$v[field].$error;
    },
  },
  watch:{
    "total_time_spent.days": (value) => {

      that.total_time_spent.days = parseInt(value);

    },
    "total_time_spent.hours": (value) => {

      that.total_time_spent.hours = parseInt(value);

    },
    "total_time_spent.minutes": (value) => {

      that.total_time_spent.minutes = parseInt(value);

    }
  },created() {
    that = this
  }
};
</script>

<style scoped></style>
