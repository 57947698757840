export const FacialHairSet = [
    {
        ID: "0",
        Description:
            "If you choose 'None', your avatar will have no facial hair. Let your style reflect your personality.",
        Color: "1",
        Name: "None",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "0",
        MeshId: "0",
        AtlasId: "0",
        U_transform: "0",
        V_transform: "0",
        U_offset: "0.000000",
        V_offset: "0.000000",
        UI_Icon: "0",
        EthnicityIdMappedToFacialScaleValues:
            "((0, (X=0.000000,Y=0.000000,Z=0.000000)),(1, (X=0.000000,Y=0.000000,Z=0.000000)),(2, (X=0.000000,Y=0.000000,Z=0.000000)),(3, (X=0.000000,Y=0.000000,Z=0.000000)),(4, (X=0.000000,Y=0.000000,Z=0.000000)))",
    },
    {
        ID: "1",
        Description:
            "Add character to your avatar with stylish facial hair - beards, moustaches, or stubble. Express your virtual personality!",
        Color: "2",
        Name: "Full Beard",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "1",
        MeshId: "1",
        AtlasId: "1",
        U_transform: "0",
        V_transform: "0",
        U_offset: "0.250000",
        V_offset: "0.250000",
        UI_Icon: "1",
        EthnicityIdMappedToFacialScaleValues:
            "((0, (X=1.000000,Y=1.010000,Z=1.000000)),(1, (X=1.000000,Y=1.000000,Z=1.000000)),(2, (X=1.000000,Y=1.020000,Z=1.000000)),(3, (X=1.000000,Y=1.100000,Z=1.000000)),(4, (X=0.950000,Y=0.990000,Z=0.950000)))",
    },
    {
        ID: "2",
        Description:
            "Add character to your avatar with stylish facial hair - beards, moustaches, or stubble. Express your virtual personality!",
        Color: "0",
        Name: "Circle Beard",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "1",
        MeshId: "2",
        AtlasId: "1",
        U_transform: "0",
        V_transform: "0",
        U_offset: "0.250000",
        V_offset: "0.250000",
        UI_Icon: "2",
        EthnicityIdMappedToFacialScaleValues:
            "((0, (X=1.000000,Y=1.010000,Z=1.000000)),(1, (X=1.000000,Y=1.000000,Z=1.000000)),(2, (X=1.000000,Y=1.020000,Z=1.000000)),(3, (X=1.000000,Y=1.100000,Z=1.000000)),(4, (X=0.950000,Y=0.990000,Z=0.900000)))",
    },
    {
        ID: "3",
        Description:
            "Add character to your avatar with stylish facial hair - beards, moustaches, or stubble. Express your virtual personality!",
        Color: "0",
        Name: "Zappa",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "4",
        MeshId: "3",
        AtlasId: "1",
        U_transform: "0",
        V_transform: "0",
        U_offset: "0.250000",
        V_offset: "0.250000",
        UI_Icon: "3",
        EthnicityIdMappedToFacialScaleValues:
            "((0, (X=1.000000,Y=1.010000,Z=1.000000)),(1, (X=1.000000,Y=1.000000,Z=1.000000)),(2, (X=1.000000,Y=1.020000,Z=1.000000)),(3, (X=1.000000,Y=1.100000,Z=1.000000)),(4, (X=0.950000,Y=0.990000,Z=0.900000)))",
    },
    {
        ID: "4",
        Description:
            "Add character to your avatar with stylish facial hair - beards, moustaches, or stubble. Express your virtual personality!",
        Color: "0",
        Name: "Classic Goatee",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "6",
        MeshId: "4",
        AtlasId: "1",
        U_transform: "0",
        V_transform: "0",
        U_offset: "0.250000",
        V_offset: "0.250000",
        UI_Icon: "4",
        EthnicityIdMappedToFacialScaleValues:
            "((0, (X=1.000000,Y=1.010000,Z=1.000000)),(1, (X=1.000000,Y=1.000000,Z=1.000000)),(2, (X=1.000000,Y=1.020000,Z=1.000000)),(3, (X=1.000000,Y=1.100000,Z=1.000000)),(4, (X=0.850000,Y=0.970000,Z=1.000000)))",
    },
    {
        ID: "5",
        Description:
            "Add character to your avatar with stylish facial hair - beards, moustaches, or stubble. Express your virtual personality!",
        Color: "0",
        Name: "Short Goatee",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "0",
        MeshId: "5",
        AtlasId: "1",
        U_transform: "0",
        V_transform: "0",
        U_offset: "0.250000",
        V_offset: "0.250000",
        UI_Icon: "5",
        EthnicityIdMappedToFacialScaleValues:
            "((0, (X=1.000000,Y=1.010000,Z=1.000000)),(1, (X=1.000000,Y=1.000000,Z=1.000000)),(2, (X=1.000000,Y=1.020000,Z=1.000000)),(3, (X=1.000000,Y=1.100000,Z=1.000000)),(4, (X=0.850000,Y=0.970000,Z=1.000000)))",
    },
    {
        ID: "6",
        Description:
            "Add character to your avatar with stylish facial hair - beards, moustaches, or stubble. Express your virtual personality!",
        Color: "0",
        Name: "Chevron",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "0",
        MeshId: "6",
        AtlasId: "1",
        U_transform: "0",
        V_transform: "0",
        U_offset: "0.250000",
        V_offset: "0.250000",
        UI_Icon: "6",
        EthnicityIdMappedToFacialScaleValues:
            "((0, (X=1.000000,Y=1.010000,Z=1.000000)),(1, (X=1.000000,Y=1.000000,Z=1.000000)),(2, (X=1.000000,Y=1.020000,Z=1.000000)),(3, (X=1.000000,Y=1.100000,Z=1.000000)),(4, (X=0.960000,Y=0.980000,Z=0.930000)))",
    },
    {
        ID: "7",
        Description:
            "Add character to your avatar with stylish facial hair - beards, moustaches, or stubble. Express your virtual personality!",
        Color: "0",
        Name: "Soul Patch",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "0",
        MeshId: "7",
        AtlasId: "1",
        U_transform: "0",
        V_transform: "0",
        U_offset: "0.250000",
        V_offset: "0.250000",
        UI_Icon: "7",
        EthnicityIdMappedToFacialScaleValues:
            "((0, (X=1.000000,Y=1.010000,Z=1.000000)),(1, (X=1.000000,Y=1.000000,Z=1.000000)),(2, (X=1.000000,Y=1.020000,Z=1.000000)),(3, (X=1.000000,Y=1.080000,Z=1.000000)),(4, (X=0.960000,Y=0.970000,Z=1.000000)))",
    },
];
