import axios from 'axios';

const Service = () => {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };

    const token = localStorage.getItem('webToken');
    const loginRoute = global?.app?.$router?.currentRoute?.name;

    if (token && loginRoute !== 'login') {
        headers.Authorization = `Bearer ${token}`;
    }

    const instance = axios.create({
        withCredentials: true,
        baseURL: process.env.VUE_APP_API_URL,
        headers,
    });

    instance.interceptors.request.use(config => {
        const isPaymentInfoRequest = config.url.includes("garage/payment/info");

        if (!isPaymentInfoRequest) {
            global.app.$store.commit('common/SET_LOADING', true);
        } else {
            global.app.$store.commit("SET_ERRORS", {}, { root: true });
        }

        return config;
    }, error => Promise.reject(error));

    instance.interceptors.response.use(response => {
        global.app.$store.commit('common/SET_LOADING', false);

        const data = response.data;
        if (data?.display) {
            global.app.$toastr.success(data.message);
        }

        return data;
    }, error => {
        global.app.$store.commit('common/SET_LOADING', false);

        if (!error.response) {
            global.app.$toastr.error('No response received. We regret the inconvenience. Please try again later.');
            return Promise.reject(error);
        }

        const { status, data } = error.response;
        const currentQuery = global.app.$router.currentRoute.query;

        if (status === 401) {
            global.app.$router.push({
                name: 'login',
                query: { ...currentQuery } // Retain query parameters
            });
        } else if (status === 400 && data?.errors) {
            global.app.$store.commit("SET_ERRORS", data.errors);
        }

        if (data?.display) {
            global.app.$toastr.error(data.message);
        }

        return Promise.reject(data);
    });

    return instance;
};

export default Service;
