<template>
  <div v-if="page">
    <Banner :page="page" />

    <section class="profile events-page-wrap clearfix">
      <div class="container">
        <div class="row">
          <div class="col-md-12 kothing-editor-editable text-left" style="background-color: transparent !important;" v-html="page.details">
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Banner from '@/components/shared/Banner'

export default {
  name: 'AboutUs',
  components: {
    Banner
  },

  computed: {
    ...mapGetters('page', ['page']),
  },

  destroyed() {
    document.body.classList.remove('contact')
  },

  mounted() {
    document.body.id = 'Whoosh';
    document.body.classList.add('contact');
    this.$store.dispatch('page/view', 'sunday-race-club');
  },
}
</script>
