import Service from "@/services";
const use_encryption = process.env.VUE_APP_ADD_PAYLOAD_ENCRYPTION || false
const encryption_key = process.env.VUE_APP_PAYLOAD_ENCRYPTION_KEY || null
const activities_obj = {results:[],totalCount:0,totalPages:0,currentPage:0,user:null,response_default:null};

import CryptoJS from "crypto-js"

export default {
    namespaced: true,
    state: {
        rider_default_data:null,
        profile: null,
        user_total_event_count:0,
        single_ride_data: {detail:[],header_detail:{}},
        activities: activities_obj,
        compare_data: [],

        avatar: 'assets/images/profile-pic.jpg',
    },
    getters: {
        profile: (state) => state.profile,

        single_ride_data: (state) => state.single_ride_data,
        avatar: (state) => state.avatar,
        activities: (state) => state.activities,
        compare_data: (state) => state.compare_data,
        rider_default_data: (state) => state.rider_default_data,
        user_total_event_count: (state) => state.user_total_event_count
    },

    actions: {
        async submitThirdPartyImport({commit},payload){

            try {
                return await Service().post('../api/auth/post-third-party-data',payload)
                    .then(res => {
                        commit('SET_RIDER_DEFAULT_DATA', res?.data);
                        return res
                    });
            } catch (e) {
                commit('SET_THIRD_PARTY_DATA', []);
                return e
            }

        },

        async profile({commit}) {
            try {
                return await Service().get('../api/auth/profile').then(res => {
                    commit('SET_PROFILE', res.data);
                    commit('SET_AVATAR', res.data.avatar);
                    localStorage.setItem('avatar', res.data.avatar)
                    return res;
                });
            } catch (e) {
                commit('SET_PROFILE', null);
                console.log('Error: ', e);
            }
        },
        async get_single_ride_data({commit},payload) {
            try {
                if(use_encryption){
                    payload = CryptoJS.AES.encrypt(JSON.stringify(payload) ,encryption_key).toString();
                }

                return await Service().post(`${process.env.VUE_APP_RIDER_PROFILE_JAVA_API_URL}/rider/profile/detailed-graph-data`,payload)
                    .then(res1 => {
                         return Service().post(`${process.env.VUE_APP_RIDER_PROFILE_JAVA_API_URL}/rider/profile/activity-info`,payload)
                             .then(res2 => {
                                 commit('SET_SINGLE_RIDE_DATA', {detail:res1?.data,header_detail:res2?.data});
                        });
                    });
            } catch (e) {
                commit('SET_SINGLE_RIDE_DATA', {detail:[],header_detail:{}});
                console.log('Error: ', e);
            }
        },
        async get_compare_data({commit},payload) {
            try {

                if(use_encryption){
                    payload = CryptoJS.AES.encrypt(JSON.stringify(payload) ,encryption_key).toString();
                }

                return await Service()
                    .post(`${process.env.VUE_APP_RIDER_PROFILE_JAVA_API_URL}/rider/profile/power-curve`,payload)
                    .then(res => {
                        commit('SET_COMPARE_DATA', res.data);
                      return res.data;
                    });
            } catch (e) {
                commit('SET_COMPARE_DATA', []);
                console.log('Error: ', e);
            }
        },
        async new_activities({commit},payload) {
          try {
               if(use_encryption){
                    payload = CryptoJS.AES.encrypt(JSON.stringify(payload) ,encryption_key).toString();
               }

                return await Service()
                    .post(`${process.env.VUE_APP_RIDER_PROFILE_JAVA_API_URL}/rider/profile/activities`,payload)
                    .then(res => {
                        if(!res.data){
                            commit('SET_ACTIVITIES',activities_obj);
                            return {error:true}
                        }
                        commit('SET_ACTIVITIES', res.data);
                        return res?.data || []
                });
           } catch (e) {
                commit('SET_ACTIVITIES',activities_obj);
            }
        },
        async get_rider_default_data({commit}) {
            try {
                return await Service().post('../api/auth/get-default-data-for-rider-profile')
                    .then(res => {
                        commit('SET_RIDER_DEFAULT_DATA', res.data);

                        return Service().get(`${process.env.VUE_APP_RIDER_PROFILE_JAVA_API_URL}/rider/profile/get-user-events-count`)
                            .then(({data}) => {
                                commit('SET_USER_TOTAL_EVENT_COUNT', data?.total);
                            });

                    });
            } catch (e) {
                commit('SET_RIDER_DEFAULT_DATA', null);
                commit('SET_USER_TOTAL_EVENT_COUNT', 0);
                console.log('Error: ', e);
            }
        },
        async downloadFile(_, payload) {
            try {

                if(use_encryption){
                    payload = CryptoJS.AES.encrypt(JSON.stringify(payload) ,encryption_key).toString();
                }

                return await
                    Service().post(`${process.env.VUE_APP_RIDER_PROFILE_JAVA_API_URL}/rider/profile/download-activity-file`,payload);
            } catch (e) {
                console.log('Error: ', e);
            }
        },

        async update({commit}, payload) {
            try {
                return await Service().put('../api/auth/profile', payload).then(res => {
                    Service().get('../api/auth/profile').then(res => {
                        commit('SET_PROFILE', res.data);
                        if(typeof res.data.avatar !== "undefined"){
                            commit('SET_AVATAR', res.data.avatar);
                            localStorage.setItem('avatar', res.data.avatar)
                        }else{
                            commit('SET_AVATAR', "");
                            localStorage.setItem('avatar', "")
                        }
                    });
                    return res;
                });
            } catch (e) {
                 console.log('Error: ', e);
           }
        },

        async updatePassword(_, payload) {
            try {
                return await Service().post('../api/auth/update_password', payload);
            } catch (e) {
                console.log('Error: ', e);
            }
        },

        // upload user avatar
        async avatar({commit}, payload) {
            try {
                return await Service().post('../api/auth/avatar', payload).then(res => {
                    if (res && res.status) {
                        commit('SET_AVATAR', res.data.avatar);
                        localStorage.setItem('avatar', res.data.avatar)
                    }
                });
            } catch (e) {
                console.log('Error: ', e);
            }
        },

        async removeAvatar({commit}) {
            try {
                return await Service().delete('../api/auth/avatar').then(res => {
                    if (res && res.status) {
                        commit('SET_AVATAR', res.data.avatar);
                        localStorage.setItem('avatar', res.data.avatar)
                    }
                });
            } catch (e) {
                console.log('Error: ', e);
            }
        },

        async removeConnection(_, payload) {
            return await Service().post('../api/auth/remove_connection', payload);
        },

        async deleteAccount(_, payload) {
            return await Service().post('../mobile/delete-account', {email: payload.email});
        },
        async setUserHasSeen({commit}, payload) {
            return await Service().post('../api/auth/set-user-has-seen', payload).then (res => {
                commit('SET_RIDER_DEFAULT_DATA', res?.data);
            });

        },



    },

    mutations: {
        SET_PROFILE: (state, payload) => state.profile = payload,
        SET_AVATAR: (state, payload) => state.avatar = payload,
        SET_USER_TOTAL_EVENT_COUNT: (state, payload) => state.user_total_event_count = payload,
        SET_ACTIVITIES(state, payload){
            if(payload.results.length > 0){
                if(payload.currentPage === 1){
                    state.activities.results = payload.results
                }else{
                    state.activities.results.push(...payload.results)
                }

                state.activities.totalCount = payload.totalCount
                state.activities.totalPages = payload.totalPages
                state.activities.currentPage = payload.currentPage
            }else{
                state.activities = activities_obj;
            }
        },
        SET_COMPARE_DATA: (state, payload) => state.compare_data = payload,
        SET_RIDER_DEFAULT_DATA: (state, payload) => state.rider_default_data = payload,
        SET_SINGLE_RIDE_DATA: (state, payload) => state.single_ride_data = payload,

    }
}
