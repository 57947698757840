<template>
  <div v-if="article">
    <Banner :page="article" />

    <section class="profile events-page-wrap clearfix">
      <div class="container">
        <div class="row">
          <div class="col-md-12 kothing-editor-editable" style="background-color: transparent !important;">
            <h1>{{article.title}}</h1>
            <span v-html="article.content"></span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Banner from '@/components/shared/Banner'

export default {
  name: 'Article',

  data() {
    return {
      slug: this.$route.params.slug
    }
  },

  computed: {
    ...mapGetters('page', ['article']),
  },

  components: {
    Banner
  },

  mounted() {
    document.body.id = 'Whoosh';
    document.body.classList.add('contact');
    this.$store.dispatch('page/getArticle', this.slug);
  },
}
</script>
