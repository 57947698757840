<template>
    <div>
        <b-tabs nav-class="v-nav" pills vertical>
            <!-- Apparel Jersey -->
            <b-tab title="Jersey" active>
                <Product
                    @activateModalChild="activateModalChild"
                    :products="jerseys"
                    :user-gems="userGems"
                    :user-coins="userCoins"
                />
            </b-tab>

            <!-- Apparel Head Gear -->
            <b-tab title="Head Gear">
                <Product
                    @activateModalChild="activateModalChild"
                    :products="helmet"
                    :user-gems="userGems"
                    :user-coins="userCoins"
                />
            </b-tab>

            <!-- Apparel Shoes -->
            <b-tab title="Shoes">
                <Product
                    @activateModalChild="activateModalChild"
                    :products="shoes"
                    :user-gems="userGems"
                    :user-coins="userCoins"
                />
            </b-tab>

            <!-- Apparel Socks -->
            <b-tab title="Socks">
                <Product
                    @activateModalChild="activateModalChild"
                    :products="socks"
                    :user-gems="userGems"
                    :user-coins="userCoins"
                />
            </b-tab>

            <!-- Apparel Gloves -->
            <b-tab title="Gloves">
                <Product
                    @activateModalChild="activateModalChild"
                    :products="gloves"
                    :user-gems="userGems"
                    :user-coins="userCoins"
                />
            </b-tab>

            <!-- Apparel Eye wear -->
            <b-tab title="Eye wear">
                <Product
                    @activateModalChild="activateModalChild"
                    :products="glasses"
                    :user-gems="userGems"
                    :user-coins="userCoins"
                />
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import Product from "./Product.vue";



export default {
    name: "Apparel",
    props: ["userGems", "userCoins","glasses","jerseys","socks","shoes","helmet","gloves"],

    components: {
        Product,
    },

    data() {
        return { };
    },
    methods: {
      activateModalChild(tabIndex){
        this.$emit('activateModalParent',tabIndex)
      },
        defineMethodName() {
            //do stuff here
        },
    },
    created() {},
};
</script>

<style scoped></style>
