<template>
  <section>
    <div class="profie-img">
      <img
          alt="player profile image"
          style="height:200px;width:200px;"
          class="img-fluid cursor-pointer"
          :src="makeAvatar(avatar)"
          @click="$refs.file.click()"
      />
      <input ref="file" class="d-none" type="file" @change="uploadAvatar" @click="resetImageUploader" accept="image/*"/>
      <button href="#" class="text-primary border-0 mt-3 pointer profile-btn" @click="$refs.file.click()">Change</button>
      <button v-if="avatar.length > 25" href="#" class="text-danger border-remove mt-3 pointer profile-btn" @click="removeAvatar">Remove</button>
    </div>
  </section>
</template>

<script>
import {mapGetters} from 'vuex';
import {commonMixin} from '@/mixins/commonMixin';
import Swal  from 'sweetalert2'

export default {
  name: 'ProfileAvatar',
  mixins: [commonMixin],

  computed: {
    ...mapGetters('user', ['avatar']),
  },

  methods: {
    makeAvatar() {
      const def = this.avatar.split('/')
      if (def[def.length - 1] === 'avatar.png') {
        return this.assetsUrl() + '/images/' + this.avatar
      }
      
      return this.avatar;
      // return this.makeImage(this.avatar, 350, 350, true)
    },

    removeAvatar() {
      this.$store.dispatch('user/removeAvatar');
    },

    resetImageUploader() {
      this.$refs.file.value = '';
    },
    
    uploadAvatar(e) {
      const formData = new FormData();
      const files = e.target.files || e.dataTransfer.files;

      if(files[0].type.indexOf('image')==-1){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Invalid Image Type',
        });

        return;
      }

      formData.append('avatar', files[0]);
      this.$store.dispatch('user/avatar', formData);
    },

  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
