<template>
  <div>
    <b-modal ref="shop_payment_modal" modal-class="payment" hide-footer>
      <template #modal-header>
        <h5>Shop</h5>
        <button
            @click="hideModal"
            class="close"
            aria-label="Close"
            type="button"
        >
          <img src="/assets/images/shop/icons/close2.png" alt="" />
        </button>
      </template>

      <template #default>
        <LoadingIcon v-if="showLoading" ref="loading_msg" />
        <MessageSuccess
            :coins_purchased="coins_purchased"
            :gems_purchased="gems_purchased"
            v-if="showSuccessMsg"
            ref="success_msg_component"
        />
        <MessageError
            :coins_purchased="coins_purchased"
            :gems_purchased="gems_purchased"
            v-if="showFailureMsg"
            ref="error_msg_component"
        />

        <b-tabs
            v-if="showPackages"
            @input="handleTabUpdate"
            ref="package_section"
        >
          <b-tab title="Coins" :active="localActiveTab === 0">
            <b-row>
              <b-col
                  sm="6"
                  lg="4"
                  class="px-2 mb-3"
                  v-for="(item, index) in coins_bundles"
                  :key="index + 'gems'"
              >
                <b-card
                    @click="
                                        purchaseGems(
                                            item.Identifier,
                                            item.Price
                                        )
                                    "
                >
                  <template #header>
                    <img
                        src="/assets/images/shop/icons/coin.png"
                        alt="Coins"
                    />
                    <span>{{ item.Reward }}</span>
                  </template>
                  <img
                      :src="`/assets/images/shop/icons/coins-${
                                            index + 1
                                        }x.png`"
                      alt="Coins"
                  />
                  <div class="card-cta">
                                        <span v-if="false" class="ex-price">
                                            <img
                                                src="/assets/images/shop/icons/gem.png"
                                                alt="Gems"
                                            />
                                            600
                                        </span>
                    <span class="price">
                                            <img
                                                src="/assets/images/shop/icons/gem.png"
                                                alt="Gems"
                                            />
                                            <span>{{ item.Price }}</span>
                                            <small
                                                style="color: red"
                                                v-if="userGems < item.Price"
                                            >Not Enough Gems</small
                                            >
                                        </span>
                  </div>
                  <strong v-if="false" class="offer"
                  >Limited Time</strong
                  >
                </b-card>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab title="Power Gems" :active="localActiveTab === 1">
            <b-row>
              <b-col
                  sm="6"
                  lg="4"
                  class="px-2 mb-3"
                  v-for="(item, index) in gems_bundles"
                  :key="index + 'coins'"
              >
                <b-card
                    @click="
                                        getRedirectUrl(item.Identifier)
                                    "
                >
                  <template #header>
                    <img
                        src="/assets/images/shop/icons/gem.png"
                        alt="Gems"
                    />
                    <span>{{ item.Reward }}</span>
                  </template>
                  <img
                      :src="`/assets/images/shop/icons/gems-${
                                            index + 1
                                        }x.png`"
                      alt="Gems"
                  />
                  <div class="card-cta">
                                        <span v-if="false" class="ex-price"
                                        >AED 350</span
                                        >
                    <span class="price"
                    >AED {{ item.Price }}</span
                    >
                  </div>
                  <strong v-if="false" class="offer"
                  >Limited Time</strong
                  >
                </b-card>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>

      </template>

      <template #modal-footer></template>
    </b-modal>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import LoadingIcon from "./LoadingIcon.vue";
import MessageSuccess from "./MessageSuccess.vue";
import MessageError from "./MessageError.vue";
import Swal from "sweetalert2";

export default {
  name: "PaymentModal",
  props: ["userGems", "userCoins"],
  components: {
    LoadingIcon,
    MessageSuccess,
    MessageError,
  },
  data() {
    return {
      localActiveTab: 0,
      showLoading: false,
      showSuccessMsg: false,
      showFailureMsg: false,
      showPackages: true,
      gems_purchased: null,
      coins_purchased: null,
      pollingInterval: null,
      orderId: null,

    };
  },
  methods: {
    handleTabUpdate(newTab) {
      this.localActiveTab = newTab;
    },
    async getRedirectUrl(package_id) {
      try {
        const payload = { package_id };
        const { data } = await this.$store.dispatch("gameshop/getRedirectUrl", payload);

        if (data.session_url) {
          window.location.href = data.session_url;
        } else {
          throw new Error("Session URL not found in response");
        }
      } catch (error) {
        console.error("Error getting Stripe redirect URL:", error);
        alert("Unable to process at this moment. Please try again later.");
      }
    },
    activateTab(tabIndex) {
      this.showSuccessMsg = false;
      this.showFailureMsg = false;
      this.showPackages = true;
      this.gems_purchased = null;
      this.coins_purchased = null;

      this.$refs.shop_payment_modal.show();
      this.localActiveTab = tabIndex;
    },
    async purchaseGems(package_id, price) {
      if (this.userGems < price) {
        this.localActiveTab = 1;
        return;
      }

      try {
        const res = await this.$store.dispatch("gameshop/purchaseGems", { package_id });

        if (res.status) {
          this.showSuccessMessage('coins',res.data.reward);
        } else {
          this.showFailureMessage();
        }
      } catch (error) {
        console.error("Error purchasing gems:", error);
        this.showFailureMessage();
      }
    },
    showSuccessMessage(type='coins',reward) {
      this.showPackages = false;
      this.showSuccessMsg = true;
      if(type === 'gems'){
        this.gems_purchased = reward;
      }else{
        this.coins_purchased = reward;
      }

    },
    showFailureMessage() {
      this.showPackages = false;
      this.showFailureMsg = true;
    },
    showModal() {

        this.$refs.shop_payment_modal.show();

    },
    hideModal() {
      this.$refs.shop_payment_modal.hide();
      window.location.href = "/user/activities";
    },
    async startPolling(orderId, maxAttempts = 3, interval = 10000) {
      let attempts = 0;

      const poll = async () => {
        try {
          // Make the first request
          const res = await this.$store.dispatch("gameshop/getPaymentInfo", { order_id: orderId });

          if (res.status) {
            Swal.close(); // Close the loading modal
            this.showSuccessMessage('gems', res.data.reward); // Show success message
            this.showModal();
            return; // Stop further polling if successful
          } else {
            attempts++;

            if (attempts >= maxAttempts) {
              Swal.close(); // Close the loading modal
              this.showFailureMessage(); // Show failure message
              this.showModal();
              return; // Stop further polling after max attempts
            }

            if (attempts === Math.floor(maxAttempts / 2)) {
              Swal.update({
                html: `<div>
                          <p>This is taking longer than usual. Please stay with us while we continue to check your payment status.</p>
                          <div class="swal2-loading">
                            <div class="spinner"></div>
                          </div>
                        </div>`,
              });
            }
          }
        } catch (error) {
          console.error("Error in polling payment status:", error);
          attempts++;

          if (attempts >= maxAttempts) {
            Swal.close(); // Close the loading modal
            this.showFailureMessage(); // Show failure message
            this.showModal();
            return; // Stop further polling after max attempts
          }
        }

        // Wait for the interval before the next attempt
        await new Promise(resolve => setTimeout(resolve, interval));
        await poll(); // Recursively call poll again
      };

      await poll(); // Start the first polling attempt
    },
    stopPolling() {
      if (this.pollingInterval) {
        clearInterval(this.pollingInterval); // Clear the polling interval
        this.pollingInterval = null;
      }
    },
    async updateGemsCoins() {
      const { payment_result: paymentResult, orderId } = this.$route.query;
      this.orderId = orderId;

      if (!paymentResult || !orderId) return;

      try {
        if (!["success", "error"].includes(paymentResult)) {
          throw new Error("Invalid payment result");
        }
        if (paymentResult === "success") {
          if (!orderId) {
            throw new Error("Order ID is required for a successful payment");
          }
          this.startPolling(orderId);
        } else if (paymentResult === "error") {
          this.showPackages = false;
          this.showFailureMsg = true;
          this.showModal()
        }
      } catch (error) {
        console.error("Error in updateGemsCoins:", error);
        this.showFailureMsg = true;
        Swal.close(); // Close the loading modal
        this.showModal()
      }
    },
    async getBundleInfo() {
      try {
        await this.$store.dispatch("gameshop/getBundleInfo");
      } catch (error) {
        console.error("Error fetching bundle info:", error);
      }
    },
    showWaitModal() {
      const { orderId } = this.$route.query;
      if (orderId) {
        Swal.fire({
          icon: "warning",
          html: `<div>
            <p>Please wait while we are checking your payment. Please do not close your browser.</p>
            <div class="swal2-loading">
              <div class="spinner"></div>
            </div>
          </div>`,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          customClass: {
            container: 'swal2-no-backdrop',
          },
        });
      }
    },
  },
  computed: {
    ...mapGetters("gameshop", ["gems_bundles", "coins_bundles"]),
  },
  created() {
    this.getBundleInfo();
  },
  mounted() {
    this.showWaitModal();
    this.updateGemsCoins();
  },
  beforeDestroy() {
    this.stopPolling();
  },
};
</script>

<style>
.swal2-loading .spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.payment .modal-content {
    border-radius: 20px;
    border: 0;
}

.payment .modal-header {
    background-color: #23292e;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border: 0;
    color: #ffffff;
    padding: 25px 30px;
}

.payment .modal-header h5 {
    font-family: Inter;
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
}

.payment .close {
    border-radius: 0;
    box-shadow: none;
    color: #ffffff;
    opacity: 0.8;
}

.payment .close:focus {
    outline: none;
}

.payment .tabs .nav {
    border: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.payment .tabs .nav-item {
    margin: 0;
    width: auto;
}

.payment .tabs .nav-link {
    border-radius: 0;
    border: 1px solid #b8c3d1;
    color: #252e39;
    font-family: Roboto;
    font-size: 18px;
    line-height: 26px;
    padding: 8px 15px;
    text-align: center;
    text-transform: uppercase;
    width: 170px;
}

.payment .tabs .nav-link.active,
.payment .tabs .nav-link:focus,
.payment .tabs .nav-link:hover {
    background-color: #fcb913;
    border: 1px solid #fcb913;
    color: #252e39;
    font-size: 18px;
    font-weight: 400;
}

.payment .modal-body {
    padding: 30px;
}

.payment .modal-body .row {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
}

.payment .card {
    border-radius: 10px;
    border: 0;
    color: #ffffff;
    cursor: pointer;
    font-family: Roboto;
    overflow: hidden;
    text-align: center;
    transition: box-shadow ease-in-out 0.3s;
}

.payment .card:hover,
.payment .card:focus {
    box-shadow: 0 0 0 5px rgba(37, 46, 57, 0.2);
}

.payment .card-header,
.payment .card-cta {
    align-items: center;
    border: 0;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
}

.payment .card-header {
    background-color: #23292e;
    border-radius: 10px 10px 0px 0px;
    font-size: 24px;
    line-height: 28px;
    padding: 6px 15px;
}

.payment .card-cta {
    background-color: #36425b;
    border-radius: 6px;
    font-family: "Roboto";
    font-size: 20px;
    font-weight: 500;
    height: 44px;
    padding: 8px 15px;
    position: relative;
    z-index: 1;
}

.payment .card-header img + span,
.payment .card-cta img + span {
    margin-left: 5px;
}

.payment .card-body {
    background-color: #252e39;
    padding-top: 10px;
    position: relative;
}

.payment .card-body > img {
    max-width: 175px;
    margin-bottom: 10px;
}

.payment .card-cta .price,
.payment .card-cta .ex-price {
    align-items: center;
    display: inline-flex;
    position: relative;
}

.payment .card-cta .price small {
    font-size: 12px;
    margin-left: 8px;
}

.payment .card-cta .ex-price {
    color: #8193af;
    margin-right: 8px;
}

.payment .card-cta .ex-price img {
    margin-right: 7px;
    max-width: 18px;
}

.payment .card-cta .ex-price::after {
    background-color: #da2e2e;
    border-radius: 3px;
    content: "";
    display: block;
    height: 3px;
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: rotate(-10deg);
    width: 100%;
}

.payment .offer {
    background-color: #ffe600;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    bottom: 58px;
    color: #36425b;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    left: 20px;
    line-height: 10px;
    padding: 5px 12px 10px 6px;
    position: absolute;
    text-transform: uppercase;
    z-index: 0;
}

.payment .offer::after {
    background-color: #ffe600;
    border-top-left-radius: 5px;
    content: "";
    display: block;
    height: 25px;
    position: absolute;
    right: -9px;
    top: 3px;
    transform: rotate(60deg);
    width: 25px;
    z-index: -1;
}

.status {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 500px;
    justify-content: center;
}

.status .icon {
    margin-bottom: 40px;
}

.status h2,
.status h3,
.status h4 {
    color: #36425b;
    font-family: "Roboto";
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    margin: 30px 0;
}

.status p {
    color: #36425b;
    font-family: "Roboto";
    font-size: 24px;
    font-weight: 400;
    text-align: center;
}

@media only screen and (max-width: 767px) {
    .payment .modal-header {
        padding: 10px 15px;
    }

    .payment .modal-body {
        padding: 20px;
    }

    .payment .modal-header h5 {
        font-size: 18px;
        line-height: 30px;
    }

    .payment .close {
        margin: 0 0 0 auto;
        padding: 5px;
    }

    .payment .tabs .nav {
        flex-wrap: nowrap;
    }

    .payment .tabs .nav-item {
        flex: 1 0 auto;
    }

    .payment .tabs .nav-link {
        font-size: 15px;
        line-height: 28px;
        padding: 5px;
        width: auto;
    }

    .payment .tabs .nav-link.active,
    .payment .tabs .nav-link:focus,
    .payment .tabs .nav-link:hover {
        font-size: 15px;
    }

    .payment .card-header {
        font-size: 16px;
        line-height: 18px;
        padding: 6px 12px;
    }

    .payment .card-header > img {
        max-width: 18px;
    }

    .payment .card-body {
        padding: 8px;
    }

    .payment .card-body > img {
        max-width: 100%;
        margin: 0;
        max-height: 85px;
    }

    .payment .card-cta {
        font-size: 18px;
        height: 30px;
        padding: 0;
    }

    .payment .card-cta img {
        max-width: 22px;
    }

    .col-sm-6 {
        width: 50%;
    }
}
</style>
