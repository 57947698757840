export const GlovesSet = [
    {
        ID: "1",
        Name: "MyWhoosh Half-Finger Glove",
        Description:
            "MyWhoosh half-finger gloves are designed to provide protection, grip, and comfort with high ventilation. ",
        itemType: "0",
        coins: "0",
        gems: "0",
        purchasingType: "E_Coins",
        totalKilometers: "0.000000",
        itemUnlockingType: "E_Base",
        unlockingValue: "1",
        MeshId: "1",
        AtlasId: "2",
        U_transform: "0",
        V_transform: "0",
        U_offset: "0.125000",
        V_offset: "0.125000",
        UI_Icon: "1",
        Gender: "E_Male",
        thumbnail: "MyWhoosh Half-Finger Glove - White.png"
    },
    {
        ID: "2",
        Name: "MyWhoosh Half-Finger Glove",
        Description:
            "MyWhoosh half-finger gloves are designed to provide protection, grip, and comfort with high ventilation. ",
        itemType: "0",
        coins: "0",
        gems: "0",
        purchasingType: "E_Coins",
        totalKilometers: "0.000000",
        itemUnlockingType: "E_Base",
        unlockingValue: "1",
        MeshId: "1",
        AtlasId: "2",
        U_transform: "0",
        V_transform: "1",
        U_offset: "0.125000",
        V_offset: "0.125000",
        UI_Icon: "2",
        Gender: "E_Male",
        thumbnail: "MyWhoosh Half-Finger Glove - Sky Blue.png"
    },
    {
        ID: "3",
        Name: "MyWhoosh Half-Finger Glove",
        Description:
            "MyWhoosh half-finger gloves are designed to provide protection, grip, and comfort with high ventilation. ",
        itemType: "0",
        coins: "0",
        gems: "0",
        purchasingType: "E_Coins",
        totalKilometers: "0.000000",
        itemUnlockingType: "E_Base",
        unlockingValue: "2",
        MeshId: "1",
        AtlasId: "2",
        U_transform: "0",
        V_transform: "2",
        U_offset: "0.125000",
        V_offset: "0.125000",
        UI_Icon: "3",
        Gender: "E_Male",
        thumbnail: "MyWhoosh Half-Finger Glove - Burgundy.png"
    },
    {
        ID: "4",
        Name: "MyWhoosh Half-Finger Glove",
        Description:
            "MyWhoosh half-finger gloves are designed to provide protection, grip, and comfort with high ventilation. ",
        itemType: "0",
        coins: "0",
        gems: "0",
        purchasingType: "E_Coins",
        totalKilometers: "0.000000",
        itemUnlockingType: "E_Base",
        unlockingValue: "2",
        MeshId: "1",
        AtlasId: "2",
        U_transform: "0",
        V_transform: "3",
        U_offset: "0.125000",
        V_offset: "0.125000",
        UI_Icon: "4",
        Gender: "E_Male",
        thumbnail: "MyWhoosh Half-Finger Glove - Moss Green.png"
    },
    {
        ID: "5",
        Name: "MyWhoosh Half-Finger Glove",
        Description:
            "MyWhoosh half-finger gloves are designed to provide protection, grip, and comfort with high ventilation. ",
        itemType: "0",
        coins: "0",
        gems: "0",
        purchasingType: "E_Coins",
        totalKilometers: "0.000000",
        itemUnlockingType: "E_Base",
        unlockingValue: "2",
        MeshId: "1",
        AtlasId: "2",
        U_transform: "0",
        V_transform: "4",
        U_offset: "0.125000",
        V_offset: "0.125000",
        UI_Icon: "5",
        Gender: "E_Male",
        thumbnail: "MyWhoosh Half-Finger Glove - Yellow.png"
    },
    {
        ID: "6",
        Name: "MyWhoosh Half-Finger Glove",
        Description:
            "MyWhoosh half-finger gloves are designed to provide protection, grip, and comfort with high ventilation. ",
        itemType: "0",
        coins: "0",
        gems: "0",
        purchasingType: "E_Coins",
        totalKilometers: "0.000000",
        itemUnlockingType: "E_Base",
        unlockingValue: "3",
        MeshId: "1",
        AtlasId: "2",
        U_transform: "0",
        V_transform: "5",
        U_offset: "0.125000",
        V_offset: "0.125000",
        UI_Icon: "6",
        Gender: "E_Male",
        thumbnail: "MyWhoosh Half-Finger Glove - Red.png"
    },
    {
        ID: "7",
        Name: "MyWhoosh Half-Finger Glove",
        Description:
            "MyWhoosh half-finger gloves are designed to provide protection, grip, and comfort with high ventilation. ",
        itemType: "0",
        coins: "0",
        gems: "0",
        purchasingType: "E_Coins",
        totalKilometers: "0.000000",
        itemUnlockingType: "E_Base",
        unlockingValue: "4",
        MeshId: "1",
        AtlasId: "2",
        U_transform: "0",
        V_transform: "6",
        U_offset: "0.125000",
        V_offset: "0.125000",
        UI_Icon: "7",
        Gender: "E_Male",
        thumbnail: "MyWhoosh Half-Finger Glove - Light Pink.png"
    },
    {
        ID: "8",
        Name: "MyWhoosh Half-Finger Glove",
        Description:
            "MyWhoosh half-finger gloves are designed to provide protection, grip, and comfort with high ventilation. ",
        itemType: "0",
        coins: "0",
        gems: "0",
        purchasingType: "E_Coins",
        totalKilometers: "0.000000",
        itemUnlockingType: "E_Base",
        unlockingValue: "5",
        MeshId: "1",
        AtlasId: "2",
        U_transform: "0",
        V_transform: "7",
        U_offset: "0.125000",
        V_offset: "0.125000",
        UI_Icon: "8",
        Gender: "E_Male",
        thumbnail: "MyWhoosh Half-Finger Glove - Sefo Green.png"
    },
    {
        ID: "9",
        Name: "MyWhoosh Black/White Glove",
        Description:"Go the safe way",
        itemType: "0",
        coins: "0",
        gems: "0",
        purchasingType: "E_Coins",
        totalKilometers: "0.000000",
        itemUnlockingType: "E_Base",
        unlockingValue: "6",
        MeshId: "2",
        AtlasId: "2",
        U_transform: "0",
        V_transform: "0",
        U_offset: "0.125000",
        V_offset: "0.125000",
        UI_Icon: "9",
        Gender: "E_Male",
        thumbnail: "MyWhoosh Black/White Glove.png"
    },
    {
        ID: "10",
        Name: "MyWhoosh Blue Glove",
        Description:"Matches those nice blue shirts.",
        itemType: "0",
        coins: "0",
        gems: "0",
        purchasingType: "E_Coins",
        totalKilometers: "0.000000",
        itemUnlockingType: "E_Base",
        unlockingValue: "7",
        MeshId: "2",
        AtlasId: "2",
        U_transform: "0",
        V_transform: "1",
        U_offset: "0.125000",
        V_offset: "0.125000",
        UI_Icon: "10",
        Gender: "E_Male",
        thumbnail: "MyWhoosh Blue Glove.png"
    },
    {
        ID: "11",
        Name: "MyWhoosh Appleblue Seagreen",
        Description: "Ultimate glove.",
        itemType: "0",
        coins: "0",
        gems: "0",
        purchasingType: "E_Coins",
        totalKilometers: "0.000000",
        itemUnlockingType: "E_Base",
        unlockingValue: "8",
        MeshId: "2",
        AtlasId: "2",
        U_transform: "0",
        V_transform: "2",
        U_offset: "0.125000",
        V_offset: "0.125000",
        UI_Icon: "11",
        Gender: "E_Male",
        thumbnail: "MyWhoosh Appleblue Seagreen.png"
    },
    {
        ID: "12",
        Name: "MyWhoosh Sprint Green",
        Description: "Be fast and aero.",
        itemType: "0",
        coins: "0",
        gems: "0",
        purchasingType: "E_Coins",
        totalKilometers: "0.000000",
        itemUnlockingType: "E_Base",
        unlockingValue: "9",
        MeshId: "2",
        AtlasId: "2",
        U_transform: "0",
        V_transform: "3",
        U_offset: "0.125000",
        V_offset: "0.125000",
        UI_Icon: "12",
        Gender: "E_Male",
        thumbnail: "MyWhoosh Sprint Green.png"
    },
    {
        ID: "13",
        Name: "MyWhoosh Ciclamino",
        Description:
            "Stand out of the crowd.",
        itemType: "0",
        coins: "0",
        gems: "0",
        purchasingType: "E_Coins",
        totalKilometers: "0.000000",
        itemUnlockingType: "E_Base",
        unlockingValue: "10",
        MeshId: "2",
        AtlasId: "2",
        U_transform: "0",
        V_transform: "4",
        U_offset: "0.125000",
        V_offset: "0.125000",
        UI_Icon: "13",
        Gender: "E_Male",
        thumbnail: "MyWhoosh Ciclamino.png"
    },
    {
        ID: "14",
        Name: "MyWhoosh Vuelta Glove",
        Description:
            "For those who lead the pack.",
        itemType: "0",
        coins: "0",
        gems: "0",
        purchasingType: "E_Coins",
        totalKilometers: "0.000000",
        itemUnlockingType: "E_Base",
        unlockingValue: "11",
        MeshId: "2",
        AtlasId: "2",
        U_transform: "0",
        V_transform: "5",
        U_offset: "0.125000",
        V_offset: "0.125000",
        UI_Icon: "14",
        Gender: "E_Male",
        thumbnail: "MyWhoosh Vuelta Glove.png"
    },
    {
        ID: "15",
        Name: "MyWhoosh Flash grey",
        Description:
            "No compremises in performance.",
        itemType: "0",
        coins: "0",
        gems: "0",
        purchasingType: "E_Coins",
        totalKilometers: "0.000000",
        itemUnlockingType: "E_Base",
        unlockingValue: "12",
        MeshId: "2",
        AtlasId: "2",
        U_transform: "0",
        V_transform: "6",
        U_offset: "0.125000",
        V_offset: "0.125000",
        UI_Icon: "15",
        Gender: "E_Male",
        thumbnail: "MyWhoosh Flash grey.png"
    },
    {
        ID: "16",
        Name: "MyWhoosh Desert Storm Glove",
        Description:
            "One for the Team.",
        itemType: "0",
        coins: "0",
        gems: "0",
        purchasingType: "E_Coins",
        totalKilometers: "0.000000",
        itemUnlockingType: "E_Base",
        unlockingValue: "13",
        MeshId: "2",
        AtlasId: "2",
        U_transform: "0",
        V_transform: "7",
        U_offset: "0.125000",
        V_offset: "0.125000",
        UI_Icon: "16",
        Gender: "E_Male",
        thumbnail: "MyWhoosh Desert Storm Glove.png"
    },
    {
        ID: "17",
        Name: "MyWhoosh Radiance Glove",
        Description:
            "MyWhoosh Radiance Glove, style and performance. Everything you require.",
        itemType: "0",
        coins: "0",
        gems: "0",
        purchasingType: "E_Coins",
        totalKilometers: "0.000000",
        itemUnlockingType: "E_Base",
        unlockingValue: "14",
        MeshId: "1",
        AtlasId: "3",
        U_transform: "0",
        V_transform: "0",
        U_offset: "0.125000",
        V_offset: "0.125000",
        UI_Icon: "17",
        Gender: "E_Male",
        thumbnail: "MyWhoosh Radiance Glove.png"
    },
    {
        ID: "18",
        Name: "MyWhoosh Desert Sand Glove",
        Description:
            "MyWhoosh Desert Sand Glove, classic design and fit. Everything you require.",
        itemType: "0",
        coins: "0",
        gems: "0",
        purchasingType: "E_Coins",
        totalKilometers: "0.000000",
        itemUnlockingType: "E_Base",
        unlockingValue: "15",
        MeshId: "1",
        AtlasId: "3",
        U_transform: "0",
        V_transform: "1",
        U_offset: "0.125000",
        V_offset: "0.125000",
        UI_Icon: "18",
        Gender: "E_Male",
        thumbnail: "MyWhoosh Desert Sand Glove.png"
    },
];
