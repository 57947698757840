<template>
    <section>
        <h3>Delete Account</h3>
        <p class="px-5">You will receive an email to confirm deletion of the account.</p>
        <button class="btn-danger border-0 p-2 pl-5 pr-5" type="button" @click="deleteHandler">Delete My Account</button>
    </section>
</template>

<script>
export default {
    name: 'DeleteAccount',
    props: ['email'],

    data() {
        return {}
    },

    methods: {
        deleteHandler() {
            this.$store.dispatch('user/deleteAccount', {email: this.email}).then(res => {
                if (res && res.status) {
                    this.$store.dispatch("auth/logout");
                    this.$router.push({ name: "login" });
                }
            });
        },
    }
}
</script>