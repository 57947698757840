import Service from "@/services";

export default {

    namespaced: true,

    state: {
        upcoming_events: [],
        events: [],
        event: {},
        otpSentStatus: false,
        otpVerifyStatus: false,
        dob_required: true,
        participantRegisterStatus: null
    },

    getters: {
        events: (state) => state.events,
        upcoming_events: (state) => state.upcoming_events,
        event: (state) => state.event,
        otpSentStatus: (state) => state.otpSentStatus,
        otpVerifyStatus: (state) => state.otpVerifyStatus,
        participantRegisterStatus: (state) => state.participantRegisterStatus
    },

    actions: {
        async upcoming_events({commit}) {
            try {
                return await Service().get('events/upcoming').then(res => {
                    commit('SET_UP_COMING_EVENTS', res.data);
                });
            } catch (e) {
                console.log('Error: ', e);
            }
        },

        async events({commit}) {
            try {
                return await Service().get('events').then(res => {
                    commit('SET_EVENTS', res.data);
                });
            } catch (e) {
                console.log('Error: ', e);
            }
        },

        async event({commit}, id) {
            try {
                return await Service().get(`events/${id}`).then(res => {
                    commit('SET_EVENT', res.data);
                    return res;
                });
            } catch (e) {
                console.log('Error: ', e);
            }
        },

        async sendOtp({commit}, payload) {
            try {
                return await Service().post(`events/otp/send`, payload).then(res => {
                    if (res.status) {
                        commit('SET_OTP_SENT_STATUS', true);
                        return res;
                    }
                    commit('SET_OTP_SENT_STATUS', false);
                    return false;
                });
            } catch (e) {
                commit('SET_OTP_SENT_STATUS', false);
                console.log('Send OTP Error: ', e);
            }
        },

        async verifyOtp({commit}, payload) {
            try {
                return await Service().post(`events/otp/verify`, payload).then(res => {
                    if (res.status) {
                        commit('SET_OTP_VERIFY_STATUS', true);
                        return res;
                    }
                    commit('SET_OTP_VERIFY_STATUS', false);
                    return false;
                });
            } catch (e) {
                commit('SET_OTP_VERIFY_STATUS', false);
                console.log('Send OTP Error: ', e);
            }
        },

        async registration({commit}, payload) {
            try {
                return await Service().post(`events/registration`, payload).then(res => {
                    if (res.status) {
                        commit('SET_PARTICIPANT_REGISTER_STATUS', true);
                        return true;
                    }
                    commit('SET_PARTICIPANT_REGISTER_STATUS', false);
                    return false;
                });
            } catch (e) {
                commit('SET_PARTICIPANT_REGISTER_STATUS', false);
                console.log('Error: ', e);
            }
        },

        async submitApplication({commit}, payload) {
            try {
                const res = await Service().post(`events/event_application`, payload);
                commit('SET_EVENT', {});
                return res;
            } catch (e) {
                let errors = [];
                if(e.data){
                    errors.push(e.data);
                }else{
                    Object.keys(e?.errors).forEach(key => {
                        errors.push(e?.errors[key]);
                    });
                }
                commit('SET_ERRORS', errors, { root: true });
                return
            }
        },

        // download user file from event_metadata
        async downloadFile(_, payload) {
            try {
                return await Service().post('events/file_download', payload);
            } catch (e) {
                console.log('Error: ', e);
            }
        },
    },

    mutations: {
        SET_UP_COMING_EVENTS: (state, payload) => state.upcoming_events = payload,
        SET_EVENTS: (state, payload) => state.events = payload,
        SET_EVENT: (state, payload) => state.event = payload,
        SET_OTP_SENT_STATUS: (state, payload) => state.otpSentStatus = payload,
        SET_OTP_VERIFY_STATUS: (state, payload) => state.otpVerifyStatus = payload,
        SET_PARTICIPANT_REGISTER_STATUS: (state, payload) => state.participantRegisterStatus = payload,
    }
}
