<template>
  <div>
    <vue-title title="Login - Your Indoor Cycling Solution"></vue-title>

    <section class="banner">
      <div class="container">
        <div class="overlay"></div>
        <div class="sreachBar">
          <h1>Profile</h1>
        </div>
      </div>
    </section>

    <section class="login-wrap">
      <div class="container">
        <div class="row">
          <section class="col-md-6 mx-auto">
            <div class="login-box">
              <h3>RECOVER YOUR PASSWORD</h3>
              <span>Using your MyWhoosh account</span>
              <form @submit.prevent="submitHandler" autocomplete="off">
                <div class="form-group">
                  <input type="email" v-model="form.email"  class="form-control" id="exampleInputEmail1" placeholder="Email Address" autocomplete="chrome-off">
                  <div v-if="submitted && getErrors('email')" class="text-danger text-left">A valid email is required</div>
                  <div v-if="submitted && errorResponse" class="text-danger text-left">{{ errorResponse }}</div>
                </div>
                <button type="submit" class="btn-universal">{{resetPassword.key ? "Send Again": "Submit"}}</button>
                <ul class="list-inline justify-content-center">
                  <li><router-link :to="{name: 'login'}">Back to Login</router-link> or <router-link :to="{name: 'register'}">Create New Account?</router-link></li>
                </ul>
              </form>

            </div>
          </section>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {email, required} from 'vuelidate/lib/validators'

export default {
  name: 'ForgotPassword',

  data() {
    return {
      form: {},
      submitted: false,
      errorResponse: null
    }
  },

  validations: {
    form: {
      email: {required, email},
    }
  },

  computed: {
    ...mapGetters('auth', ['resetPassword']),
    ...mapGetters(['errors']),
  },

  mounted() {
    // add contact class to body
    document.body.classList.add('contact');
  },
  destroyed() {
    document.body.classList.remove('contact')
  },

  methods: {
    submitHandler() {
      this.submitted = true;

      this.$v.$touch();
      if (this.$v.$invalid) return;


      this.$store.dispatch('auth/resetPassword', this.form).then(res => {
        if (res) {
          this.$router.push({path: '/'});
          return
        } else {
          this.errorResponse = "Invalid email address provided.";
        }
        return res;
      });
    },

    getErrors(field) {
      return this.$v.form[field].$invalid;
    },
  },
}
</script>

<style scoped>
.text-danger {
  font-size: 12px;
}
</style>
