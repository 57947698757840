import Service from "@/services";
import {commonMixin} from "@/mixins/commonMixin";


export default {
    namespaced: true,

    state: {
        user: null,
        loggedIn: null,
        otpStatus: null,
        resetPassword: {},
        updatePassword: {},
        registerStatus: {},
    },

    getters: {
        user: (state) => state.user,
        loggedIn: (state) => state.loggedIn,
        otpStatus: (state) => state.otpStatus,
        resetPassword: (state) => state.resetPassword,
        updatePassword: (state) => state.updatePassword,
        registerStatus: (state) => state.registerStatus,
    },

    actions: {

        async register(_, payload) {
            try {
                return await Service().post('../api/auth/register', payload)
            } catch (e) {
                return false;
            }
        },

        async login({commit}, payload) {
            try {
                return await Service().post('../api/auth/login', payload).then(res => {
                    // eslint-disable-next-line no-unreachable
                    if (res.data && res.data.key) {
                        commit('SET_RESET_PASSWORD', res.data);
                        return 'key';
                    }

                    if (res.status) {
                        const user = JSON.stringify(res.data.user);
                        localStorage.setItem('webToken', res.data.token);
                        localStorage.setItem('webUser', user);
                        localStorage.setItem('uuid', res.data.uuId);
                        
                        // Set cookie for my-whoosh.com
                        const domain = window.location.hostname;
                        const domainParts = domain.split('.');
                        const mainDomain = domainParts.slice(-2).join('.');
                        document.cookie = `whoosh_uuid=${res.data.uuId}; domain=${mainDomain}; path=/`;
                        
                        commit('SET_USER', JSON.parse(user));
                        commit('SET_LOGGED_IN', true);
                        return true;
                    }
                    localStorage.removeItem('webToken');
                    localStorage.removeItem('webUser');
                    localStorage.removeItem('uuid');
                    commit('SET_USER', null);
                    commit('SET_LOGGED_IN', false);
                    return false;
                });
            } catch (e) {
                commit('SET_USER', null);
                commit('SET_LOGGED_IN', false);
                console.log('Error: ', e);
                return false;
            }
        },

        async verifyOtp({commit}, payload) {
            try {
                return await Service().post('../api/auth/verify_email', payload).then(res => {
                    if (res.status) {
                        commit('SET_OTP_STATUS', true);
                        return true;
                    }
                    commit('SET_OTP_STATUS', false);
                    return false;
                });
            }
            catch (e) {
                commit('SET_OTP_STATUS', false);
                console.log('Error: ', e);
                return false;
            }
        },

        async resetPassword({commit}, payload) {
            try {
                return await Service().post('../api/auth/forget_password', payload).then(res => {
                    if (res.status) {
                        commit('SET_RESET_PASSWORD', res.data);
                        return true;
                    }
                    commit('SET_RESET_PASSWORD', {});
                    return false;
                });
            }
            catch (e) {
                commit('SET_RESET_PASSWORD', {});
                console.log('Error: ', e);
                return false;
            }
        },

        async updatePassword({commit}, payload) {
            try {
                return await Service().post('../api/auth/reset_password', payload).then(res => {
                    if (res.status) {
                        commit('SET_UPDATE_PASSWORD', true);
                        return true;
                    }
                    commit('SET_UPDATE_PASSWORD', false);
                    return false;
                });
            }
            catch (e) {
                commit('SET_UPDATE_PASSWORD', false);
                console.log('Error: ', e);
                return false;
            }
        },

        async logout({commit}) {
            commit('SET_USER', null);
            commit('SET_LOGGED_IN', false);
            commit('SET_OTP_STATUS', false);
            commit('SET_RESET_PASSWORD', false);
            commit('SET_UPDATE_PASSWORD', false);
            commit('event/SET_EVENT', {}, { root: true });

            localStorage.removeItem('webToken');
            localStorage.removeItem('webUser');
            localStorage.removeItem('uuid');

            commonMixin.methods.deleteCookie("whoosh_uuid")

            await Service().post('../api/auth/logout');
        },

        loggedIn({commit}) {
            const token = localStorage.getItem('webToken');
            const user = localStorage.getItem('webUser');
            const tokenPassed = token !== undefined &&
                token !== "undefined" &&
                token !== null &&
                token !== "null" &&
                token !== "";
            if (tokenPassed) {
                commit('SET_LOGGED_IN', true);
                commit('SET_USER', JSON.parse(user));
                return;
            }
            commit('SET_USER', null);
            commit('SET_LOGGED_IN', false);
        },

        async replacePassword(_, payload) {
            return await Service().post('../api/auth/change_password', payload);
        },

    },

    mutations: {
        SET_USER: (state, payload) => state.user = payload,
        SET_LOGGED_IN: (state, payload) => state.loggedIn = payload,
        SET_OTP_STATUS: (state, payload) => state.otpStatus = payload,
        SET_RESET_PASSWORD: (state, payload) => state.resetPassword = payload,
        SET_UPDATE_PASSWORD: (state, payload) => state.updatePassword = payload,
    }
}
