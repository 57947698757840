<template>
  <div id="app">
    <Loading v-if="getLoading" />
    <MainLoading v-if="getMainLoading" />
    <router-view/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "@/components/shared/Loading";
import MainLoading from "@/components/shared/MainLoading";

export default {
  name: 'App',
  components: {MainLoading, Loading },
  computed: {
    ...mapGetters("common", ['getLoading', 'getMainLoading', 'getCountries']),
  },

  mounted() {
    this.$store.dispatch('common/getCountries');
  } 
}
</script>
