export const SocksSet = [
    {
        ID: "1",
        Name: "MyWhoosh Explore Sock",
        Description:
            "MyWhoosh Explore socks are designed to provide comfort and support during longer rides, they feature a combination of breathable and moisture-wicking materials to keep feet cool and dry over extended periods.",
        SocksLength: "1",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "1",
        AtlasId: "1",
        U_transform: "0",
        V_transform: "0",
        U_offset: "0.125000",
        V_offset: "0.125000",
        Gender: "0",
        UI_Icon: "0",
        thumbnail: "MyWhoosh Explore Sock - White.png"
    },
    {
        ID: "2",
        Name: "MyWhoosh Explore Sock",
        Description:
            "MyWhoosh Explore socks are designed to provide comfort and support during longer rides, they feature a combination of breathable and moisture-wicking materials to keep feet cool and dry over extended periods.",
        SocksLength: "1",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "1",
        AtlasId: "2",
        U_transform: "0",
        V_transform: "0",
        U_offset: "0.125000",
        V_offset: "0.125000",
        Gender: "0",
        UI_Icon: "1",
        thumbnail: "MyWhoosh Explore Sock - Black.png"
    },
    {
        ID: "3",
        Name: "MyWhoosh Explore Sock",
        Description:
            "MyWhoosh Explore socks are designed to provide comfort and support during longer rides, they feature a combination of breathable and moisture-wicking materials to keep feet cool and dry over extended periods.",
        SocksLength: "1",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "1",
        AtlasId: "1",
        U_transform: "0",
        V_transform: "1",
        U_offset: "0.125000",
        V_offset: "0.125000",
        Gender: "0",
        UI_Icon: "2",
        thumbnail: "MyWhoosh Explore Sock - Blue.png"
    },
    {
        ID: "4",
        Name: "MyWhoosh Explore Sock",
        Description:
            "MyWhoosh Explore socks are designed to provide comfort and support during longer rides, they feature a combination of breathable and moisture-wicking materials to keep feet cool and dry over extended periods.",
        SocksLength: "1",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "1",
        AtlasId: "1",
        U_transform: "0",
        V_transform: "2",
        U_offset: "0.125000",
        V_offset: "0.125000",
        Gender: "0",
        UI_Icon: "3",
        thumbnail: "MyWhoosh Explore Sock - Burgundy.png"
    },
    {
        ID: "5",
        Name: "MyWhoosh Explore Sock",
        Description:
            "MyWhoosh Explore socks are designed to provide comfort and support during longer rides, they feature a combination of breathable and moisture-wicking materials to keep feet cool and dry over extended periods.",
        SocksLength: "1",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "2",
        AtlasId: "2",
        U_transform: "0",
        V_transform: "1",
        U_offset: "0.125000",
        V_offset: "0.125000",
        Gender: "0",
        UI_Icon: "4",
        thumbnail: "MyWhoosh Explore Sock - Light Yellow.png"
    },
    {
        ID: "6",
        Name: "MyWhoosh Explore Sock",
        Description:
            "MyWhoosh Explore socks are designed to provide comfort and support during longer rides, they feature a combination of breathable and moisture-wicking materials to keep feet cool and dry over extended periods.",
        SocksLength: "1",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "2",
        AtlasId: "1",
        U_transform: "0",
        V_transform: "3",
        U_offset: "0.125000",
        V_offset: "0.125000",
        Gender: "0",
        UI_Icon: "5",
        thumbnail: "MyWhoosh Explore Sock - Moss Green.png"
    },
    {
        ID: "7",
        Name: "MyWhoosh Race Sock",
        Description:
            "MyWhoosh Race Socks are specialized, performance-oriented socks designed for competitive cycling, crafted with lightweight and breathable materials to keep feet cool and dry during intense races.",
        SocksLength: "1",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "3",
        AtlasId: "1",
        U_transform: "0",
        V_transform: "5",
        U_offset: "0.125000",
        V_offset: "0.125000",
        Gender: "0",
        UI_Icon: "6",
        thumbnail: "MyWhoosh Race Sock - Orange.png"
    },
    {
        ID: "8",
        Name: "MyWhoosh Race Sock",
        Description:
            "MyWhoosh Race Socks are specialized, performance-oriented socks designed for competitive cycling, crafted with lightweight and breathable materials to keep feet cool and dry during intense races.",
        SocksLength: "1",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "3",
        AtlasId: "2",
        U_transform: "0",
        V_transform: "2",
        U_offset: "0.125000",
        V_offset: "0.125000",
        Gender: "0",
        UI_Icon: "7",
        thumbnail: "MyWhoosh Race Sock - Red.png"
    },
    {
        ID: "9",
        Name: "MyWhoosh Race Sock",
        Description:
            "MyWhoosh Race Socks are specialized, performance-oriented socks designed for competitive cycling, crafted with lightweight and breathable materials to keep feet cool and dry during intense races.",
        SocksLength: "1",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "5",
        AtlasId: "1",
        U_transform: "0",
        V_transform: "4",
        U_offset: "0.125000",
        V_offset: "0.125000",
        Gender: "0",
        UI_Icon: "8",
        thumbnail: "MyWhoosh Race Sock - Desert Yellow.png"
    },
    {
        ID: "10",
        Name: "MyWhoosh Race Sock",
        Description:
            "MyWhoosh Race Socks are specialized, performance-oriented socks designed for competitive cycling, crafted with lightweight and breathable materials to keep feet cool and dry during intense races.",
        SocksLength: "1",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "6",
        AtlasId: "1",
        U_transform: "0",
        V_transform: "7",
        U_offset: "0.125000",
        V_offset: "0.125000",
        Gender: "0",
        UI_Icon: "9",
        thumbnail: "MyWhoosh Race Sock - Sefo Green.png"
    },
    {
        ID: "11",
        Name: "MyWhoosh Race Sock",
        Description:
            "MyWhoosh Race Socks are specialized, performance-oriented socks designed for competitive cycling, crafted with lightweight and breathable materials to keep feet cool and dry during intense races.",
        SocksLength: "1",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "8",
        AtlasId: "1",
        U_transform: "0",
        V_transform: "6",
        U_offset: "0.125000",
        V_offset: "0.125000",
        Gender: "0",
        UI_Icon: "10",
        thumbnail: "MyWhoosh Race Sock - Light Pink.png"
    },
    {
        ID: "12",
        Name: "MyWhoosh Race Sock",
        Description:
            "MyWhoosh Race Socks are specialized, performance-oriented socks designed for competitive cycling, crafted with lightweight and breathable materials to keep feet cool and dry during intense races.",
        SocksLength: "1",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "0",
        AtlasId: "2",
        U_transform: "0",
        V_transform: "3",
        U_offset: "0.125000",
        V_offset: "0.125000",
        Gender: "0",
        UI_Icon: "11",
        thumbnail: "MyWhoosh Race Sock - Dark Blue.png"
    },
    {
        ID: "13",
        Name: "MyWhoosh Radiance Sock",
        Description:
            "MyWhoosh Radiance socks provide comfort, style and a classic race fit.",
        SocksLength: "1",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "0",
        AtlasId: "2",
        U_transform: "0",
        V_transform: "6",
        U_offset: "0.125000",
        V_offset: "0.125000",
        Gender: "0",
        UI_Icon: "12",
        thumbnail: "MyWhoosh Radiance Sock.png"
    },
    {
        ID: "14",
        Name: "MyWhoosh Desert Sand Sock",
        Description:
            "MyWhoosh Desert Sand socks provide comfort, style and a classic race fit.",
        SocksLength: "1",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "0",
        AtlasId: "2",
        U_transform: "0",
        V_transform: "7",
        U_offset: "0.125000",
        V_offset: "0.125000",
        Gender: "0",
        UI_Icon: "13",
        thumbnail: "MyWhoosh Desert Sand Sock.png"
    },
];