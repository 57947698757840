<template>
  <div class="events-page-wrap clearfix">
    <vue-title title="Profile - Your Indoor Cycling Solution"></vue-title>

    <!-- <section class="banner bg-m profile-banner">
      <div class="container">
        <div class="overlay"></div>
        <div class="sreachBar">
          <h1>Profile</h1>
        </div>
      </div>
    </section> -->

    <section class="login-wrap">
      <div class="container" v-if="getProfile">
        <div class="row">
          <section class="col-md-4">
            <avatar />

            <div
              id="v-pills-tab"
              aria-orientation="vertical"
              class="nav flex-column nav-pills"
              role="tablist"
            >
              <a
                id="v-pills-profile-tab"
                aria-controls="v-pills-profile"
                aria-selected="true"
                class="nav-link active"
                data-toggle="pill"
                href="#v-pills-profile"
                role="tab"
              >
                <span>Edit Profile</span>
                <i class="fas fa-user"></i>
              </a>

              <a
                id="v-pills-connection-tab"
                aria-controls="v-pills-connection"
                aria-selected="false"
                class="nav-link"
                data-toggle="pill"
                href="#v-pills-connection"
                role="tab"
                @click="connections"
              >
                <span>Connections</span>
                <i class="fas fa-link"></i>
              </a>
              <a
                style="display: none"
                id="v-pills-activity-tab"
                aria-controls="v-pills-activity"
                aria-selected="true"
                class="nav-link"
                data-toggle="pill"
                href="#v-pills-activity"
                role="tab"
              >
                <span>Activity Files</span>
                <i class="fas fa-user"></i>
              </a>
              <a
                id="v-pills-password-tab"
                aria-controls="v-pills-profile"
                aria-selected="true"
                class="nav-link"
                data-toggle="pill"
                href="#v-pills-password"
                role="tab"
              >
                <span>Change Password</span>
                <i class="fas fa-user"></i>
              </a>
              <a
                id="v-pills-delete-account-tab"
                aria-controls="v-pills-profile"
                aria-selected="true"
                class="nav-link text-danger"
                data-toggle="pill"
                href="#v-pills-delete-account"
                role="tab"
              >
                <span>Delete Account</span>
                <i class="fas fa-user"></i>
              </a>
              <a class="nav-link" href="javascript:void(0)" @click="logout">
                <span>Logout</span>
                <i class="fas fa-sign-out-alt"></i>
              </a>
            </div>
          </section>

          <section class="col-md-8" style="background-color: #f4f4f4">
            <div class="login-box">
              <div id="v-pills-tabContent" class="tab-content">
                <div
                  id="v-pills-profile"
                  aria-labelledby="v-pills-profile-tab"
                  class="tab-pane fade show active"
                  role="tabpanel"
                >
                  <h3>EDIT PROFILE</h3>
                  <ul v-if="formErrors.length > 0" class="indicator">
                    <li
                      style="color: red"
                      v-for="(error, e) of formErrors"
                      :key="e"
                    >
                      {{ error }}
                    </li>
                  </ul>

                  <form @submit.prevent="updateHandler" autocomplete="off">
                    <div class="row">
                      <div
                        v-for="(field, i) of schema"
                        :key="i"
                        :class="field.colClass"
                      >
                        <div class="form-group">
                          <label
                            v-if="field.type !== 'consent'"
                            v-text="field.label"
                          ></label>
                          <template v-if="field.type === 'select'">
                            <select
                              v-model="form[field.model]"
                              class="form-control"
                              :disabled="field.readonly"
                            >
                              <option
                                :value="dd.Id"
                                :key="index"
                                v-for="(dd, index) in dropdowns[field.options]"
                              >
                                {{ dd.Name }}
                              </option>
                            </select>
                            <!-- <v-select
                              class="style-chooser"
                              label="Name"
                              :searchable="field.model !== 'gender'"
                              :options="dropdowns[field.options]"
                              v-model="form[field.model]"
                              :reduce="option => option.Id"
                              :clearable="false"
                            >
                            </v-select> -->
                          </template>

                          <template v-else-if="field.type === 'date'">
                            <input
                              v-model="form[field.model]"
                              :placeholder="field.label"
                              :type="field.type"
                              class="form-control"
                              autocomplete="chrome-off"
                              :max="giveMaxRangeForDate()"
                            />
                            <!-- <datetime
                                v-model.lazy="form[field.model]"
                                auto
                                class="theme-orange"
                                format="dd-MM-yyyy"
                                input-class="form-control"
                                placeholder="Select date"
                                type="date"
                                max-datetime="2021-01-01"
                            /> -->
                          </template>

                          <template v-else-if="field.type === 'consent'">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                :required="field.validation.required"
                                type="checkbox"
                                v-model="form[field.model]"
                                :id="field.id"
                              />
                              <label
                                class="form-check-label"
                                :for="field.id"
                                v-html="field.consent_text"
                              >
                              </label>
                            </div>
                          </template>

                          <template v-else>
                            <input
                              v-model="form[field.model]"
                              :placeholder="field.label"
                              :type="field.type"
                              :disabled="field.readonly"
                              class="form-control"
                              autocomplete="chrome-off"
                            />
                          </template>

                          <div
                            v-if="submitted && getError(field.model)"
                            class="text-danger"
                          >
                            {{ field.label }} is required.
                          </div>
                        </div>
                      </div>

                      <div class="text-danger">{{ weightError }}</div>
                    </div>

                    <button class="btn-universal" type="submit">Save</button>
                    <a
                      :href="makePageLink('events')"
                      class="btn-universal btn-register-cancel"
                      style="margin-left: 20px"
                      >Cancel</a
                    >
                  </form>
                </div>
                <div
                  id="v-pills-connection"
                  aria-labelledby="v-pills-connection-tab"
                  class="tab-pane fade"
                  role="tabpanel"
                >
                  <social v-if="socialLinks" :linkedNetworks="socialLinks" />
                </div>
                <div
                  id="v-pills-password"
                  aria-labelledby="v-pills-password-tab"
                  class="tab-pane fade"
                  role="tabpanel"
                >
                  <password />
                </div>
                <div
                  id="v-pills-activity"
                  aria-labelledby="v-pills-activity-tab"
                  class="tab-pane fade"
                  role="tabpanel"
                >
                  <activities :files="getFiles" />
                </div>
                <div
                  id="v-pills-delete-account"
                  aria-labelledby="v-pills-delete-account"
                  class="tab-pane fade"
                  role="tabpanel"
                >
                  <deleteAccount :email="form.email" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Avatar from "./Avatar";
import Social from "./Social";
import Activities from "./Activities";
import Password from "./Password";
import deleteAccount from "./DeleteAccount";

import * as dayjs from "dayjs";
// import {Datetime} from 'vue-datetime';
import { required } from "vuelidate/lib/validators";
import { commonMixin } from "@/mixins/commonMixin";
import Schema from "@/json/forms/profile.json";

export default {
  name: "Profile",
  mixins: [commonMixin],
  components: {
    // datetime: Datetime,
    Avatar,
    Social,
    Activities,
    Password,
    deleteAccount,
  },

  validations() {
    return {
      form: this.formValidation,
    };
  },

  data() {
    return {
      submitted: false,
      schema: Schema,
      socialLinks: [],
      dropdowns: {
        countries: [],
        gender: [
          { Id: 0, Name: "Male" },
          { Id: 1, Name: "Female" },
        ],
      },
      form: {
        country: 246,
        gender: 0,
      },
      weightError: null,
      wpURL: process.env.VUE_APP_WEB_URL,
    };
  },

  computed: {
    ...mapGetters(["errors"]),
    ...mapGetters("auth", ["user", "loggedIn"]),
    ...mapGetters("user", ["profile"]),
    ...mapGetters("common", ["getCountries"]),

    formValidation() {
      const fields = {};
      for (const field of this.schema) {
        if (field.validation.required) {
          fields[field.model] = { required };
        } else {
          fields[field.model] = {};
        }
      }
      return fields;
    },

    getProfile() {
      if (!this.profile) return;
      return Object.assign({}, this.profile);
    },

    formErrors() {
      if (this.errors) {
        return Object.values(this.errors);
      }
      return false;
    },

    getFiles() {
      if (!this.getProfile) return [];
      return Object.assign([], this.getProfile.files);
    },

    getUserNameForMenu() {
      if (this.user) {
        return this.user.firstname + " " + this.user.lastname;
      }

      return null;
    },

    makeQueryString() {
      if (!this.user) return `?login=false`;
      return `?login=${this.loggedIn}&profile=${this.getUserNameForMenu}`;
    },
  },

  async mounted() {
    document.body.classList.add("contact");

    await this.$store.dispatch("auth/loggedIn");
    await this.$store.dispatch("user/profile").then((res) => {
      if (!res || !res.status) {
        this.$router.push({ name: "login" });
        return;
      }
      this.form = Object.assign({}, res.data);
    });

    if (this.getCountries.length === 0) {
      await this.$store.dispatch("common/getCountries");
    }
    this.dropdowns.countries = this.getCountries;
  },

  destroyed() {
    document.body.classList.remove("contact");
  },

  watch: {
    "form.weight": {
      deep: true,
      handler: function (nv) {
        this.validateWeight(parseInt(this.form.gender), parseInt(nv));
      },
    },

    "form.gender": {
      deep: true,
      handler: function (nv) {
        this.validateWeight(parseInt(nv), parseInt(this.form.weight));
      },
    },
  },

  methods: {
    giveMaxRangeForDate() {
      var main_data = new Date(
        new Date().setFullYear(new Date().getFullYear() - 16)
      );
      return main_data.getFullYear() + "-01-01";
    },
    makePageLink(slug = null) {
      if (slug) {
        return `${this.wpWebUrl()}/${slug}/${this.makeQueryString}`;
      }

      return `${this.wpWebUrl()}/${this.makeQueryString}`;
    },
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push({ name: "events" });
    },

    connections() {
      this.socialLinks = Object.assign([], this.getProfile.networks);
    },

    updateHandler() {
      this.submitted = true;
      this.$v?.$touch();
      if (this.$v?.$invalid) return;
      if (this.weightError) return;

      const payload = {
        firstname: this.form.firstname,
        lastname: this.form.lastname,
        country: this.form.country,
        gender: this.form.gender,
        dob: dayjs(this.form.dob).format("YYYY-MM-DD"),
        weight: this.form.weight,
        height: this.form.height,
        IsAllowedToSendMarketingEmails:
          this.form.IsAllowedToSendMarketingEmails,
      };
      this.$store.dispatch("user/update", payload);
      // this.$store.dispatch("user/update", this.form);
    },

    getError(field) {
      return this.$v.form[field]?.$invalid;
    },

    validateWeight(gender, weight) {
      this.weightError = null;

      if (gender === 0 && weight < 50) {
        this.weightError = "The weight field must be 50 or more";
      }

      if (gender === 1 && weight < 45) {
        this.weightError = "The weight field must be 45 or more";
      }

      if (weight > 140) {
        this.weightError = "The weight field must be 140 or less";
      }
    },
  },
};
</script>
<style scoped>
input[disabled="disabled"],
select[disabled="disabled"] {
  color: #818181;
  cursor: not-allowed;
  background-color: #e0e0e0 !important;
}
ul.indicator {
  display: block;
  width: 100%;
  text-align: left;
  padding: 2px 19px;
}
ul.indicator li {
  width: 100%;
  text-align: left;
  padding: 2px 2px;
  display: list-item;
  margin: 0 18px;
  font-size: 14px !important;
}
</style>
