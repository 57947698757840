<template>

  <div>
    <!-- <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions"></vue-dropzone> -->

    <div class="">
      <label for="exampleFormControlInput1" class="form-label"
      >Enter your profile link</label
      >

      <input
          type="text"
          class="form-control"
          @blur="$v.profile_link.$touch"
          v-model="profile_link"
          id="exampleFormControlInput1"
          placeholder="Ex: https://www.abc.com/profile"
      />
      <div v-if="getError('profile_link')" class="error-message">
        You need to provide profile link
      </div>
      <div  class="error-message"
            v-if="!$v.profile_link.url && $v.profile_link.error">
        Profile link should be a valid link
      </div>

    </div>
    <br/>
    <label for="exampleFormControlInput1" class="form-label"
    >Upload proof of data</label
    >
    <div v-if="getError('proof_file')" class="error-message">
      You need to provide proof file
    </div>
    <vue-dropzone ref="myVueDropzone" id="dropzone" @vdropzone-max-files-reached="maxFileReached"  @vdropzone-removed-file="deleteFile"  @vdropzone-files-added="sendFile"  :options="dropzoneOptions" :useCustomSlot="true">
      <div class="dropzone-custom-content">
        <h3 class="dropzone-custom-title">
          <button class="chooseBtn">CHOOSE FILE</button>
        </h3>
        <div class="subtitle">
          Or drag image here
        </div>
      </div>
    </vue-dropzone>

  </div>


</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { required,url } from 'vuelidate/lib/validators';
export default {
name: "StepThree",
  data: function () {
    return {
      profile_link:"",
      proof_file:[],

      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 150,
        thumbnailHeight:150,
        addRemoveLinks: true,
        maxFiles:10,
        acceptedFiles:'image/*,application/pdf',
        autoProcessQueue:false,
       // maxFilesize: 10485760,
        // init: function() {
        //   this.on("maxfilesexceeded", function(file) {
        //     this.removeAllFiles();
        //     this.addFile(file);
        //   });
        // }
      }
    }
  },
  validations: {
    profile_link:{required,url},
    proof_file:{required},
    form: ['profile_link','proof_file']
  },
  components:{
    vueDropzone: vue2Dropzone
  },
  methods:{
    maxFileReached(){
      alert("You can only upload maximum 10 files.")
    },
    deleteFile(file){
      this.proof_file.forEach((val,index) => {
        if(file.name === val.name){
          this.proof_file.splice(index, 1);
        }
      })

    },
    resetData () {

      this.profile_link = ""
          this.proof_file = null


    },
    sendFile(files){

      for (let i = 0; i < files.length; i++) {
        var file = files[i];
        this.proof_file.push(file)
        // Do whatever you need with each file
      }


    },
    validate() {
      this.$v.form.$touch();
      const isValid = !this.$v.form.$invalid
      this.$emit('on-validate', this.$data, isValid)
      return isValid
    },
    getError(field) {
      return this.$v[field].$error;
    }
  }
}
</script>

<style scoped>


</style>