<template>
  <div class="event-reg-form">
    <div class="row align-items-center">
      <section class="col-md-6 pr-lg-5">

        <div class="basic-info">
          <div class="input-field form-group">
            <input v-model="firstName" class="form-control" type="text"/>
            <label :class="firstName ? 'top-label':'' ">First Name</label>
            <div v-if="getError('firstName')" class="error-message">
              required
            </div>

          </div>
        </div>

        <div class="basic-info">
          <div class="input-field form-group">
            <input v-model="lastName" class="form-control" type="text"/>
            <label :class="lastName ? 'top-label':'' ">Last Name</label>
            <div v-if="getError('lastName')" class="error-message">
              required
            </div>
          </div>
        </div>
        <small class="text-help-info">
          Please provide your real name as it appears on your Passport/ID. It is mandatory to comply with this requirement in order to avoid disqualification.
        </small>
        <br><br>

        <div class="select-field form-group" v-if="false">
          <label for="gender">Gender</label>
          <v-select
              class="style-chooser"
              label="label"
              :options="[{label: 'Male', value: 0}, {label: 'Female', value: 1}]"
              v-model="gender"
              :reduce="e => e.value"
              :clearable="false"
              id="gender"
          >
          </v-select>

          <div v-if="getError('gender')" class="error-message">
            required
          </div>
        </div>
        <div class="input-field form-group mt-4" v-if="dob_required">
          <input type="date" v-model="dob" class="form-control" id="datePickerId" @change="getDOBYear()" />

          <small class="text-help-info">Your DOB must match the DOB on your Passport/ID</small>
          <div v-if="getError('dob')" class="error-message">
            required
          </div>
        </div>
        <!-- Removed limit after event -->
        <div class="custom-file form-group mt-0 mb-5" v-if="dob_required && age > 0 && (age < minimumDayLimit || age >= maximumDayLimit)">
          <input
              id="dob-certificate"
              class="custom-file-input"
              type="file"
              @change="handleFileUpload('dobCertificate', $event)"
              accept="image/*"
          />
          <label class="custom-file-label" for="dob-certificate">Upload Passport / ID</label>
          <div class="fileName">{{ getFileName() }}</div>
          <div v-if="getError('dobCertificate')" class="error-message">required</div>
          <small class="text-help-info">
            According to your DOB, you are eligible for Youth/Masters classification (Please upload your Passport/ID proof in .jpg or .png format for verification).
          </small>
        </div>

        <div class="input-field form-group mt-4">
          <input v-model="email" class="form-control" type="email" disabled="disabled"/>
          <label :class="email ? 'top-label':'' ">Account Email</label>

          <div v-if="getError('email')" class="error-message">
            Please provide a valid email address
          </div>
        </div>

      </section>
    </div>
  </div>
</template>

<script>
import * as dayjs from 'dayjs';
import {required, requiredIf} from 'vuelidate/lib/validators';
import Swal from "sweetalert2";
// import { mapState } from 'vuex';

export default {
  name: "PersonalForm",
  props: ["user", "dobRequired"],

  data() {
    return {
      minimumDayLimit: 8376,
      maximumDayLimit: 18244,
      email: this.user.email,
      lastName: this.user.last_name,
      firstName: this.user.first_name,
      dob_required: this.dobRequired,
      dob: '',
      age: 0,
      dobCertificate: null,
      gender:  parseInt(this.user.gender?.toString() || '0'),
    }
  },

  validations: {
    dob: {required: requiredIf(function(){
      return (this.dob_required === true);
    })},
    email: {required},
    gender: {required},
    lastName: {required},
    firstName: {required},
    dobCertificate: {
      required: requiredIf(function () {
        return (this.age < this.minimumDayLimit || this.age >= this.maximumDayLimit) && this.age > 0;
      }),
    },
    // stravaProfileLink: {required, url},
    form: ['dob', 'dobCertificate', 'email', 'gender', 'lastName', 'firstName'] //, 'stravaProfileLink'
  },

  computed:{
    // ...mapState("event", ['dob_required'])
  },

  mounted() {
    const datePickerId = document.getElementById('datePickerId');
    datePickerId.max = dayjs().subtract(10, 'year').format('YYYY-MM-DD');
  },

  methods: {
    validate() {
      this.$v.form.$touch();
      const isValid = !this.$v.form.$invalid
      this.$emit('on-validate', this.$data, isValid)
      return isValid
    },

    getError(field) {
      return this.$v[field].$error;
    },

    getDOBYear(){
      this.dobCertificate = null;
      this.age = dayjs().diff(this.dob, 'day');
    },

    getFileName() {
      if (!this.dobCertificate) return;
      return this.dobCertificate.name;
    },

    //can be removed just for reference
    // handleFileUpload(name, $event) {
    //   this[name] = $event.target.files[0];
    // },

    handleFileUpload(name, $event) {

      if ($event.target.files.length > 0) {
        const file = $event.target.files[0];

        // Check if file size is greater than 8MB (8 * 1024 * 1024 bytes)
        const maxSize = 8 * 1024 * 1024;
        if (file.size > maxSize) {
          Swal.fire({
            icon: "error",
            text: "File size exceeds 8MB. Please select a smaller file.",
          })
              .then(() => {
            // Reset the input field to allow for re-selection
            $event.target.value = '';
          });
          return; // Exit the function if the file is too large
        }

        this[name] = file;
      }
    }

  }
}
</script>
