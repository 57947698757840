<template>
  <div>
    <vue-title title="Results - Your Indoor Cycling Solution"></vue-title>
    <div class="iframe">
      <iframe
          :src="iframeUrl"
          width="100%"
          ref="resultiframe"
          scrolling="auto"
          frameborder="0"
          :allowtransparency="true"
      >
      </iframe>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Results',
  data() {
    return {
      appUrl: process.env.VUE_APP_RESULT_URL,
      resultUrl: '/v2/complete-result',
      iframeUrl: process.env.VUE_APP_RESULT_URL,
      iframeHeight: 400,
    }
  },
  async mounted() {
    await this.getBroadCastedEvent();
    await this.loadIframe();
  },

  methods: {

    async loadIframe() {
      const {eventId, dayId} = this.$route.query;

      let url = new URL(this.appUrl);
      if (eventId || dayId) {
        url = new URL(`${this.appUrl}${this.resultUrl}`);
      }

      const params = url.searchParams;
      if (eventId) {
        params.append("eventId", eventId);
      }
      if (dayId) {
        params.append("dayId", dayId);
      }
      this.iframeUrl = url.toString();
    },

    async getBroadCastedEvent() {

      // Create IE + others compatible event handler
      const eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
      const eventer = window[eventMethod];
      const messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

      // Listen to message from child window
      eventer(messageEvent, async (e) => {
        const {eventId, dayId} = this.$route.query;
        const {broadcastType, selectedDayId, eventType, documentHeight} = e.data;

        if (broadcastType === 'MyWhooshEvent') {
          this.iframeHeight = documentHeight;
          if (eventType === 'series' && (eventId !== e.data.eventId || dayId !== selectedDayId)) {
            await this.$router.push({
              name: 'results',
              query: {eventId: e.data.eventId, dayId: selectedDayId}
            });
          } else if (eventType === 'single' && eventId !== e.data.eventId) {
            await this.$router.push({
              name: 'results', query: {eventId: e.data.eventId}
            });
          } else if (eventType === 'events') {
            await this.$router.push({name: 'results'});
          }
        }
      }, false);
    }
  }
};
</script>

<style scoped>
.iframe iframe {
  height: 1200px;
  margin-top: 56px;
}
</style>
