<template>
  <div class="event-reg-form">
<!--    <h4>DEVICE DETAILS</h4>-->
    <div class="row align-items-center">
      <section class="col-md-7 pr-lg-6">

          <div class="select-field form-group font">
            <label for="platform">
              Platform on which you are using My Whoosh
            </label>

            <v-select 
                class="style-chooser" 
                label="label"
                :options="[{label: 'iOS', value: 'iOS'}, {label: 'Android', value: 'Android'}]" 
                v-model="platform"
                :reduce="e => e.value"
                :clearable="false"
              >
            </v-select>

            <div v-if="getError('platform')" class="error-message">
              Platform is required
            </div>

            <small class="text-help-info pointer" @click="openPopoverOs">
              <i class="fas fa-info-circle"></i> Click here for more info
            </small>
          </div>

          <div class="input-field form-group font">
            <input
                type="text"
                class="form-control"
                v-model="smartTrainerDeviceModelName"
            />
            <label :class="smartTrainerDeviceModelName ? 'top-label':'' ">Smart Trainer Device – Model Name</label>
            <div v-if="getError('smartTrainerDeviceModelName')" class="error-message">required</div>

            <small class="text-help-info">
              Only direct drive smart trainers are allowed
            </small>
          </div>

          <div class="input-field form-group font">
            <input
                type="text"
                class="form-control"
                v-model="smartTrainerDeviceSerialNumber"
            />
            <label :class="smartTrainerDeviceSerialNumber ? 'top-label':'' ">
              Smart trainer device – Serial number
            </label>
            <div v-if="getError('smartTrainerDeviceSerialNumber')" class="error-message">required</div>
          </div>

<!--        -->
<!--          <div>-->
<!--            <div class="custom-control custom-radio custom-control-inline" v-show="existingFile">-->
<!--              <input v-model="useFile" value="0" type="radio" id="useExistingDevice" class="custom-control-input"/>-->
<!--              <label class="custom-control-label text-12" for="useExistingDevice">Use existing device video</label>-->
<!--            </div>-->
<!--            <div class="custom-control custom-radio custom-control-inline">-->
<!--              <input v-model="useFile" value="1" type="radio" id="uploadNewDevice" class="custom-control-input"/>-->
<!--              <label class="custom-control-label text-12" for="uploadNewDevice">Upload new device video</label>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div v-if="useFile === '0'" class="text-center">-->
<!--            <ul class="pagination pagination-sm mt-3">-->
<!--              <li class="page-item" v-if="smartTrainerDeviceVideo">-->
<!--                <a class="page-link" href="javascript:" @click="downloadPrevFile('device')">Download Device Video</a>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->

<!--          <div v-else class="custom-file mt-3 form-group">-->
<!--            <input-->
<!--                type="file"-->
<!--                accept="video/*"-->
<!--                class="custom-file-input"-->
<!--                id="smartTrainerDeviceVideo"-->
<!--                @change="handleFileUpload('smartTrainerDeviceVideo', $event)"-->
<!--            />-->
<!--            <label for="smartTrainerDeviceVideo" class="custom-file-label">Smart trainer device – video</label>-->

<!--            <div class="fileName">{{ getFileName('smartTrainerDeviceVideo') }}</div>-->
<!--            <div v-if="getError('smartTrainerDeviceVideo')" class="error-message">required</div>-->

<!--            <small class="text-help-info">-->
<!--              Video must show participant face, smart trainer device and serial-->
<!--              number of smart trainer device clearly Please ensure to take a new-->
<!--              smart trainer video for each event. To prove recency, user must-->
<!--              clearly say his/her name, weight and date in the video.-->
<!--            </small>-->
<!--          </div>-->
<!--        -->

      </section>
    </div>
  </div>
</template>


<script>
import sweetAlert from "@/services/sweet-alert";
import {required} from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  name: "EventDeviceForm",
  props: ["event", "user"],

  data() {
    return {
      files: [],
      useFile: this.user.device_video ? '0' : '1',
      existingFile: this.user.device_video,

      platform: this.user.device || 'iOS',
      smartTrainerDeviceModelName: this.user.device_model,
      smartTrainerDeviceSerialNumber: this.user.device_serial,
      // smartTrainerDeviceVideo: this.user.device_video,
    }
  },

  validations: {
    platform: {required},
    smartTrainerDeviceModelName: {required},
    smartTrainerDeviceSerialNumber: {required},
    // smartTrainerDeviceVideo: {required},
    form: ['platform', 'smartTrainerDeviceModelName', 'smartTrainerDeviceSerialNumber'], //, 'smartTrainerDeviceVideo'
  },

  watch: {
    // useFile: function (newVal) {
    //   if (newVal === '0') {
    //     this.smartTrainerDeviceVideo = this.user.device_video;
    //     return;
    //   }
    //
    //   this.smartTrainerDeviceVideo = null;
    //
    // }
  },

  methods: {
    validate() {
      this.$v.form.$touch();
      const isValid = !this.$v.form.$invalid
      this.$emit('on-validate', this.$data, isValid)
      return isValid
    },

    downloadPrevFile(type) {
      this.$store.dispatch('event/downloadFile', {key: this.user.key, file: type}).then(res =>  {
        if(res && res.status) {
          window.open(res.data.url);
          return ;
        }
        alert('There is an error in downloading file. please try again or contact support');
      });
    },

    getError(field) {
      return this.$v[field].$error;
    },

    getFileName(model) {
      const file = this.files.find(file => file.model === model)
      if (!file) return;
      return file.name;
    },

    //just for reference , can be deleted later
    // handleFileUpload(name, $event) {
    //   this.files = this.files.filter(file => file.model !== name)
    //
    //   if ($event.target.files.length > 0) {
    //     this.files.push({
    //       model: name,
    //       name: $event.target.files[0].name
    //     });
    //   }
    //
    //   this[name] = $event.target.files[0];
    // },


    handleFileUpload(name, $event) {
      this.files = this.files.filter(file => file.model !== name);

      if ($event.target.files.length > 0) {
        const file = $event.target.files[0];

        // Check if file size is greater than 8MB (8 * 1024 * 1024 bytes)
        const maxSize = 8 * 1024 * 1024;
        if (file.size > maxSize) {
          Swal.fire({
            icon: "error",
            text: "File size exceeds 8MB. Please select a smaller file.",
          }).then(() => {
            // Reset the input field to allow for re-selection
            $event.target.value = '';
          });
          return; // Exit the function if the file is too large
        }

        this.files.push({
          model: name,
          name: file.name
        });

        this[name] = file;
      }
    },

    openPopoverOs() {
      const text = `<ol class="text-left font-weight-light text-14">
                      <li>iOS - Recommended Devices <br>iOS 11 or later is recommended
                          <ul>
                              <li>iPad Mini 5</li>
                              <li>iPad Air 3</li>
                              <li>iPad Pro 2 or above</li>
                              <li>iPad 7</li>
                              <li>iPhone X or above</li>
                            </ul>
                        </li>
                        <li>Android - Minimum Supported device specifications
                          <ul>
                            <li>Android version: 9.0 and above</li>
                            <li>RAM: 4 GB</li>
                            <li>Storage: 32 GB</li>
                            <li>Processor: Snapdragon 660, Mali G52, Adreno 512</li>
                          </ul>
                        </li>
                    </ol>`;

      sweetAlert.alert(text, '', false);
    },
  },
}
</script>
