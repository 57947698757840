export const HairSet = [
    {
        ID: "0",
        Color: "0",
        Name: "None",
        Description:
            "If you choose 'None', your avatar will have no hair. Let your style reflect your personality.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "1",
        MeshId: "0",
        AtlasId: "0",
        U_transform: "0",
        V_transform: "0",
        U_offset: "0.000000",
        V_offset: "0.000000",
        UI_Icon: "0",
    },
    {
        ID: "1",
        Color: "0",
        Name: "Crew Cut",
        Description:
            "Customize your avatar's style with trendy haircuts - long, short, or quirky. Let your virtual locks reflect your personality!",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "1",
        MeshId: "1",
        AtlasId: "1",
        U_transform: "0",
        V_transform: "0",
        U_offset: "0.250000",
        V_offset: "0.250000",
        UI_Icon: "1",
    },
    {
        ID: "2",
        Color: "0",
        Name: "Ponytail",
        Description:
            "Customize your avatar's style with trendy haircuts - long, short, or quirky. Let your virtual locks reflect your personality!",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "2",
        MeshId: "2",
        AtlasId: "1",
        U_transform: "0",
        V_transform: "0",
        U_offset: "0.250000",
        V_offset: "0.250000",
        UI_Icon: "2",
    },
    {
        ID: "3",
        Color: "0",
        Name: "Braids",
        Description:
            "Customize your avatar's style with trendy haircuts - long, short, or quirky. Let your virtual locks reflect your personality!",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "3",
        MeshId: "3",
        AtlasId: "1",
        U_transform: "0",
        V_transform: "0",
        U_offset: "0.250000",
        V_offset: "0.250000",
        UI_Icon: "3",
    },
    {
        ID: "4",
        Color: "0",
        Name: "Short Afro",
        Description:
            "Customize your avatar's style with trendy haircuts - long, short, or quirky. Let your virtual locks reflect your personality!",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "4",
        MeshId: "4",
        AtlasId: "1",
        U_transform: "0",
        V_transform: "0",
        U_offset: "0.250000",
        V_offset: "0.250000",
        UI_Icon: "4",
    },
    {
        ID: "5",
        Color: "0",
        Name: "Side Fringe",
        Description:
            "Customize your avatar's style with trendy haircuts - long, short, or quirky. Let your virtual locks reflect your personality!",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "5",
        MeshId: "5",
        AtlasId: "1",
        U_transform: "0",
        V_transform: "0",
        U_offset: "0.250000",
        V_offset: "0.250000",
        UI_Icon: "5",
    },
    {
        ID: "6",
        Color: "0",
        Name: "Pogacar Hair",
        Description: "Hair made specially for Pogacar",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_CustomAvatar",
        UnlockingValue: "5",
        MeshId: "6",
        AtlasId: "1",
        U_transform: "0",
        V_transform: "0",
        U_offset: "0.250000",
        V_offset: "0.250000",
        UI_Icon: "6",
    },
];
