<template>
  <div>
    <h1>This website cannot be embedded in an iframe.</h1>
  </div>
</template>

<script>
export default {
name: "IframeWarning"
}
</script>

<style scoped>

</style>