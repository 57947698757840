<template>
  <div v-if="page">
    <Banner :page="page" v-if="page"/>

    <div class="container">
      <div class="head-style">
        <h1>Welcome to MyWhoosh Support <br/> How can we help you?</h1>
      </div>
    </div>

    <Articles v-if="getArticles" :articles="getArticles" />

    <section class="container-fluid contact-us">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="content text-center">
              <h2>{{ page.contact_section_heading }}</h2>
              <router-link :to="{name: 'contacts'}">CONTACT US</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="device">

      <div class="container">
        <div class="cycle-icon-trans">
            <img class="img-fluid" :src="`${assetsUrl()}/images/cycle-icon.png`" alt="">
        </div>
        <div class="row">
          <div class="col-md-12 animatedParent">
            <h1 class="animated fadeInLeft go">
              {{ page.main_devices_heading }}
            </h1>
            <!-- <div class="device-img text-center animated growIn slow">
                <img src="assets/images/Group%2023.png" class="img-fluid">
            </div> -->
            <div class="screens-warp animatedParent">
              <div class="apple-screen animated growIn go">
                <p>{{ page.apple_device_heading }}</p>
                <!--                <img class="img-fluid" v-lazy="page.apple_device_image" alt="">-->
                <ul>
                  <li>iPad Mini 5</li>
                  <li>iPad Air 3</li>
                  <li>iPad Pro 2 or above</li>
                  <li>iPad 7</li>
                  <li>iPhone X or above</li>
                </ul>
              </div>
              <div class="line-mid"></div>
              <div class="android-screen animated growIn go" v-if="displayFooter">
                <p>{{ page.android_device_heading }}</p>
                <!--                <img class="img-fluid" v-lazy="page.android_device_image" alt="">-->
                <ul>
                  <li>Android Version: 9.0 and above</li>
                  <li>RAM: 4 GB</li>
                  <li>Storage: 32 GB</li>
                  <li>Processor: Snapdragon 660, Mali G52, Adreno 512</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="any-time">
      <div class="container contain">
        <div class="row">
          <div class="col-md-12 animatedParent">
            <h1 class="animated fadeInLeft go">{{ page.playstore_section_heading }}</h1>
            <p class="animated fadeInLeft go" v-html="page.playstore_section_description"></p>
            <ul class="list-inline store-btn animated fadeInLeft go">
              <li class="list-inline-item">
                <a target="_blank" :href="setting.applestore">
                  <img class="img-normal" :src="`${assetsUrl()}/images/appple.png`" alt="">
                  <img class="img-hover" :src="`${assetsUrl()}/images/apple-hover.png`" alt="">
                </a>
              </li>
              <li class="list-inline-item" v-if="displayFooter">
                <a target="_blank" :href="setting.playstore">
                  <img class="img-normal" :src="`${assetsUrl()}/images/android.png`" alt="">
                  <img class="img-hover" :src="`${assetsUrl()}/images/andriod-hover.png`" alt="">
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {commonMixin} from '@/mixins/commonMixin';
import Banner from '@/components/shared/Banner'
import Articles from '@/components/Articles'

export default {
  name: 'Support',
  mixins: [commonMixin],

  components: {
    Banner,
    Articles,
  },

  data() {
    return {
      refresh: 0,
      displayFooter: true,
    }
  },

  computed: {
    ...mapGetters('page', ['page', 'setting']),

    getArticles() {
      if(!this.page || !this.page.articles) return ;
      return this.page.articles;
    },
  },

  mounted() {
    document.body.id = 'Whoosh';
    document.body.classList.add('contact');

    if(localStorage.getItem('d') && localStorage.getItem('d') === 'm') {
      this.displayFooter = false;
    }

    this.$store.dispatch('page/view', 'support');
  },
  destroyed() {
    document.body.classList.remove('contact')
  }
}
</script>

<style scoped>
.any-time * p {
  max-width: 100% !important;
}
.contact-us a{color: #121113 !important;}
.contact-us a:hover{color: white !important;}
</style>
