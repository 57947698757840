<template>
    <div class="status">
        <span class="loader"> </span>
    </div>
</template>

<script>
export default {
    name: "LoadingIcon",
    props: [],
    components: {},
    data() {
        return {};
    },
};
</script>

<style>
.loader {
    -webkit-animation: rotation 0.5s linear infinite;
    animation: rotation 0.5s linear infinite;
    border-radius: 50%;
    border: 5px solid #e2e6eb;
    border-bottom-color: #28bfff;
    display: inline-block;
    height: 48px;
    width: 48px;
}

/* keyFrames */
@-webkit-keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
