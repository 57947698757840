<template>
  <div v-if="page">
    <vue-title title="Tracks - Whoosh! Your Indoor Cycling Solution"></vue-title>

    <Banner :page="page" />

    <div class="track-main">
      <section class="container">
        <div class="row" v-for="(track, i) in page.tracks" :key="i">
          <section class="col-md-12 track-1">
            <h3>{{ track.trackTitle }}</h3>
            <p v-html="track.trackDescription"></p>
            <img class="img-fluid" v-lazy="image(track.trackImage)" alt="">
            <div class="space-20"></div>
          </section>
        </div>
      </section>
    </div>

  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Banner from '@/components/shared/Banner';

export default {
  name: 'Tracks',
  components: {
    Banner,
  },

  computed: {
    ...mapGetters('page', ['page']),
  },

  mounted() {
    document.body.classList.add('contact');
    this.$store.dispatch('page/view', 'routes');
  },

  methods: {
    image(url) {
      return `${url}/1140/625`;
    }
  }
}
</script>
