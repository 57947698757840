<template>
  <div>
    <i>please wait...</i>
  </div>
</template>

<script>
export default {
  name: 'FitnessNetworkCallback',

  mounted() {

    // const $query = this.$route.query;
    // console.log('$query.code', $query.code)
    // console.log('$query.scope', $query.scope)
    // console.log('$query.source', $query.source)
    // console.log('$query.state', $query.state)
    // console.log('this.$route.params', this.$route.params)

    const payload = {...this.$route.query, ...this.$route.params};

    this.$store.dispatch('oauth/authorization', payload).then(res => {
      if(res && res.status) {
        this.$router.push('/user/profile')
        return ;
      }

      alert('An error has occurred. Please try again');
      this.$router.push('/user/profile')
    });


  }

}
</script>