<template>
  <div class="event-reg-form">
<!--    <h4>IDENTITY VERIFICATION</h4>-->
    <div class="row align-items-center">
      <section class="col-md-8 pr-lg-6">

          <div>
            <div class="custom-control custom-radio custom-control-inline" v-show="existingFile">
              <input v-model="useFile" value="0" type="radio" id="useExisting" class="custom-control-input"/>
              <label class="custom-control-label" for="useExisting">Use existing file(s)</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input v-model="useFile" value="1" type="radio" id="uploadNew" class="custom-control-input"/>
              <label class="custom-control-label" for="uploadNew">Upload a new file(s)</label>
            </div>
          </div>

          <div v-if="useFile === '0'" class="text-center">
            <ul class="pagination pagination-sm mt-3">
              <li class="page-item" v-if="nationalIdFront">
                <a class="page-link" href="javascript:" @click="downloadPrevFile('front')">Document front side</a>
              </li>
              <li class="page-item" v-if="nationalIdBack && idType !== 'passport'">
                <a class="page-link" href="javascript:" @click="downloadPrevFile('back')">Document back side</a>
              </li>
            </ul>
            <div class="text-left text-primary">Document Expiry Date: {{ expiryDate | dateFormat }}</div>

          </div>

          <div class="mt-4" v-else>
            <div class="select-field form-group">
              <label for="idType">ID Type</label>
              
              <v-select 
                class="style-chooser" 
                label="label"
                :options="[{label: 'National ID', value: 'nationalId'}, {label: 'Passport', value: 'passport'}]" 
                v-model="idType"
                :reduce="e => e.value"
                :clearable="false"
                id="idType"
              >
              </v-select>

              <div v-if="getError('idType')" class="error-message">ID Type is required</div>
            </div>

            <div class="custom-file form-group mt-0 mb-5">
              <input
                  id="national-id-front"
                  class="custom-file-input"
                  type="file"
                  @change="handleFileUpload('nationalIdFront', $event)"
                  accept="image/*"
              />
              <label class="custom-file-label" for="national-id-front">Passport / National ID (front)</label>

              <div class="fileName">{{ getFileName('nationalIdFront') }}</div>
              <div v-if="getError('nationalIdFront')" class="error-message">required</div>
              <small class="text-help-info">
                Please upload a clear copy of your National Id/passport with a valid expiry date
              </small>
            </div>

            <div class="custom-file form-group mt-0" v-show="idType !== 'passport'">
              <input
                  id="national-id-back"
                  class="custom-file-input"
                  type="file"
                  @change="handleFileUpload('nationalIdBack', $event)"
                  accept="image/*"
              />
              <label class="custom-file-label" for="national-id-back">National ID (back)</label>
              <div class="fileName">{{ getFileName('nationalIdBack') }}</div>

              <div v-if="getError('nationalIdBack')" class="error-message">required</div>
              <small class="text-help-info">Please upload a clear copy of the back of your National ID</small>
            </div>

            <div class="input-field form-group mt-3">
              <span>National ID / Passport Expiry Date</span>
              <input
                  type="date"
                  v-model="expiryDate"
                  class="form-control"
                  id="dateExpiryId"
              />

              <div v-if="getError('expiryDate')" class="error-message">
                National ID / Passport Expiry Date is required
              </div>
            </div>
          </div>

      </section>
    </div>
  </div>
</template>

<script>
import * as dayjs from "dayjs";
import {required, requiredIf} from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  name: "IdentificationForm",
  props: ["event", "user"],

  data() {
    return {
      files: [],
      useFile: this.user.front ? '0' : '1',
      existingFile: this.user.front,

      idType: this.user.id_type || 'nationalId',
      nationalIdFront: this.user.front || null,
      nationalIdBack: this.user.back || null,
      expiryDate: this.user.expiry ?
          dayjs(this.user.expiry).format('YYYY-MM-DD') :
          dayjs().add(10, 'day').format('YYYY-MM-DD')

    }
  },

  validations: {
    idType: {required},
    expiryDate: {required},
    nationalIdFront: {required},
    nationalIdBack: {
      required: requiredIf(function () {
        return this.idType !== 'passport';
      }),
    },
    form: ['idType', 'nationalIdFront', 'nationalIdBack']
  },

  mounted() {
    const dateExpiryId = document.getElementById('dateExpiryId');
    if(dateExpiryId) {
      dateExpiryId.min = dayjs().add(10, 'day').format('YYYY-MM-DD');
    }
  },

  watch: {
    useFile: function (newVal) {
      if (newVal === '0') {
        this.idType = this.user.id_type || 'nationalId';
        this.nationalIdFront = this.user.front || null;
        this.nationalIdBack = this.user.back || null;
        this.expiryDate = this.user.expiry ?
            dayjs(this.user.expiry).format('YYYY-MM-DD') :
            dayjs().add(10, 'day').format('YYYY-MM-DD')
        return;
      }

      this.idType = 'nationalId';
      this.nationalIdFront = null;
      this.nationalIdBack = null;
      this.expiryDate = dayjs().add(10, 'day').format('YYYY-MM-DD')

    }
  },

  filters: {
    dateFormat: function (value) {
      return dayjs(value).format('DD MMMM YYYY')
    },
  },

  methods: {
    validate() {
      this.$v.form.$touch();
      const isValid = !this.$v.form.$invalid
      this.$emit('on-validate', this.$data, isValid)
      return isValid
    },

    getError(field) {
      if(this.$v[field]) {
        return this.$v[field].$error;
      }
    },

    downloadPrevFile(type) {
      this.$store.dispatch('event/downloadFile', {key: this.user.key, file: type}).then(res =>  {
        if(res && res.status) {
          window.open(res.data.url);
          return ;
        }
        alert('There is an error in downloading file. please try again or contact support');
      });
    },

    getFileName(model) {
      const file = this.files.find(file => file.model === model)
      if (!file) return;
      return file.name;
    },
    handleFileUpload(name, $event) {
      this.files = this.files.filter(file => file.model !== name);

      if ($event.target.files.length > 0) {
        const file = $event.target.files[0];

        // Check if file size is greater than 8MB (8 * 1024 * 1024 bytes)
        const maxSize = 8 * 1024 * 1024;
        if (file.size > maxSize) {
          Swal.fire({
            icon: "error",
            text: "File size exceeds 8MB. Please select a smaller file.",
          }).then(() => {
            // Reset the input field to allow for re-selection
            $event.target.value = '';
          });
          return; // Exit the function if the file is too large
        }

        this.files.push({
          model: name,
          name: file.name
        });

        this[name] = file;
      }
    }


  }
}
</script>