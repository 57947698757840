import Service from "@/services";

export default {

    namespaced: true,

    state: {
        contact_us_status: null,
        contact_us_form_status: null
    },

    getters: {
        page: (state) => state.page,
        faqs: (state) => state.faqs,
        formStatus: (state) => state.contact_us_form_status,
    },

    actions: {
        async view({commit}, slug) {
            try {
                return await Service().post(`pages/${slug}`).then(res => {
                    commit('SET_CONTACT_STATUS', res.data);
                    return res;
                });
            } catch (e) {
                console.log('Error: ', e);
            }
        },

        async sendEmail({commit}, payload) {
            try {
                return await Service().post(`contact`, payload).then(res => {
                    if (res.status) {
                        commit('SET_CONTACT_FORM_STATUS', res.message);
                    }
                    return res;
                });
            } catch (e) {
                global.app.$store.commit("SET_ERRORS", e.errors, {root: true});
                console.log('Contact Form Error: ', e.message);
            }
        },

        async resetForm({commit}) {
            commit('RESET_FORM');
        },
    },

    mutations: {
        SET_CONTACT_STATUS: (state, payload) => state.contact_us_status = payload,
        SET_CONTACT_FORM_STATUS: (state, payload) => state.contact_us_form_status = payload,
        RESET_FORM: (state) => state.contact_us_form_status = null
    }
}
