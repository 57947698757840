<template>
  <div>
    <vue-title :title="`${getEventData.name} - Event`"></vue-title>

    <div class="homepage-wrap clearfix">
      <div class="section-wrap bg-img event-more">
        <div class="max-width-wrap">
          <div class="event-more-wrapper">
            <div class="txt-wrapper">
              <h2 class="heading-lrg">
                <span>{{ getEventData.name }}</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-img register-now mb-10">
        <div class="container">
          <div class="register-box">
            <div class="div-flex">
              <div class="col-right">
                <div class="div-flex">
                  <div class="col-right" v-if="getEventData.date">
                    <div
                      class="regi-block txt-center"
                      :class="getEventData.prizeMoney ? 'border-right' : ''"
                    >
                      <h5>
                        {{ getEventData.date }}
                      </h5>
                      <span>Event Date</span>
                    </div>
                  </div>
                  <div class="col-right" v-if="getEventData.prizeMoney">
                    <div class="regi-block txt-center">
                      <h5>{{ getEventData.prizeMoney }}</h5>
                      <span>Prize Money</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-right">
                <a
                  v-if="checkJoinStatus() && getEventData.accept"
                  class="btn-regi"
                  style="color: #0a0a0a"
                  href="javascript:void(0)"
                  @click="joinEvent(getEventData.id)"
                  >REGISTER NOW!</a
                >
              </div>
            </div>
          </div>
          <p class="p-text p-text-small" v-if="loggedIn">
            <template v-if="getEventData.accept">
              <template v-if="getEventData.ustatus === 'pending'">
                <p class="alert alert-warning alert-12">
                  Your registration for this event is in process, soon you will
                  receive a confirmation email.
                </p>
              </template>
              <template v-else-if="getEventData.ustatus === 'approved'">
                <p class="alert alert-success alert-12">
                  Congratulations! Your registration for this event has been
                  approved.
                </p>
              </template>
              <template v-else-if="getEventData.ustatus === 'declined'">
                <p class="alert alert-danger alert-12">
                  Your request for this event is declined. please contact
                  support for further details
                </p>
              </template>
              <template v-else>
                <span v-if="join && checkEmptySlots()"
                  >Registration closing at: {{ getEventData.date_close }} at
                  {{ getEventData.time_close }} GMT +4</span
                >
                <span v-if="!checkEmptySlots()">
                  The room is already fill for this event.
                </span>
              </template>
            </template>
            <template v-else>
              <span>
                Registration for this event has been closed.
              </span>
            </template>
          </p>
          <p class="p-text p-text-small" v-else>
            Login or create an account to register for this event.
          </p>
        </div>
      </div>
      <div class="card-box-info">
        <div class="container">
          <ul>
            <li>
              <img src="/assets/images/icon-1.png" /><span>Distance</span>
              <div class="count-text">{{ getEventData.distance }}</div>
            </li>
            <li>
              <img src="/assets/images/icon-2.png" />
              <span>Race Starting Time</span>
              <div class="count-text">
                {{ getEventData.time }} <span class="gmt-css">GMT +4</span>
              </div>
            </li>
            <li>
              <img src="/assets/images/icon-3.png" /><span
                >Total Elevation</span
              >
              <div class="count-text">{{ getEventData.elevation }}</div>
            </li>
            <li v-if="getEventData.maxGrade">
              <img src="/assets/images/icon-4.png" /><span>Max Grade</span>
              <div class="count-text">{{ getEventData.maxGrade }}</div>
            </li>
            <li>
              <img src="/assets/images/icon-5.png" /><span>Participant</span>
              <div class="count-text">
                {{ getEventData.participants.booked }} /
                {{ getEventData.participants.capacity }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="text-info">
        <div class="container" v-html="getEventData.details"></div>
      </div>

      <EventCategoryTimes
        :data="getEventData"
        @doToggle="doToggle"
        :details="true"
        :new_version="true"
      />

      <EventLinks :links="getLinks" :new_version="true" />

      <br />
      <div class="elevation" v-if="getEventData.map">
        <div class="container">
          <!-- Added image resize -->
          <img :src="makeImage(getEventData.map, 1800, 1024, true)" />
        </div>
      </div>
    </div>
    <PriceWidget
      v-if="getWinnersData.length > 0"
      :winners="getWinnersData"
      :eventData="getEventData"
      :showLink="false"
    />

    <div class="progress-bar-wrap">
      <div id="progress-bar" style="--scroll: 0%"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as dayjs from "dayjs";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import EventLinks from "@/components/event/EventLinks";
import EventCategoryTimes from "@/components/event/EventCategoryTimes";
import { commonMixin } from "@/mixins/commonMixin";
import PriceWidget from "@/components/event/PriceWidget";

export default {
  name: "EventDetails",
  mixins: [commonMixin],
  components: {
    EventCategoryTimes,
    EventLinks,
    PriceWidget
  },

  data() {
    return {
      formData: new FormData(),
      eventId: this.$route.params.id,
      submitted: false,
      formIsValid: true,
      instruction: false,
      charLimit: 20,
      join: true,
    };
  },

  filters: {
    dateFormat: function(value) {
      return dayjs(value).format("dddd, DD MMMM YYYY");
    },

    timeFormat: function(value) {
      return dayjs(value).format("hh:mm A");
    },
  },

  computed: {
    ...mapGetters(["errors"]),
    ...mapGetters("page", ["page"]),
    ...mapGetters("auth", ["loggedIn", "user"]),
    ...mapGetters("event", ["event", "otpSentStatus", "otpVerifyStatus"]),

    getEventData() {
      if (!this.event || !this.event.event) return false;
      return Object.assign({}, this.event.event);
    },

    getLinks() {
      return this.getEventData.links;
    },

    getWinnersData() {
      if (!this.getEventData) return [];
      return Object.assign([], this.getEventData.winners);
    },

    getUserData() {
      if (!this.getEventData) return false;
      return Object.assign({}, this.event.user);
    },

    getErrors() {
      if (!this.errors) return;
      return Object.values(this.errors).join("<br />");
    },
  },

  mounted() {
    document.body.classList.add("contact");
    this.$store.dispatch("auth/loggedIn");
    this.$store.dispatch("page/view", "events");
    this.$store.dispatch("event/event", this.eventId).then((res) => {
      if (res.data?.is_registered) {
        this.$router.push({ name: "events" });
      }
    });
  },

  methods: {
    validateStep(name) {
      return this.$refs[name].validate();
    },

    mergePartialModels(model, isValid) {
      if (isValid) {
        for (const [key, value] of Object.entries(model)) {
          this.formData.set(key, value);
        }
      }
    },

    checkEmptySlots() {
      return (
        this.getEventData.participants.booked <
        this.getEventData.participants.capacity
      );
    },

    checkJoinStatus() {
      if (
        this.getEventData.ustatus === "pending" ||
        this.getEventData.ustatus === "approved" ||
        this.getEventData.participants?.booked >
          this.getEventData.participants?.capacity
      ) {
        this.join = false;
        return false;
      }

      return true;
    },

    joinEvent(eventId) {
      this.$router.push({ name: "event", params: { id: eventId } });
    },

    submitApplication() {
      this.submitted = true;
      if (this.getEventData.otp) {
        if (!this.otpVerifyStatus) {
          this.formIsValid = false;
          return;
        }
      }

      this.formData.append("eventId", this.eventId);
      this.$store
        .dispatch("event/submitApplication", this.formData)
        .then((res) => {

          if (res && res.status) {
            this.$router.push({ name: "events" });
          }
        });
    },
  },

  destroyed() {
    document.body.classList.remove("event-registration");
  },
};
</script>

<style scoped>
section h6 {
  color: #ccc;
  background: white;
  padding: 7px 10px 5px;
  border: 1px solid #f7f7f7;
  border-radius: 5px;
}
.regi-block h5 {
  font-size: 22px;
}
.gmt-css {
  font-size: 12px;
}
.p-text-small {
  font-size: 14px;
}
.elevation img{
  max-width: 100%;
}
</style>
