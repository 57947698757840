export const commonMixin = {
  methods: {
    assetsUrl() {
      return process.env.VUE_APP_ASSETS;
    },

    preVerificationUrl(eventId) {
      return process.env.VUE_APP_WEB_URL + `/weightvideo/?event-id=${eventId}`;
    },

    wpWebUrl() {
      return process.env.VUE_APP_WEB_URL;
    },

    makeImage(url, w = 800, h = 600, fit = false) {
      if (fit) {
        return `${url}/${w}/${h}/fit`;
      }

      return `${url}/${w}/${h}`;
    },

    deleteCookie(name) {
      const domain = window.location.hostname;
      const domainParts = domain.split('.');
      const mainDomain = domainParts.slice(-2).join('.');
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${mainDomain};`;
    },
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    },
     parseJwt() {
      try {
        const token = localStorage.getItem('webToken')
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
      } catch (error) {
        return null;
      }
    },
      isTokenExpired() {
            const token = localStorage.getItem('webToken')
            if(token){
              const decodedToken = this.parseJwt(token);

              if (!decodedToken || !decodedToken.exp) {
                return true; // If the token is invalid or doesn't have an exp field, treat it as expired
              }
              const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

              return decodedToken.exp < currentTime; // true if token is expired
            }
            return false

      }
  },
};