export const HelmetSet = [
    {
        "ID": 2,
        "Name": "MyWhoosh Road Helmet",
        "Description": "MyWhoosh Road Helmet delivers ample ventilation with aerodynamic efficiency. To keep you cool and cut through the air more smoothly, improving speed and performance.",
        "Weight": 110,
        "Color": "green",
        "model": 2018,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 1,
        "MeshId": 1,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 1,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 1,
        thumbnail: "MyWhoosh Road Helmet- Black.png"
    },
    {
        "ID": 3,
        "Name": "MyWhoosh Road Helmet",
        "Description": "MyWhoosh Road Helmet delivers ample ventilation with aerodynamic efficiency. To keep you cool and cut through the air more smoothly, improving speed and performance.",
        "Weight": 0,
        "Color": "Black",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 2,
        "MeshId": 1,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 2,
        thumbnail: "MyWhoosh Road Helmet - White.png"
    },
    {
        "ID": 4,
        "Name": "MyWhoosh Road Aero Helmet",
        "Description": "MyWhoosh Road Aero Helmet has a lightweight construction and aerodynamic profile that helps reduce air resistance, enhancing overall speed.",
        "Weight": 0,
        "Color": "",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 2,
        "MeshId": 2,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 1,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 3,
        thumbnail: "MyWhoosh Road Aero Helmet - Black.png"
    },
    {
        "ID": 5,
        "Name": "MyWhoosh Road Aero Helmet",
        "Description": "MyWhoosh Road Aero Helmet has a lightweight construction and aerodynamic profile that helps reduce air resistance, enhancing overall speed.",
        "Weight": 0,
        "Color": "",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 3,
        "MeshId": 2,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 4,
        thumbnail: "MyWhoosh Road Aero Helmet - White.png"
    },
    {
        "ID": 6,
        "Name": "MyWhoosh Road Helmet",
        "Description": "MyWhoosh Road Helmet delivers ample ventilation with aerodynamic efficiency. To keep you cool and cut through the air more smoothly, improving speed and performance.",
        "Weight": 0,
        "Color": "",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 3,
        "MeshId": 1,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 4,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 5,
        thumbnail: "MyWhoosh Road Helmet - Yellow.png"
    },
    {
        "ID": 14,
        "Name": "MyWhoosh Standard Cycling Cap",
        "Description": "MyWhoosh Standard Cycling Cap features a short brim, sweat-absorbing band, and a compact design, adding a touch of style to your apparel.",
        "Weight": 0,
        "Color": "",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 3,
        "MeshId": 3,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 6,
        thumbnail: "MyWhoosh Standard Cycling Cap - Yellow.png"
    },
    {
        "ID": 15,
        "Name": "MyWhoosh Standard Cycling Cap",
        "Description": "MyWhoosh Standard Cycling Cap features a short brim, sweat-absorbing band, and a compact design, adding a touch of style to your apparel.",
        "Weight": 0,
        "Color": "",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 4,
        "MeshId": 3,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 1,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 7,
        thumbnail: "MyWhoosh Standard Cycling Cap - White.png"
    },
    {
        "ID": 16,
        "Name": "MyWhoosh Standard Cycling Cap",
        "Description": "MyWhoosh Standard Cycling Cap features a short brim, sweat-absorbing band, and a compact design, adding a touch of style to your apparel.",
        "Weight": 0,
        "Color": "",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 4,
        "MeshId": 3,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 2,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 8,
        thumbnail: "MyWhoosh Standard Cycling Cap - Blue.png"
    },
    {
        "ID": 17,
        "Name": "MyWhoosh Fluro Cycling Cap",
        "Description": "MyWhoosh Fluro Cycling Cap features a short brim, sweat-absorbing band, and a compact design, adding a touch of style to your apparel.",
        "Weight": 0,
        "Color": "",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 4,
        "MeshId": 3,
        "AtlasId": 2,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 9,
        thumbnail: "MyWhoosh Fluro Cycling Cap.png"
    },
    {
        "ID": 18,
        "Name": "MyWhoosh Polka Dot Cycling Cap",
        "Description": "MyWhoosh Polka Dot Cycling Cap features a short brim, sweat-absorbing band, and a compact design, adding a touch of style to your apparel.",
        "Weight": 0,
        "Color": "",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 4,
        "MeshId": 3,
        "AtlasId": 2,
        "U_transform": 0,
        "V_transform": 1,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 10,
        thumbnail: "MyWhoosh Polka Dot Cycling Cap.png"
    },
    {
        "ID": 19,
        "Name": "MyWhoosh Stripes Cycling Cap",
        "Description": "MyWhoosh Stripes Cycling Cap features a short brim, sweat-absorbing band, and a compact design, adding a touch of style to your apparel.",
        "Weight": 0,
        "Color": "",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 4,
        "MeshId": 3,
        "AtlasId": 2,
        "U_transform": 0,
        "V_transform": 2,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 11,
        thumbnail: "MyWhoosh Stripes Cycling Cap.png"
    },
    {
        "ID": 20,
        "Name": "MyWhoosh Standard Bandana",
        "Description": "MyWhoosh Standard Bandana, a practical choice for managing sweat and enhancing overall comfort while also adding a touch of personal flair.",
        "Weight": 0,
        "Color": "",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 5,
        "MeshId": 4,
        "AtlasId": 2,
        "U_transform": 0,
        "V_transform": 1,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 12,
        thumbnail: "MyWhoosh Standard Bandana - Black.png"
    },
    {
        "ID": 21,
        "Name": "MyWhoosh Standard Bandana",
        "Description": "MyWhoosh Standard Bandana, a practical choice for managing sweat and enhancing overall comfort while also adding a touch of personal flair.",
        "Weight": 0,
        "Color": "",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 5,
        "MeshId": 4,
        "AtlasId": 2,
        "U_transform": 0,
        "V_transform": 4,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 13,
        thumbnail: "DMyWhoosh Standard Bandana - Blue.png"
    },
    {
        "ID": 22,
        "Name": "MyWhoosh Standard Bandana",
        "Description": "MyWhoosh Standard Bandana, a practical choice for managing sweat and enhancing overall comfort while also adding a touch of personal flair.",
        "Weight": 0,
        "Color": "",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 5,
        "MeshId": 4,
        "AtlasId": 2,
        "U_transform": 0,
        "V_transform": 2,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 14,
        thumbnail: "MyWhoosh Standard Bandana - Red.png"
    },
    {
        "ID": 23,
        "Name": "MyWhoosh Standard Bandana",
        "Description": "MyWhoosh Standard Bandana, a practical choice for managing sweat and enhancing overall comfort while also adding a touch of personal flair.",
        "Weight": 0,
        "Color": "",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 5,
        "MeshId": 4,
        "AtlasId": 2,
        "U_transform": 0,
        "V_transform": 3,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 15,
        thumbnail: "MyWhoosh Standard Bandana - White.png"
    },
    {
        "ID": 24,
        "Name": "MyWhoosh Standard Bandana",
        "Description": "MyWhoosh Standard Bandana, a practical choice for managing sweat and enhancing overall comfort while also adding a touch of personal flair.",
        "Weight": 0,
        "Color": "",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 5,
        "MeshId": 4,
        "AtlasId": 2,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 16,
        thumbnail: "MyWhoosh Standard Bandana - Yellow.png"
    },
    {
        "ID": 25,
        "Name": "Ekoi S22 Cycling Cap",
        "Description": "Ekoi S22 Cycling Cap is a classic design which provides comfort, sun protection and style to any cyclists kit.",
        "Weight": 0,
        "Color": "",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 6,
        "MeshId": 5,
        "AtlasId": 3,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 17,
        thumbnail: "Ekoi S22 Cycling Cap - Red.png"
    },
    {
        "ID": 11,
        "Name": "Ekoi Aero 15 Helmet",
        "Description": "Ekoi Aero 15 Helmet combines protection, ergonomics, aerodynamics, versatility and design in a top level helmet.",
        "Weight": 0,
        "Color": "",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 6,
        "MeshId": 6,
        "AtlasId": 3,
        "U_transform": 0,
        "V_transform": -1,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 18,
        thumbnail: "Ekoi Aero 15 Helmet - Black.png"
    },
    {
        "ID": 13,
        "Name": "Ekoi AERO15 Helmet",
        "Description": "Ekoi AERO15 Helmet combines protection, ergonomics, aerodynamics, versatility and design in a top level helmet.",
        "Weight": 0,
        "Color": "",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 6,
        "MeshId": 6,
        "AtlasId": 3,
        "U_transform": 0,
        "V_transform": -2,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 19,
        thumbnail: "Ekoi AERO15 Helmet - White.png"
    },
    {
        "ID": 12,
        "Name": "Ekoi AERO15 Helmet",
        "Description": "Ekoi AERO15 Helmet combines protection, ergonomics, aerodynamics, versatility and design in a top level helmet.",
        "Weight": 0,
        "Color": "",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 6,
        "MeshId": 6,
        "AtlasId": 3,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 20,
        thumbnail: "Ekoi AERO15 Helmet - Red.png"
    },
    {
        "ID": 7,
        "Name": "MyWhoosh Aero Visor",
        "Description": "MyWhoosh Aero Visor Helemt fetaures a streamlined shape and compact design delivering a versatile helmet suitable for both time trials and other aero applications.",
        "Weight": 0,
        "Color": "",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 6,
        "MeshId": 7,
        "AtlasId": 3,
        "U_transform": 0,
        "V_transform": 1,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 21,
        thumbnail: "MyWhoosh Aero Visor - Black.png"
    },
    {
        "ID": 8,
        "Name": "MyWhoosh Aero Visor",
        "Description": "MyWhoosh Aero Visor Helemt fetaures a streamlined shape and compact design delivering a versatile helmet suitable for both time trials and other aero applications.",
        "Weight": 0,
        "Color": "",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 6,
        "MeshId": 7,
        "AtlasId": 3,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 22,
        thumbnail: "MyWhoosh Aero Visor - White"
    },
    {
        "ID": 9,
        "Name": "MyWhoosh Time Trial Helmet",
        "Description": "MyWhoosh Time Trial Helmet prioritizes a streamlined and elongated shape to minimize air resistance and enhance speed optimizing aerodynamic performance.",
        "Weight": 0,
        "Color": "",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 6,
        "MeshId": 8,
        "AtlasId": 3,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 23,
        thumbnail: "MyWhoosh Time Trial Helmet - White.png"
    },
    {
        "ID": 10,
        "Name": "MyWhoosh Time Trial Helmet",
        "Description": "MyWhoosh Time Trial Helmet prioritizes a streamlined and elongated shape to minimize air resistance and enhance speed optimizing aerodynamic performance.",
        "Weight": 0,
        "Color": "",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 6,
        "MeshId": 8,
        "AtlasId": 3,
        "U_transform": 0,
        "V_transform": 1,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 24,
        thumbnail: "MyWhoosh Time Trial Helmet - Yellow.png"
    },
    {
        "ID": 26,
        "Name": "Ekoi AERO16 Helmet",
        "Description": "The Ekoi AERO16 will improve aerodynamics while delivering a lightweight design and the comfort needed for hilly terrain.",
        "Weight": 0,
        "Color": "",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 6,
        "MeshId": 9,
        "AtlasId": 4,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 25,
        thumbnail: "Ekoi AERO16 Helmet - Black Red.png"
    },
    {
        "ID": 27,
        "Name": "Ekoi Veloce Helmet",
        "Description": "Ekoi Veloce Helemt, tested and developed in a wind tunnel with one key goal, aerodynamic efficiency.",
        "Weight": 0,
        "Color": "",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 6,
        "MeshId": 10,
        "AtlasId": 4,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 26,
        thumbnail: "Ekoi Veloce Helmet - White.png"
    },
    {
        "ID": 28,
        "Name": "Van Rysel FCR Aero Helmet",
        "Description": "Van Rysel FCR Aero Helmet. At the highest level, and at high speeds, aerodynamics make all the difference.",
        "Weight": 0,
        "Color": "",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 7,
        "MeshId": 11,
        "AtlasId": 4,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 27,
        thumbnail: "Van Rysel FCR Aero Helmet.png"
    },
    {
        "ID": 29,
        "Name": "MyWhoosh Radiance Helmet",
        "Description": "MyWhoosh Radiance Helmet, style and functionality combined to meet the performance needs of any cyclist.",
        "Weight": 0,
        "Color": "",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 7,
        "MeshId": 12,
        "AtlasId": 4,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 28,
        thumbnail: "MyWhoosh Radiance Helmet.png"
    },
    {
        "ID": 30,
        "Name": "MyWhoosh Desert Sand Helmet",
        "Description": "MyWhoosh Desert Sand Helmet, classic design and style to meet all riders demands.",
        "Weight": 0,
        "Color": "",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 7,
        "MeshId": 13,
        "AtlasId": 4,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 29,
        thumbnail: "MyWhoosh Desert Sand Helmet.png"
    },
	{
        "ID": 32,
        "Name": "ABUS AirBreaker Orange",
        "Description": "With an innovative Multi Speed design, the AirBreaker always offers the best possible aerodynamics and at the same time ensures optimum ventilation. ",
        "Weight": 0,
        "Color": "",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 7,
        "MeshId": 13,
        "AtlasId": 4,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 29,
        thumbnail: "ABUS AirBreaker Orange.png"
    },
	{
        "ID": 33,
        "Name": "ABUS AirBreaker White",
        "Description": "With an innovative Multi Speed design, the AirBreaker always offers the best possible aerodynamics and at the same time ensures optimum ventilation.",
        "Weight": 0,
        "Color": "",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 7,
        "MeshId": 13,
        "AtlasId": 4,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 29,
        thumbnail: "ABUS AirBreaker White.png"
    },
	{
        "ID": 34,
        "Name": "ABUS GameChanger Black",
        "Description": "The GameChanger sets new standards in the battle against the wind. With its Multi Position Design, the GameChanger redefines aerodynamics.",
        "Weight": 0,
        "Color": "",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 7,
        "MeshId": 13,
        "AtlasId": 4,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 29,
        thumbnail: "ABUS GameChanger Black.png"
    },
	{
        "ID": 35,
        "Name": "ABUS GameChanger Blue",
        "Description": "The GameChanger sets new standards in the battle against the wind. With its Multi Position Design, the GameChanger redefines aerodynamics.",
        "Weight": 0,
        "Color": "",
        "model": 0,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 7,
        "MeshId": 13,
        "AtlasId": 4,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 29,
        thumbnail: "ABUS GameChanger Blue.png"
    }
];