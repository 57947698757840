<template>
  <div>
    <vue-title title="Thank You - MyWhoosh! Your Indoor Cycling Solution"></vue-title>

    <section class="description">
      <div class="container">
        <div class="row">

          <div class="col-md-12 text-center">
            <div class="p-5">
            <svg class="checkGreen" viewBox="0 0 120 121" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="60" cy="60.5" r="60" fill="#1DA46E"/>
              <path d="M89.1818 40.5L49.1818 80.5L31 62.3182" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </div>

          <div class="col-md-12">
            <h1 class="heading">Thank You for Registering!</h1>
            <p class="thank-you">
              We're currently processing your registration. Our team is working hard to make sure everything goes smoothly for you. If you have any questions or need help, feel free to email us at <a
                href="mailto:racecontrol@mywhoosh.com">racecontrol@mywhoosh.com</a>.
               Please do not forget to read MyWhoosh Cycling Esports Rules & Regulations
            </p>
          </div>

          <EventLinks v-if="getLinks" :links="getLinks" :new_version="true" />
        </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EventLinks from "@/components/event/EventLinks";

export default {
  name: 'EventThankYou',

  components: { EventLinks },

  data() {
    return {
      eventId: this.$route.params.id,
    };
  },
  
  computed: {
    ...mapGetters("event", ['event']),
    
    getLinks() {
      if (!this.event || !this.event.event) return;
      return this.event.event.links;
    },

    getEventData() {
      if (!this.event || !this.event.event) return false;
      return Object.assign({}, this.event.event);
    },
  },
  
  mounted() {
    // add contact class to body
    document.body.id = 'Whoosh';
    document.body.classList.add('contact');
    this.$store.dispatch("event/event", this.eventId);
  },

  destroyed() {
    document.body.classList.remove('contact')
  }
}
</script>

<style scoped>
.heading {
  font-family: 'DIN Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  margin-bottom: 20px;
}
.checkGreen {
  width: 75px;
}
.thank-you {
  width: 70%;
  margin: auto;
  margin-bottom: 0px;
}
</style>