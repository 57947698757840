<template>
    <div class="status error">


      <img v-if="coins_purchased && imageIndex !== -1"
           :src="`/assets/images/shop/icons/coins-${imageIndex + 1}x.png`" alt="treasure" />

      <img v-else-if="gems_purchased && imageIndex !== -1"
           :src="`/assets/images/shop/icons/gems-${imageIndex + 1}x.png`" alt="treasure" />

      <img v-else src="/assets/images/shop/icons/treasure.png" alt="treasure" />



      <h3>Sorry!</h3>
        <p>Something went wrong. Please try again.</p>

        <!-- <div class="icon">!</div> -->
        <svg
            class="animated-icon"
            width="90"
            height="90"
            viewBox="0 0 90 90"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle class="circle" cx="45" cy="45" r="45" fill="#DA2E2E" />
            <path
                class="line"
                d="M45 24L45 54.1351"
                stroke="white"
                stroke-width="8"
                stroke-linecap="round"
            />
            <path
                class="dot"
                d="M45 66.8232H45.0001"
                stroke="white"
                stroke-width="8"
                stroke-linecap="round"
            />
        </svg>

        <button class="btn btn-dark" type="button" @click="redirectToHome">
            Ok
        </button>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "MessageError",
  props: ["coins_purchased", "gems_purchased"],
    components: {},
    data() {
        return {
          imageIndex: -1
        };
    },
  computed: {
    ...mapGetters("gameshop", [
      "gems_bundles",
      "coins_bundles",
    ]),
  },
    methods: {
        redirectToHome() {
            window.location.href = "/user/activities";
        },
      giveImageIndex(type, value) {
        let bundles = type === 'gems' ? this.gems_bundles : this.coins_bundles;
        let index = bundles.findIndex(bundle => bundle.Reward == value);

        this.imageIndex = index !== -1 ? index : 0; // Fallback to the first image
      }
    },
  mounted() {
    if (this.gems_purchased) {
      this.giveImageIndex('gems', this.gems_purchased);
    } else if (this.coins_purchased) {
      this.giveImageIndex('coins', this.coins_purchased);
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    gems_bundles(newValue) {
      if (this.gems_purchased) {
        this.giveImageIndex('gems', this.gems_purchased);
      }
    },
    // eslint-disable-next-line no-unused-vars
    coins_bundles(newValue) {
      if (this.coins_purchased) {
        this.giveImageIndex('coins', this.coins_purchased);
      }
    }
  },
};
</script>

<style>
.status.error {
    margin-top: 0;
}

.status.error .icon {
    background-color: #da2e2e;
    color: #ffffff;
    height: 114px;
    width: 114px;
    line-height: 114px;
    font-size: 72px;
    border-radius: 50%;
}

.animated-icon {
    width: 90px;
    height: 90px;
    animation: bounce 1s ease-in-out forwards;
  margin-bottom: 1%;
}

.circle {
    /* animation: bounce 1s ease-in-out forwards; */
}

.line {
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    animation: draw 1s ease-in-out forwards;
    animation-delay: 1s; /* Delay the line animation to start after the circle animation */
}

.dot {
    stroke-dasharray: 10;
    stroke-dashoffset: 10;
    animation: draw 0.5s ease-in-out forwards;
    animation-delay: 2s; /* Delay the dot animation to start after the line animation */
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}

@keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
}
</style>
