<template>
  <div>
    <Banner v-if="page" :page="page" />

    <section v-if="page" class="events-page-wrap clearfix">
      <div class="row">
        <div class="col-sm-12">
          <div class="col-sm-6 left-side">
            <h1>{{ page[`left-section-heading`] }}</h1>
            <br>
            <p>{{ page[`left-section-description`] }}</p>
          </div>
          <!--col-sm-6-->

          <div class="col-sm-6 right-side">

            <h1>{{ page[`right-section-heading`] }}</h1>
  <!--           <p>Don't have an account? Create your account, it takes less than a minute</p>-->

            <form @submit.prevent="submitHandler">
              <div class="form ">

                <div class=" blockit d-flex justify-content-between">

                  <div class="w-45 form-group">
                    <label for="name">Name</label>
                    <input v-model.trim="form.name" class="form-control" type="text" id="name">
                    <div v-if="submitted && getError('name')" class="text-danger">First Name is required</div>
                  </div>

                  <div class="w-45 form-group">
                    <label for="phone">Phone Number</label>
                    <input v-model="form.phone" class="form-control" type="text" id="phone">
  <!--                  <div v-if="submitted && getError('phone')" class="text-danger">Phone is required</div>-->
                  </div>
                </div>

                <div class="form-group">
                  <label for="email">Email Address</label>
                  <input v-model="form.email" class="form-control" type="email" id="email">
                  <div v-if="submitted && getError('email')" class="text-danger">A valid email is required</div>
                </div>

                <div class="form-group">
                  <label for="message">Message</label>
                  <textarea v-model="form.message" class="form-control" rows="5" id="message"></textarea>
                  <div v-if="submitted && getError('message')" class="text-danger">Message must be at least 10 characters long</div>
                </div>

                <div class="text-right">
                  <button :disabled="submitted === 'PENDING'" class="btn-universal border-0 btn-deep-purple" type="submit">Submit</button>
                </div>
              </div>
            </form>

            <div v-if="errors" class="text-danger ml-5">
              {{getErrors}}
            </div>
          </div>

        </div>
      </div>
    </section>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {required, email, minLength} from 'vuelidate/lib/validators'
import Banner from '@/components/shared/Banner'

export default {
  name: 'ContactUs',

  data() {
    return {
      form: {},
      submitted: false
    }
  },

  components: {
    Banner
  },

  validations: {
    form: {
      name: {required},
      email: {required, email},
      message: {required, minLength: minLength(10)},
    }
  },

  computed: {
    ...mapGetters("contact", ['formStatus']),
    ...mapGetters("page", ['page']),
    ...mapGetters(['errors']),

    getErrors() {
      if(!this.errors.message) return ;
      return this.errors.message.join("\n")
    }
  },

  mounted() {
    document.body.id = 'Whoosh';
    document.body.classList.add('contact');

    this.$store.dispatch("contact/resetForm"); //reset form response
    this.$store.dispatch("page/view", "contact");
  },

  destroyed() {
    document.body.classList.remove('contact')
  },

  methods: {
    submitHandler() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$store.dispatch("contact/sendEmail", this.form).then(res => {
        if(res && res.status) {
          this.form = {};
          this.submitted = false;
        }
      });
    },

    getError(field) {
      return this.$v.form[field].$invalid;
    }
  }
}
</script>
