<template>
  <div>
    <div class="stepsHeading">Choose any one of the options:</div>
    <div
      class="cardsWrpr d-flex justify-content-between align-items-center flex-wrap m-auto"
    >
      <div v-if="getError('migration_platform')" class="error-message">
        You need to select platform to migrate from.
      </div>

      <label :class="{ active: migration_platform === 'Strava' }">
        <input
          type="radio"
          name="migration_platform"
          value="Strava"
          v-model="migration_platform"
        />
        <img
          src="/assets/images/steps-form-logos/strava.png"
          alt="strava import"
        />
      </label>

      <label :class="{ active: migration_platform === 'Zwift' }">
        <input
          type="radio"
          name="migration_platform"
          value="Zwift"
          v-model="migration_platform"
        />
        <img
          src="/assets/images/steps-form-logos/zwift.png"
          alt="swift import"
        />
      </label>

      <label :class="{ active: migration_platform === 'Trainer road' }">
        <input
          type="radio"
          name="migration_platform"
          value="Trainer road"
          v-model="migration_platform"
        />
        <img
          src="/assets/images/steps-form-logos/tr_updated.png"
          alt="trainer road import"
        />
      </label>

      <label :class="{ active: migration_platform === 'Rouvy' }">
        <input
          type="radio"
          name="migration_platform"
          value="Rouvy"
          v-model="migration_platform"
        />
        <img
          src="/assets/images/steps-form-logos/rouvy.png"
          alt="rouvy import"
        />
      </label>

      <label :class="{ active: migration_platform === 'Bkool' }">
        <input
          type="radio"
          name="migration_platform"
          value="Bkool"
          v-model="migration_platform"
        />
        <img
          src="/assets/images/steps-form-logos/bkool.png"
          alt="bkool import"
        />
      </label>

      <label :class="{ active: migration_platform === 'Others' }">
        <input
          type="radio"
          name="migration_platform"
          value="Others"
          v-model="migration_platform"
        />
        <img
          src="/assets/images/steps-form-logos/others.png"
          alt="others import"
        />
      </label>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "StepOne",
  data() {
    return {
      migration_platform: "",
    };
  },
  validations: {
    migration_platform: { required },
    form: ["migration_platform"],
  },
  methods: {
    resetData() {
      this.migration_platform = "";
    },
    validate() {
      this.$v.form.$touch();
      const isValid = !this.$v.form.$invalid;
      this.$emit("on-validate", this.$data, isValid);
      return isValid;
    },
    getError(field) {
      return this.$v[field].$error;
    },
  },
  watch: {
    migration_platform() {
      this.$emit("selected-platform");
    },
  },
};
</script>
