<template>
  <div>
    <vue-title title="Faqs - MyWhoosh! Your Indoor Cycling Solution"></vue-title>

    <section class="banner bg-m faqs-banner">
      <div class="container">
        <div class="overlay"></div>
        <div class="sreachBar">
          <h1 style="text-transform:none;">
            FAQs
          </h1>
<!--          <p>View our Questions and Anwsers</p>-->
        </div>
      </div>
    </section>

    <section class="description">
      <div class="container">
        <div class="row">

          <section class="col-md-12" v-for="(faq, i) in faqs" :key="i">

            <h3>{{ faq.topic }}</h3>

            <p>{{ faq.topic_description }}</p>

            <div class="accordion accordion-faq" :id="`accordion-${i}`">

              <div class="card" v-for="(question, j) in faq.questions" :key="j">
                <div class="card-header" :id="`heading-${i}-${j}`">
                  <h2 class="mb-0">
                    <button class="btn btn-link btn-block text-left collapsed " type="button" data-toggle="collapse"
                            :data-target="`#collapse-${i}-${j}`" aria-expanded="false" :aria-controls="`collapse-${i}-${j}`">
                      <span>Q {{ j + 1 }}: {{ question.question }}</span>
                      <i class="fas fa-caret-down"></i>
                    </button>
                  </h2>
                </div>
                <div :id="`collapse-${i}-${j}`" class="collapse" :aria-labelledby="`heading-${i}-${j}`" :data-parent="`#accordion-${i}`">
                  <div class="card-body" v-html="question.answer"></div>
                </div>
              </div>

            </div>

          </section>

        </div>
      </div>
    </section>

  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'Faqs',
  data() {
    return {}
  },
  computed: {
    ...mapGetters('page', ['faqs']),
  },
  mounted() {
    // add contact class to body
    document.body.id = 'Whoosh';
    document.body.classList.add('contact');
    this.$store.dispatch('page/faqs');
  },
  destroyed() {
    document.body.classList.remove('contact')
  }
}
</script>
