import 'sweetalert2/src/sweetalert2.scss'
import Swal  from 'sweetalert2'

const sweetAlert = {
  alert: (message, icon='error', pg = true) =>  {
    Swal.fire({
      showCloseButton: true,
      html: message,
      icon,
      showConfirmButton: false,
      timer: pg ? 10000 : 0,
      timerProgressBar: pg,
      // onClose: () => {
      //   clearInterval(timerInterval)
      // }
    });
  }
}

export default sweetAlert;
