<template>
  <div>
    <vue-title title="Reset your password - Your Indoor Cycling Solution"></vue-title>

    <section class="login-wrap mt-5">
      <div class="container">
        <div class="row">
          <section class="col-xl-6 col-lg-7 col-md-9 mx-auto">
            <div class="login-box">
              <h3>Reset your password</h3>
              <form @submit.prevent="submitHandler()" autocomplete="off">
                <div class="form-group">
                  <label for="password">New Password</label>
                  <input type="password" v-model="form.password" class="form-control" id="password" placeholder="Enter new password" autocomplete="chrome-off">
                  <div v-if="submitted && getError('password')" class="text-danger text-left">Password must be at least 6 characters</div>
                </div>
                <div class="form-group">
                  <label for="password">Confirm New Password</label>
                  <input type="password" v-model="form.repeatPassword" class="form-control" id="cpassword" placeholder="Repeat new password" autocomplete="chrome-off">
                  <div v-if="submitted && getError('repeatPassword')" class="text-danger text-left">Password doesn't match</div>
                </div>
                <div style="clear: both;"></div>
                <button type="submit" class="btn-universal cursor-pointer">Submit</button>
                
              </form>
            </div>
          </section>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import {mapGetters}      from "vuex";
import {minLength, required, sameAs} from 'vuelidate/lib/validators'

export default {
  name: 'ForgotPassword2',

  data() {
    return {
      form: {},
      submitted: false,
      code: this.$route.query.code,
    }
  },

  validations: {
    form: {      
      password: {required, minLength: minLength(6)},
      repeatPassword: {
        sameAsPassword: sameAs('password')
      }
    }
  },

  computed: {
    ...mapGetters(['errors']),
  },

  mounted() {
    document.body.classList.add('contact');
  },

  destroyed() {
    document.body.classList.remove('contact')
  },

  methods: {
    async submitHandler() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.form.code = this.code
      const res = await this.$store.dispatch('auth/replacePassword', this.form)

      if(res && res.status) {
        this.$router.push({name: 'login'})
        return ;
      }
    },

    getError(field) {
        return this.$v.form[field].$invalid;
    },
  },
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.text-danger {
  font-size: 12px;
}
</style>
