//import Service from "@/services";
import { countries } from "@/services/local-db";

export default {
  namespaced: true,

  state: {
    countries: []
  },

  getters: {
    countries: (state) => state.countries,
  },

  actions: {
    async countries({commit}) {
      try {
        return await countries().then(res => {
          commit('SET_COUNTRIES', res.data);
          return res;
        });
      }
      catch (e) {
        commit('SET_COUNTRIES', []);
        console.log('Error: ', e);
      }
    }
  },

  mutations: {
    SET_COUNTRIES: (state, payload) => state.countries = payload
  }
}
