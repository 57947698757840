<template>
  <div>
    <div v-for="(event, i) in events" :key="i" class="event-listing" v-bind:class="{ 'active-event': i === activeId }">
      <EventSummary :event="event" :join="true" :details=false />

      <transition name="slide">
        <div class="row" v-if="i === activeId">
          <section class="col-md-12">
            <div class="event-description">
              <h4>DESCRIPTION</h4>
              <div v-html="event.details"></div>
            </div>
          </section>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import EventSummary from "@/components/event/EventSummary";
import { commonMixin } from '@/mixins/commonMixin';
import MinMaxCap from '@/json/min_max_cap.json';

export default {
  name: 'EventsList',
  props: ['events'],
  mixins: [commonMixin],

  components: {
    EventSummary
  },

  data() {
    return {
      guideId: -1,
      activeId: -1,
      MinMaxCap
    }
  },

  methods: {
    toggleDetail(i) {
      this.activeId = this.activeId !== i ? i : -1;
    },

    toggleGuide(i) {
      this.guideId = this.guideId !== i ? i : -1;
    }
  }
}
</script>

<style scoped>
.alert-12 {
  font-size: 14px;
  font-weight: bold;
}

ul {
  cursor: pointer;
}

.min_max_cap {
  font-size: 13px;
}
</style>
