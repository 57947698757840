const BASE_URL = process.env.VUE_APP_JAVA_BASE_URL

import Service from "@/services";
import { FrameSet } from "@/components/game_shop/schemas/frame";
import { WheelsSet } from "@/components/game_shop/schemas/wheels";
import { HeadSet } from "@/components/game_shop/schemas/FaceEthnicity";
import { HairSet } from "@/components/game_shop/schemas/Hair";
import { FacialHairSet } from "@/components/game_shop/schemas/FacialHair";
import { JerseySet } from "@/components/game_shop/schemas/Jersies";
import { GlovesSet } from "@/components/game_shop/schemas/Gloves";
import { HelmetSet } from "@/components/game_shop/schemas/Helmet";
import { ShoesSet } from "@/components/game_shop/schemas/Shoes";
import { SocksSet } from "@/components/game_shop/schemas/Socks";
import { GlassesSet } from "@/components/game_shop/schemas/glasses";
import {encryptionMixin} from "@/mixins/encryptionMixin";


export default {
    namespaced: true,
    state: {
        bundles: [],
        frames: [],
        wheels: [],
        head: [],
        hair: [],
        glasses: [],
        jerseys: [],
        shoes: [],
        socks: [],
        helmet: [],
        gloves: [],
        facialHair: [],
        gems_bundles: [],
        coins_bundles: [],
    },
    getters: {
        bundles: (state) => state.bundles,
        frames: (state) => state.frames,
        wheels: (state) => state.wheels,
        head: (state) => state.head,
        hair: (state) => state.hair,
        socks: (state) => state.socks,
        shoes: (state) => state.shoes,
        helmet: (state) => state.helmet,
        gloves: (state) => state.gloves,
        jerseys: (state) => state.jerseys,
        glasses: (state) => state.glasses,
        facialHair: (state) => state.facialHair,
        gems_bundles: (state) => state.gems_bundles,
        coins_bundles: (state) => state.coins_bundles,
    },
    actions: {

        // eslint-disable-next-line no-unused-vars
        async getRedirectUrl({ commit }, payload) {
            try {
                return await Service().post(
                    "../api/garage/redirect-to-checkout",
                    payload
                )
                // eslint-disable-next-line no-unreachable
            } catch (e) {
                return [];
            }
        },

        async getAllGarageItems({ commit }) {
             try {
                 const response = await Service().get(BASE_URL + "v1/economy/garage/item");

                 const garage_items = response['MyWhooshShopItemsMetaData'] ?? [];

                // Define item types as an array with type, set, thumbnail, and commit information
                const itemTypes = [
                    { type: 0, set: FrameSet, thumbnail: "Frames", commit_name: "SET_FRAMES" },
                    { type: 1, set: WheelsSet, thumbnail: "wheels", commit_name: "SET_WHEELS" },
                    { type: 2, set: HelmetSet, thumbnail: "Headgear", commit_name: "SET_HELMET" },
                    { type: 3, set: GlovesSet, thumbnail: "Gloves", commit_name: "SET_GLOVES" },
                    { type: 5, set: GlassesSet, thumbnail: "Eyewear", commit_name: "SET_GLASSES" },
                    { type: 6, set: HairSet, thumbnail: "/assets/images/shop/bike/frame/bike-thumb4.png", commit_name: "SET_HAIR" },
                    { type: 7, set: FacialHairSet, thumbnail: "/assets/images/shop/bike/frame/bike-thumb5.png", commit_name: "SET_FACIALHAIR" },
                    { type: 8, set: JerseySet, thumbnail: "Jersey", commit_name: "SET_JERSEYS" },
                    { type: 10, set: SocksSet, thumbnail: "Socks", commit_name: "SET_SOCKS" },
                    { type: 11, set: ShoesSet, thumbnail: "Shoes", commit_name: "SET_SHOES" },
                    { type: 15, set: HeadSet, thumbnail: "/assets/images/shop/bike/frame/bike-thumb3.png", commit_name: "SET_HEAD" }
                ];

                // // Function to add details to items based on set and thumbnail
                const addDetails = (item, set, thumbnail) => {
                    const match = set.find((shopItem) => shopItem.ID == item.Id);
                    if (match && item.UnlockValue != 0 && item.UnlockType == 1 && item.CurrencyType == 0) {
                        return {
                            ...item,
                            Description: match.Description,
                            Aerodynamics: match?.Aerodynamics,
                            Weight: match?.Weight ?? null,
                            thumbnail: `/assets/images/shop/${thumbnail}/${match.thumbnail}`,
                            Name: match?.Name
                        };
                    }
                    return null;
                };

                // // Process each item type and commit items to the store
                itemTypes.forEach(({ type, set, thumbnail, commit_name }) => {
                    const items = garage_items
                        .filter(item => item.Type == type)
                        .map(item => addDetails(item, set, thumbnail))
                        .filter(Boolean);
                    commit(commit_name, items);
                });

                return true; // Success indicator
            } catch (error) {
                 console.error(error)

                // Fallback commit types in case of error
                const itemTypesFallback = [
                    "SET_GLASSES", "SET_HELMET", "SET_GLOVES", "SET_SOCKS", "SET_JERSEYS",
                    "SET_FRAMES", "SET_WHEELS", "SET_SHOES", "SET_HEAD", "SET_HAIR", "SET_FACIALHAIR"
                ];

                // Commit empty arrays for fallback commit types
                itemTypesFallback.forEach(commitType => commit(commitType, []));

                return error; // Error indicator
            }
        },
        async getBundleInfo({ commit }) {
            try {

                const response = await Service().get(BASE_URL + "v1/economy/bundle/info");
                const bundle_info = response.MyWhooshShopBundlesData ?? [];

                if (bundle_info.length === 0) {
                    console.warn("No bundle information found.");
                    commit("SET_GEMS_BUNDLE", []);
                    commit("SET_COINS_BUNDLE", []);
                    return false; // Indicate that no bundles were found
                }

                const filterAndSortBundles = (type) => {
                    return bundle_info
                        .filter(item => item.Platform == "IOS" && item.Type == type)
                        .sort((a, b) => a.Reward - b.Reward);
                };

                const gems_bundles = filterAndSortBundles("gems");
                const coins_bundles = filterAndSortBundles("coins");

                commit("SET_GEMS_BUNDLE", gems_bundles);
                commit("SET_COINS_BUNDLE", coins_bundles);

                return true;
            } catch (e) {
                console.error("Error fetching bundle info:", e);
                return e;
            }
        } ,
        // eslint-disable-next-line no-unused-vars
        async getPaymentInfo({ commit }, payload) {
            try {
                return await Service().post(
                    "../api/garage/payment/info",
                    payload
                );
                // eslint-disable-next-line no-unreachable
            } catch (e) {
                return [];
            }
        },
        // eslint-disable-next-line no-unused-vars
        async purchaseGems({ commit }, payload) {
            try {
                return await Service().post(
                    "../api/garage/purchase/gems",
                    payload
                );
                // eslint-disable-next-line no-unreachable
            } catch (e) {
                return [];
            }
        },
        // eslint-disable-next-line no-unused-vars
        async purchaseItem({ commit }, payload) {
            try {
                const payloadEnc = JSON.stringify(payload);
                const encryptedPayload = encryptionMixin.methods.encrypt(payloadEnc);

                return await Service().post(
                    "../api/garage/purchase/item",
                    {payload:encryptedPayload}
                );
                // eslint-disable-next-line no-unreachable
            } catch (e) {
                return [];
            }
        },
        // eslint-disable-next-line no-unused-vars
        async checkUserPurchaseItem({ commit }, payload) {
            try {

             const payloadEnc = JSON.stringify(payload);
               const encryptedPayload = encryptionMixin.methods.encrypt(payloadEnc);

                return await Service().post(
                    "../api/garage/check-user-purchase-item",
                    {payload:encryptedPayload}
                );
                // eslint-disable-next-line no-unreachable
            } catch (e) {
                console.error(e)
                return [];
            }
        },
    },
    mutations: {
        SET_BUNDLES: (state, payload) => (state.bundles = payload),
        SET_FRAMES: (state, payload) => (state.frames = payload),
        SET_WHEELS: (state, payload) => (state.wheels = payload),
        SET_HEAD: (state, payload) => (state.head = payload),
        SET_HAIR: (state, payload) => (state.hair = payload),
        SET_FACIALHAIR: (state, payload) => (state.facialHair = payload),
        SET_GEMS_BUNDLE: (state, payload) => (state.gems_bundles = payload),
        SET_COINS_BUNDLE: (state, payload) => (state.coins_bundles = payload),
        SET_JERSEYS: (state, payload) => (state.jerseys = payload),
        SET_GLOVES: (state, payload) => (state.gloves = payload),
        SET_HELMET: (state, payload) => (state.helmet = payload),
        SET_SHOES: (state, payload) => (state.shoes = payload),
        SET_SOCKS: (state, payload) => (state.socks = payload),
        SET_GLASSES: (state, payload) => (state.glasses = payload),
    },
};
