<template>
  <div class="status success">
    <img v-if="coins_purchased && imageIndex !== -1"
         :src="`/assets/images/shop/icons/coins-${imageIndex + 1}x.png`" alt="treasure" />

    <img v-else-if="gems_purchased && imageIndex !== -1"
         :src="`/assets/images/shop/icons/gems-${imageIndex + 1}x.png`" alt="treasure" />

    <img v-else src="/assets/images/shop/icons/treasure.png" alt="treasure" />

    <h3>Congratulations!</h3>
    <p v-if="coins_purchased">
      You have successfully purchased {{ coins_purchased }} coins.
    </p>
    <p v-if="gems_purchased">
      You have successfully purchased {{ gems_purchased }} gems.
    </p>

    <div class="icon">
      <svg
          xmlns="http://www.w3.org/2000/svg"
          class="svg-success"
          viewBox="0 0 24 24"
      >
        <g
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
        >
          <circle
              class="success-circle-outline"
              cx="12"
              cy="12"
              r="11.5"
          />
          <circle
              class="success-circle-fill"
              cx="12"
              cy="12"
              r="11.5"
          />
          <polyline
              class="success-tick"
              points="17,8.5 9.5,15.5 7,13"
          />
        </g>
      </svg>
    </div>

    <button class="btn btn-dark" type="button" @click="redirectToHome">
      Ok
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MessageSuccess",
  props: ["coins_purchased", "gems_purchased"],
  data() {
    return {
      imageIndex: -1
    };
  },
  computed: {
    ...mapGetters("gameshop", [
      "gems_bundles",
      "coins_bundles",
    ]),
  },
  methods: {
    redirectToHome() {
      window.location.href = "/user/activities";
    },
    giveImageIndex(type, value) {
      let bundles = type === 'gems' ? this.gems_bundles : this.coins_bundles;
      let index = bundles.findIndex(bundle => bundle.Reward == value);

      this.imageIndex = index !== -1 ? index : 0; // Fallback to the first image
    }
  },
  mounted() {
    if (this.gems_purchased) {
      this.giveImageIndex('gems', this.gems_purchased);
    } else if (this.coins_purchased) {
      this.giveImageIndex('coins', this.coins_purchased);
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    gems_bundles(newValue) {
      if (this.gems_purchased) {
        this.giveImageIndex('gems', this.gems_purchased);
      }
    },
    // eslint-disable-next-line no-unused-vars
    coins_bundles(newValue) {
      if (this.coins_purchased) {
        this.giveImageIndex('coins', this.coins_purchased);
      }
    }
  },
};
</script>


<style>
.svg-success {
    display: inline-block;
    vertical-align: top;
    height: 90px;
    width: 90px;
    opacity: 1;
    overflow: visible;
}

@-webkit-keyframes success-tick {
    0% {
        stroke-dashoffset: 16px;
        opacity: 1;
    }
    100% {
        stroke-dashoffset: 31px;
        opacity: 1;
    }
}

@keyframes success-tick {
    0% {
        stroke-dashoffset: 16px;
        opacity: 1;
    }
    100% {
        stroke-dashoffset: 31px;
        opacity: 1;
    }
}

@-webkit-keyframes success-circle-outline {
    0% {
        stroke-dashoffset: 72px;
        opacity: 1;
    }
    100% {
        stroke-dashoffset: 0px;
        opacity: 1;
    }
}

@keyframes success-circle-outline {
    0% {
        stroke-dashoffset: 72px;
        opacity: 1;
    }
    100% {
        stroke-dashoffset: 0px;
        opacity: 1;
    }
}

@-webkit-keyframes success-circle-fill {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes success-circle-fill {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.svg-success .success-tick {
    fill: none;
    stroke-width: 1px;
    stroke: #ffffff;
    stroke-dasharray: 15px, 15px;
    stroke-dashoffset: -14px;
    -webkit-animation: success-tick 450ms ease 1400ms forwards;
    animation: success-tick 450ms ease 1400ms forwards;
    opacity: 0;
}

.svg-success .success-circle-outline {
    fill: none;
    stroke-width: 1px;
    stroke: #34c17d;
    stroke-dasharray: 72px, 72px;
    stroke-dashoffset: 72px;
    -webkit-animation: success-circle-outline 300ms ease-in-out 800ms forwards;
    animation: success-circle-outline 300ms ease-in-out 800ms forwards;
    opacity: 0;
}

.svg-success .success-circle-fill {
    fill: #34c17d;
    stroke: none;
    opacity: 0;
    -webkit-animation: success-circle-fill 300ms ease-out 1100ms forwards;
    animation: success-circle-fill 300ms ease-out 1100ms forwards;
}
</style>
