<template>
  <div>
    <vue-title title="Account verification | MyWhoosh - Your Indoor Cycling Solution"></vue-title>

    <section class="banner">
      <div class="container">
        <div class="overlay"></div>
        <div class="sreachBar">
          <h1>Profile</h1>
        </div>
      </div>

    </section>

    <section class="login-wrap">
      <div class="container">
        <div class="row">
          <section class="col-md-6 mx-auto">
            <div class="login-box">
              <h3>Verify Account</h3>
              <span>Verify your account by entering OTP sent to your email address</span>
              <form @submit.prevent="verificationHandler()" autocomplete="off">
                <div class="form-group">
                  <input type="number" :class="{ 'is-invalid': errors && errors.otp }" class="form-control" v-model="form.otp"  id="exampleInputEmail1" placeholder="Enter your OTP" autocomplete="chrome-off">
                  <div class="invalid-feedback" v-if="errors && errors.otp">{{ errors.otp }}</div>
                </div>
                <button class="btn-universal" type="submit">Verify Account</button>

                <div class="text-center mt-3">
                  <router-link :to="{name: 'login'}">Back to Login</router-link><br>
                  <router-link :to="{name: 'register'}">Create New Account?</router-link>
                </div>

              </form>
            </div>
          </section>
        </div>
      </div>
    </section>

  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: 'Verification',

  data() {
    return {
      form: {
        otp: null,
      }
    }
  },

  computed: {
    ...mapGetters(['errors']),
    ...mapGetters('auth', ['otpStatus']),
  },

  mounted() {
    // add contact class to body
    document.body.classList.add('contact');
  },
  destroyed() {
    document.body.classList.remove('contact')
  },

  methods: {
    verificationHandler() {
      this.$store.dispatch('auth/verifyOtp', {
        key: localStorage.getItem('user_key'),
        otp: this.form.otp
      });
    },
  },

  watch: {
    otpStatus(value) {
      if(value) {
        this.form.otp = null;
        localStorage.removeItem('user_key');
        localStorage.removeItem('user_email');
        this.$router.push({name: 'login'});
      }
    }
  }
}
</script>
<style scoped>
a {
  color: #000;
  font-size: 12px;
}
</style>
