import Service from "@/services";

export default {
    namespaced: true,

    state: {
        loading: false,
        mainLoading: false,
        temp_key: null,
        cacheData: null,
        subscribed: false,
        countries: [],
    },

    getters: {
        getLoading: (state) => state.loading,
        getMainLoading: (state) => state.mainLoading,
        getTempKey: (state) => state.temp_key,
        getCacheData: (state) => state.cacheData,
        getSubscribed: (state) => state.subscribed,
        getCountries: (state) => state.countries,
    },

    actions: {
        async getCacheData({commit}) {
            try {
                return await Service().get(`static`).then(res => {
                    commit('SET_CACHE_DATA', res.data);
                });
            } catch (e) {
                commit("SET_ERRORS", e.errors, {root: true});
                console.log('Tmp Key Error: ', e.message);
            }
        },

        async getCountries({commit}) {
            try {
                return await Service().get(`countries`).then(res => {
                    commit('SET_COUNTRIES', res.data);
                });
            } catch (e) {
                commit("SET_ERRORS", e.errors, {root: true});
                console.log('Countries Error: ', e.message);
            }
        },

        async generateKey({commit}, payload) {
            try {
                return await Service().post(`key/new`, payload).then(res => {
                    if (res.status) {
                        commit('SET_TEMP_KEY', res.data);
                    }
                });
            } catch (e) {
                global.app.$store.commit("SET_ERRORS", e.errors, {root: true});
                console.log('Tmp Key Error: ', e.message);
            }
        },

        async subscribe({commit}, payload) {
            try {
                return await Service().post(`subscribe`, payload).then(res => {
                    commit('SET_SUBSCRIBE', true);
                    return res.data;
                });
            } catch (e) {
                commit('SET_SUBSCRIBE', false);
                console.log('Error: ', e);
            }
        }

    },

    mutations: {
        SET_LOADING: (state, status) => state.loading = status,
        SET_SUBSCRIBE: (state, status) => state.subscribed = status,
        SET_MAIN_LOADING: (state, status) => state.mainLoading = status,
        SET_TEMP_KEY: (state, payload) => state.temp_key = payload,
        SET_CACHE_DATA: (state, payload) => state.cacheData = payload,
        SET_COUNTRIES(state, payload) {
            state.countries = payload.map(country => {
                return {
                    Id: country.value,
                    Name: country.label
                }
            })

        },
    }
}
