import Service from "@/services";

export default {

        namespaced: true,

        state: {
        },

        getters: {
        },

        actions: {
                async over_grid_data(_, payload) {
                        try {
                                 const uuid = localStorage.getItem('uuid');
                                return await Service().get(`${process.env.VUE_APP_OVER_VIEW_RIDER_PROFILE_JAVA_API_URL}/progressWall/${uuid}/${payload.year}/${payload.month}/${payload.week}`).then(res => {
                                        return res;
                                });
                        } catch (e) {
                                console.log('Error: ', e);
                        }
                },

                async over_view_bar_chart_data(_, payload) {
                        try {
                                 const uuid = localStorage.getItem('uuid');
                                let apiUrl = `${process.env.VUE_APP_OVER_VIEW_RIDER_PROFILE_JAVA_API_URL}/progress/${uuid}/year/${payload.year}`;

                                if (payload.type === 'week') {
                                        apiUrl = `${process.env.VUE_APP_OVER_VIEW_RIDER_PROFILE_JAVA_API_URL}/progress/${uuid}/week/${payload.year}/${payload.month}/${payload.week}`;
                                } else if (payload.type === 'month') {
                                        apiUrl = `${process.env.VUE_APP_OVER_VIEW_RIDER_PROFILE_JAVA_API_URL}/progress/${uuid}/month/${payload.year}/${payload.month}`;
                                }

                                return await Service().get(apiUrl).then(res => {
                                        return res;
                                });
                        } catch (e) {
                                console.log('Error: ', e);
                        }
                }
        },

        mutations: {
        }
}