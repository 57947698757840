<template>
  <div v-if="getPage">
    <Banner :page="getPage"/>

    <section class="description events-page-wrap clearfix">
      <div class="container">
        <div class="row">
          <section class="col-md-12" v-html="getPage.details">
          </section>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Banner from '@/components/shared/Banner'

export default {
  name: 'Terms',
  components: {
    Banner
  },

  destroyed() {
    document.body.classList.remove('contact')
  },

  computed: {
    ...mapGetters('page', ['page']),

    getPage() {
      if(!this.page) return {};
      return this.page;
    }

  },

  mounted() {
    document.body.id = 'Whoosh';
    document.body.classList.add('contact')
    this.$store.dispatch('page/view', 'terms-and-conditions');
  },

}
</script>
