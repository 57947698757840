<template>
  <div>
    <!-- <vue-title :title="page.title"></vue-title> -->

    <!-- <section class="banner" :style="`background: url(${getBanner})`">
      <div class="container">
        <div class="overlay"></div>
        <div class="sreachBar">
          <h1>{{ page.title }}</h1>
          <p v-if="page.bannerDescription" v-html="page.bannerDescription"></p>
          <input v-if="search" type="text" name="search" placeholder="Start by searching here"/>
        </div>
      </div>
    </section> -->
  </div>
</template>
<script>
export default {
  name: 'Banner',
  props: ['page', 'search'],

  computed: {
    getBanner() {
      if (!this.page.image) return '/assets/images/terms-cond.jpg';
      return `${this.page.image}/1800/600`;
    }

  }
}
</script>
