export const JerseySet = [
    {
        ID: "50",
        Name: "UAE Team Emirates Jersey",
        Description: "UAE Team Emirates 2024 Team Jersey. Ride with the best.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "0",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "0",
        thumbnail: "UAE Team Emirates Jersey.png"

    },
    {
        ID: "51",
        Name: "MyWhoosh Explore Jersey",
        Description:
            "White MyWhoosh Explore Jersey provides comfort and functionality.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "1",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "1",
        thumbnail: "MyWhoosh Explore Jersey-White.png"
    },
    {
        ID: "52",
        Name: "MyWhoosh Explore Jersey",
        Description:
            "Green MyWhoosh Explore Jersey provides comfort and functionality.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "1",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "2",
        thumbnail: "MyWhoosh Explore Jersey-Green.png"
    },
    {
        ID: "54",
        Name: "MyWhoosh Explore Jersey",
        Description:
            "Sky Blue MyWhoosh Explore Jersey provides comfort and functionality.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "1",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "4",
        thumbnail: "MyWhoosh Explore Jersey-SkyBlue.png"
    },
    {
        ID: "55",
        Name: "MyWhoosh Explore Jersey",
        Description:
            "Burgundy MyWhoosh Explore Jersey provides comfort and functionality.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "1",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "5",
        thumbnail: "MyWhoosh Explore Jersey-Burgundy.png"
    },
    {
        ID: "56",
        Name: "MyWhoosh Race Jersey",
        Description:
            "Desert Yellow MyWhoosh Race Jersey is aerodynamic while retaining comfort and style.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "1",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "6",
        thumbnail: "MyWhoosh Race Jersey-Yellow.png"
    },
    {
        ID: "57",
        Name: "MyWhoosh Race Jersey",
        Description:
            "Red MyWhoosh Race Jersey is aerodynamic while retaining comfort and style.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "1",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "7",
        thumbnail: "MyWhoosh Race Jersey-Red.png"
    },
    {
        ID: "58",
        Name: "MyWhoosh Race Jersey",
        Description:
            "Orange Burgundy MyWhoosh Race Jersey is aerodynamic while retaining comfort and style.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "2",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "8",
        thumbnail: "MyWhoosh Race Jersey-Orange.png"
    },
    {
        ID: "59",
        Name: "MyWhoosh Race Jersey",
        Description:
            "Seafoam Green MyWhoosh Race Jersey is aerodynamic while retaining comfort and style.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "2",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "9",
        thumbnail: "MyWhoosh Race Jersey-Seafoam.png"
    },
    {
        ID: "60",
        Name: "MyWhoosh Race Jersey",
        Description:
            "Light Pink MyWhoosh Race Jersey is aerodynamic while retaining comfort and style.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "2",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "10",
        thumbnail: "MyWhoosh Race Jersey-LightPink.png"
    },
    {
        ID: "61",
        Name: "MyWhoosh Race Jersey",
        Description:
            "Dark Blue MyWhoosh Race Jersey is aerodynamic while retaining comfort and style.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "2",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "11",
        thumbnail: "MyWhoosh Race Jersey-DarkBlue.png"
    },
    {
        ID: "62",
        Name: "MyWhoosh Team White Jersey",
        Description: "MyWhoosh Team Issue White Jersey, ride with us!",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "2",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "12",
        thumbnail: "MyWhoosh Team White Jersey.png"
    },
    {
        ID: "63",
        Name: "MyWhoosh Team Black Jersey",
        Description: "MyWhoosh Team Issue Black Jersey, ride with us!",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "2",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "13",
        thumbnail: "MyWhoosh Team Black Jersey.png"
    },
    {
        ID: "73",
        Name: "MyWhoosh Green Rush",
        Description: "MyWhoosh Green Rush, get there Fast",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "4",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "23",
        thumbnail: "MyWhoosh Green Rush.png"
    },
    {
        ID: "74",
        Name: "MyWhoosh Dots Jersey",
        Description: "MyWhoosh Dots Jersey, the ulitmate KOM jersey",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "4",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "24",
        thumbnail: "MyWhoosh Dots Jersey.png"
    },
    {
        ID: "75",
        Name: "MyWhoosh Snake Lines",
        Description: "MyWhoosh Snake Lines Jersey, as fast as a snake.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "4",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "25",
        thumbnail: "MyWhoosh Snake Lines.png"
    },
    {
        ID: "77",
        Name: "MyWhoosh Rainbow Quest Jersey",
        Description: "MyWhoosh Rainbow Quest Jersey, ambition is everything.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "4",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "27",
        thumbnail: "MyWhoosh Rainbow Quest Jersey.png"
    },
    {
        ID: "79",
        Name: "MyWhoosh Afterburn",
        Description: "MyWhoosh Afterburn Jersey, the Rush of Speed.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "4",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "29",
        thumbnail: "MyWhoosh Afterburn.png"
    },
    {
        ID: "80",
        Name: "MyWhoosh Blue Thunder",
        Description: "MyWhoosh Blue Thunder, bring on the storm.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "4",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "30",
        thumbnail: "MyWhoosh Blue Thunder.png"
    },
    {
        ID: "81",
        Name: "MyWhoosh Youth Jersey",
        Description: "MyWhoosh Youth Jersey, white gives youth power.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "4",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "31",
        thumbnail: "MyWhoosh Youth Jersey.png"
    },
    {
        ID: "82",
        Name: "MyWhoosh Orange is new black",
        Description: "MyWhoosh Orange is the new black Jersey, standout from the crowd.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "4",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "32",
        thumbnail: "MyWhoosh Orange is new black.png"
    },
    {
        ID: "83",
        Name: "MyWhoosh Cyclone",
        Description: "MyWhoosh Cyclone Jersey, the unstoppable force.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "4",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "33",
        thumbnail: "MyWhoosh Cyclone.png"
    },
    {
        ID: "84",
        Name: "MyWhoosh Chase Jersey",
        Description: "MyWhoosh Chase Jersey, with a chase you win the race.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "4",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "34",
        thumbnail: "MyWhoosh Chase Jersey.png"
    },
    {
        ID: "85",
        Name: "MyWhoosh Bright in Pink",
        Description: "MyWhoosh Bright in Pink Jersey, light up that ride.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "4",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "35",
        thumbnail: "MyWhoosh Bright in Pink.png"
    },
    {
        ID: "86",
        Name: "MyWhoosh Flashes of Green",
        Description: "MyWhoosh Flashes of Green Jersey, sprint to the line.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "4",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "36",
        thumbnail: "MyWhoosh Flashes of Green.png"
    },
    {
        ID: "87",
        Name: "MyWhoosh Marine",
        Description: "MyWhoosh Marine Jersey, fight like a marine.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "4",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "37",
        thumbnail: "MyWhoosh Marine.png"
    },
    {
        ID: "88",
        Name: "MyWhoosh Alula Jersey",
        Description: "MyWhoosh AlUla Jersey Blue, an oasis to discover.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "5",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "38",
        thumbnail: "MyWhoosh Alula Jersey-Blue.png"
    },
    {
        ID: "89",
        Name: "MyWhoosh Alula Jersey",
        Description: "MyWhoosh AlUla Jersey Green, an oasis to discover.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "5",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "39",
        thumbnail: "MyWhoosh Alula Jersey-Green.png"
    },
    {
        ID: "90",
        Name: "MyWhoosh Alula Jersey",
        Description: "MyWhoosh AlUla Jersey Orange, an oasis to discover.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "5",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "40",
        thumbnail: "MyWhoosh Alula Jersey-Orange.png"
    },
    {
        ID: "91",
        Name: "MyWhoosh Alula Jersey",
        Description: "MyWhoosh AlUla Jersey Red, an oasis to discover.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "5",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "41",
        thumbnail: "MyWhoosh Alula Jersey-Red.png"
    },
    {
        ID: "92",
        Name: "MyWhoosh Alula Jersey",
        Description: "MyWhoosh AlUla Jersey White, an oasis to discover.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "5",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "42",
        thumbnail: "MyWhoosh Alula Jersey-White.png"
    },
    {
        ID: "93",
        Name: "MyWhoosh Alula Jersey",
        Description: "MyWhoosh AlUla Jersey Yellow, an oasis to discover.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "5",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "43",
        thumbnail: "MyWhoosh Alula Jersey-Yellow.png"
    },
    {
        ID: "94",
        Name: "MyWhoosh Blue Zephyr",
        Description: "MyWhoosh Blue Zephyr Jersey, breeze over the line.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "4",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "44",
        thumbnail: "MyWhoosh Blue Zephyr.png"
    },
    {
        ID: "95",
        Name: "MyWhoosh Camouflage",
        Description:"MyWhoosh Camouflage Jersey, no more hiding.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "4",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "45",
        thumbnail: "MyWhoosh Camouflage.png"
    },
    {
        ID: "101",
        Name: "MyWhoosh Flower Power",
        Description: "MyWhoosh Flower Power Jersey, sting like a bee.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "4",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "51",
        thumbnail: "MyWhoosh Flower Power.png"
    },
    {
        ID: "102",
        Name: "MyWhoosh Desert Flame",
        Description: "MyWhoosh Desert Flame Jersey, burn bright.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "4",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "52",
        thumbnail: "MyWhoosh Desert Flame.png"
    },
    {
        ID: "107",
        Name: "UAE Team ADQ Jersey",
        Description: "UAE Team ADQ 2024 Team Jersey. Ride with the best.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "6",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "57",
        thumbnail: "UAE Team ADQ Jersey.png"
    },
    {
        ID: "110",
        Name: "Ekoi Blue Jersey",
        Description:"Always in search of performance, Ekoi Blue Jersey provides optimum comfort and performance.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "6",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "60",
        thumbnail: "Ekoi Blue Jersey.png"
    },
    {
        ID: "111",
        Name: "Ekoi Violet Jersey",
        Description:"Always in search of performance, Ekoi Violet Jersey provides optimum comfort and performance.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "6",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "61",
        thumbnail: "Ekoi Violet Jersey.png"
    },
    {
        ID: "112",
        Name: "Ekoi Turquoise Jersey",
        Description:"Always in search of performance, Ekoi Turquoise Jersey provides optimum comfort and performance.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "6",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "62",
        thumbnail: "Ekoi Turquoise Jersey.png"
    },
    {
        ID: "113",
        Name: "Beastmode Jersey",
        Description:"Get caught up in beastmode and test your limits with the lightweight Beastmode Jersey.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "7",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "63",
        thumbnail: "Beastmode Jersey.png"
    },
    {
        ID: "138",
        Name: "UAE Team ADQ Jersey White",
        Description:"UAE Team ADQ Classic White Team Jersey. Ride with the best.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "8",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "88",
        thumbnail: "UAE Team ADQ Jersey White.png"
    },
    {
        ID: "139",
        Name: "Ventum xWYN Rose Jersey",
        Description:"Ventum xWYN Rose Jersey, a race-cut modern jersey for a slim, aerodynamic fit.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "9",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "89",
        thumbnail: "Ventum xWYN Rose Jersey.png"
    },
    {
        ID: "140",
        Name: "Ventum xWYN Sage Jersey",
        Description:"Ventum xWYN Sage Jersey, a race-cut modern jersey for a slim, aerodynamic fit.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "9",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "90",
        thumbnail: "Ventum xWYN Sage Jersey.png"
    },
    {
        ID: "141",
        Name: "MyWhoosh Team Red Jersey",
        Description: "MyWhoosh Team Red Jersey, ride with us!",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "10",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "91",
        thumbnail: "MyWhoosh Team Red Jersey.png"
    },
    {
        ID: "142",
        Name: "MyWhoosh Team Bronze Jersey",
        Description: "MyWhoosh Team Bronze Jersey, ride with us!",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "10",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "92",
        thumbnail: "MyWhoosh Team Bronze Jersey.png"
    },
    {
        ID: "143",
        Name: "Zycle Team Jersey",
        Description: "Zycle Team Jersey, Cycle with Zycle.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "11",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "93",
        thumbnail: "Zycle Team Jersey.png"
    },
    {
        ID: "144",
        Name: "Look Delko Classic Jersey",
        Description:"Look Delko Classic Jersey, timeless performance and design.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "12",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "94",
        thumbnail: "Look Delko Classic Jersey.png"
    },
    {
        ID: "145",
        Name: "Wahoo Le Col Team Jersey",
        Description:"Wahoo Le Col Team Jersey, designed to train and race indoors.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "13",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "95",
        thumbnail: "Wahoo Le Col Team Jersey.png"
    },
    {
        ID: "146",
        Name: "Van Rysel Jersey",
        Description:"Decathlon Van Rysel Jersey, designed for cyclists who want to push their limits.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "14",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "96",
        thumbnail: "Van Rysel Jersey.png"
    },
    {
        ID: "149",
        Name: "MyWhoosh Desert Sand",
        Description:"MyWhoosh Desert Sand Jersey, capture the relentless heat and beauty.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "17",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "99",
        thumbnail: "MyWhoosh Desert Sand.png"
    },
    {
        ID: "150",
        Name: "MyWhoosh Radiance",
        Description:"MyWhoosh Radiance Jersey, ride in the brilliance and serene glow.",
        itemType: "0",
        coins: "0",
        gems: "0",
        totalKilometers: "0.000000",
        UnlockingType: "E_Level",
        UnlockingValue: "18",
        jerseyType: "E_NormalJersey",
        GateType: "E_None",
        UI_Icon: "100",
        thumbnail: "MyWhoosh Radiance.png"
    },
];