<template>
    <div>
        <b-tabs nav-class="v-nav" pills vertical>
            <!-- Avatar Head -->
            <b-tab title="Head" active>
                <Product
                    @activateModalChild="activateModalChild"
                    :products="head"
                    :user-gems="userGems"
                    :user-coins="userCoins"
                />
            </b-tab>

            <!-- Avatar Hair -->
            <b-tab title="Hair">
                <Product
                    @activateModalChild="activateModalChild"
                    :products="hair"
                    :user-gems="userGems"
                    :user-coins="userCoins"
                />
            </b-tab>

            <!-- Avatar Facial Hair -->
            <b-tab title="Facial Hair">
                <Product
                    @activateModalChild="activateModalChild"
                    :products="facialHair"
                    :user-gems="userGems"
                    :user-coins="userCoins"
                />
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import Product from "./Product.vue";

const avatarData = [];

export default {
    name: "Avatar",
    props: ["head", "hair", "facialHair", "userGems", "userCoins"],

    components: {
        Product,
    },

    data() {
        return {
            avatar: avatarData,
        };
    },
    methods: {
      activateModalChild(tabIndex){
        this.$emit('activateModalParent',tabIndex)
      },
        defineMethodName() {
            //do stuff here
        },
    },
    created() {},
};
</script>

<style scoped></style>
