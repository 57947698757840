import Vue from 'vue'
import Vuex from 'vuex'
import oauth from './modules/oauth'
import common from './modules/common'
import auth from './modules/auth'
import event from './modules/event'
import user from './modules/user'
import page from './modules/page'
import form from './modules/form'
import contact from './modules/contact'
import ride from './modules/ride'
import gameshop from './modules/gameshop'


Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({

    state: {
        errors: []
    },

    getters: {
        errors: state => state.errors
    },

    mutations: {
        SET_ERRORS(state, errors) {
            state.errors = errors;
        }
    },

    actions: {},

    modules: {
        common,
        oauth,
        auth,
        event,
        user,
        page,
        form,
        contact,
        ride,
        gameshop
    },
    strict: debug,
})
