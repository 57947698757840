<template>
    <div>
        <b-tabs nav-class="v-nav" pills vertical>
            <!-- Bike Frame -->
            <b-tab title="Frameset" active>
                <Product
                    @activateModalChild="activateModalChild"
                    :user-gems="userGems"
                    :user-coins="userCoins"
                    :products="frames"
                />
            </b-tab>

            <!-- Bike Wheel -->
            <b-tab title="Wheel">
                <Product
                    @activateModalChild="activateModalChild"
                    :products="wheels"
                    :user-gems="userGems"
                    :user-coins="userCoins"
                />
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import Product from "./Product.vue";
// import Schema from "./data.json";

const bikeData = [];

export default {
    name: "Bike",
    props: ["frames", "wheels", "userGems", "userCoins"],

    components: {
        Product,
    },

    data() {
        return {
            bike: bikeData,
        };
    },
    methods: {
      activateModalChild(tabIndex){
        this.$emit('activateModalParent',tabIndex)
      },
        defineMethodName() {
            //do stuff here
        },
    },
    created() {},
};
</script>

<style scoped></style>
