<template>
  <div>
    
    <div class="btn-wrpr" v-if="new_version === true">
      <div class="container">
        
        <a target="_blank" v-if="links.technical_guide" :href="links.technical_guide">
          TECHNICAL GUIDE
        </a>
        
        <a target="_blank" v-if="links.ruleset" :href="links.ruleset">
          RULESET
        </a>
        
        <a
          target="_blank"
          v-if="links.requirements"
          data-target="#requirementsModal"
          data-toggle="modal"
          style="cursor: pointer;">
          REQUIREMENTS
        </a>

        <a target="_blank" :href="getVerifiedUrl()">
          PRE VERIFICATION FORM
        </a>

        <a target="_blank" v-if="links.course_details" :href="links.course_details" class="btn-universal">
          COURSE DETAILS
        </a>

        <EventRequirements
          v-if="links.requirements"
          :requirements="links.requirements"
        />
      </div>
    </div>
    
    <ul class="list-inline mt-3 text-left events-btns" v-else>
      <li class="list-inline-item" v-if="links.guideline">
        <a target="_blank" :href="links.guideline" class="btn-universal">
          TECHNICAL GUIDE
        </a>
      </li>

      <li class="list-inline-item" v-if="links.ruleset">
        <a target="_blank" :href="links.ruleset" class="btn-universal">
          RULESET
        </a>
      </li>

      <li v-if="links.requirements" class="list-inline-item">
        <button
          data-target="#requirementsModal"
          class="btn-universal border-0"
          data-toggle="modal"
          style="cursor: pointer;">
          REQUIREMENTS
        </button>
        <EventRequirements :requirements="links.requirements" />
      </li>

      <a target="_blank" :href="getVerifiedUrl()">
        PRE VERIFICATION FORM
      </a>

      <li class="list-inline-item" v-if="links.course_details">
        <a target="_blank" :href="links.course_details" class="btn-universal">
          COURSE DETAILS
        </a>
      </li>
    </ul>
    
  </div>
</template>

<script>
import { commonMixin } from "@/mixins/commonMixin";
import EventRequirements from "./EventRequirements";

export default {
  name: "EventLinks",
  props: ["links", "new_version"],
  mixins: [commonMixin],
  components: {
    EventRequirements,
  },
  
  methods: {
    getVerifiedUrl() {
      return this.preVerificationUrl(this.$route.params.id);
    },
  },
};
</script>

<style scoped>
a:hover,
button:hover {
  color: #fff !important;
}
</style>
