<template>
  <div class="row">
    <div class="col-xl-6 col-lg-7 col-md-9 mx-auto">
      <h3>Change password</h3>
      <form @submit.prevent="submitHandler()" autocomplete="off">
        <div class="form-group">
          <label for="password">New Password</label>
          <input type="password" v-model="form.password" class="form-control" id="password"
            placeholder="Enter new password" autocomplete="chrome-off" />
          <div v-if="submitted && getError('password')" class="text-danger text-left">Password must be at least 6
            characters
          </div>
        </div>
        <div class="form-group">
          <label for="password">Confirm New Password</label>
          <input type="password" v-model="form.repeatPassword" class="form-control" id="cpassword"
            placeholder="Repeat new password" autocomplete="chrome-off" />
          <div v-if="submitted && getError('repeatPassword')" class="text-danger text-left">Password doesn't match
          </div>
        </div>
        <div style="clear: both;"></div>
        <button type="submit" class="btn-universal cursor-pointer">Change Password</button>

      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { minLength, required, sameAs } from 'vuelidate/lib/validators'

export default {
  name: 'ChangePassword',

  data() {
    return {
      form: {},
      submitted: false,
    }
  },

  validations: {
    form: {
      password: { required, minLength: minLength(6) },
      repeatPassword: {
        sameAsPassword: sameAs('password')
      }
    }
  },

  computed: {
    ...mapGetters("auth", ["user", "loggedIn"]),
    ...mapGetters(['errors']),
  },

  mounted() {
    document.body.classList.add('contact');
  },

  destroyed() {
    document.body.classList.remove('contact')
  },

  methods: {
    async submitHandler() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) return;
      await this.$store.dispatch('user/updatePassword', this.form).then((res) => {
        
        if (res || res.status) {
          this.$store.dispatch("auth/logout");
          this.$router.push({ name: "events" });
          return;
        }
      });
      this.submitted = false;
      this.form.password = null;
      this.form.repeatPassword = null;
    },

    getError(field) {
      return this.$v.form[field].$invalid;
    },
  },
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.text-danger {
  font-size: 12px;
}
</style>
