<template>
  <div>
    <header-component />
    <router-view></router-view>
    <footer-component v-if="displayFooter" />
  </div>
</template>
<script>
import HeaderComponent from "@/components/shared/HeaderComponent";
import FooterComponent from "@/components/shared/FooterComponent";
//import "kothing-editor/lib/assets/css/editor-contents.css";

export default {
  name: "FrontLayout",
  components: {
    HeaderComponent,
    FooterComponent,
  },

  data() {
    return {
      displayFooter: true,
    };
  },

  mounted() {
    const $query = this.$route.query;
    if ($query.d && $query.d === "m") {
      this.displayFooter = false;
      localStorage.setItem("d", "m");
    }

    if (localStorage.getItem("avatar")) {
      this.$store.commit("user/SET_AVATAR", localStorage.getItem("avatar"));
    }
  },
};
</script>
