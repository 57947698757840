export const ShoesSet = [
    {
        ID: "1",
        Name: "MyWhoosh Lace Up Explore Shoe",
        Description:
            "MyWhoosh Lace Up Explore Shoes feature a balance between stiffness for efficient power transfer and flexibility for prolonged comfort.",
        RowId: "",
        itemType: "0",
        coins: "0",
        gems: "0",
        purchasingType: "E_Coins",
        totalKilometers: "0.000000",
        itemUnlockingType: "E_Base",
        unlockingValue: "1",
        MeshId: "1",
        AtlasId: "1",
        U_transform: "0",
        V_transform: "1",
        U_offset: "0.125000",
        V_offset: "0.125000",
        Gender: "E_Male",
        UI_Icon: "0",
        thumbnail: "MyWhoosh Lace Up Explore Shoe - Black.png"
    },
    {
        ID: "2",
        Name: "MyWhoosh Lace Up Explore Shoe",
        Description:
            "MyWhoosh Lace Up Explore Shoes feature a balance between stiffness for efficient power transfer and flexibility for prolonged comfort.",
        RowId: "",
        itemType: "0",
        coins: "0",
        gems: "0",
        purchasingType: "E_Coins",
        totalKilometers: "0.000000",
        itemUnlockingType: "E_Base",
        unlockingValue: "2",
        MeshId: "1",
        AtlasId: "1",
        U_transform: "0",
        V_transform: "0",
        U_offset: "0.125000",
        V_offset: "0.125000",
        Gender: "E_Male",
        UI_Icon: "1",
        thumbnail: "MyWhoosh Lace Up Explore Shoe - White.png"
    },
    {
        ID: "3",
        Name: "MyWhoosh Race Shoe",
        Description:
            "MyWhoosh Race Shoes are crafted to optimize performance, stiffness and comfort for cyclists during intense efforts.",
        RowId: "",
        itemType: "0",
        coins: "0",
        gems: "0",
        purchasingType: "E_Coins",
        totalKilometers: "0.000000",
        itemUnlockingType: "E_Base",
        unlockingValue: "4",
        MeshId: "1",
        AtlasId: "1",
        U_transform: "0",
        V_transform: "3",
        U_offset: "0.125000",
        V_offset: "0.125000",
        Gender: "E_Male",
        UI_Icon: "2",
        thumbnail: "MyWhoosh Race Shoe - Black.png"
    },
    {
        ID: "4",
        Name: "MyWhoosh Race Shoe",
        Description:
            "MyWhoosh Race Shoes are crafted to optimize performance, stiffness and comfort for cyclists during intense efforts.",
        RowId: "",
        itemType: "0",
        coins: "0",
        gems: "0",
        purchasingType: "E_Coins",
        totalKilometers: "0.000000",
        itemUnlockingType: "E_Base",
        unlockingValue: "6",
        MeshId: "1",
        AtlasId: "1",
        U_transform: "0",
        V_transform: "2",
        U_offset: "0.125000",
        V_offset: "0.125000",
        Gender: "E_Male",
        UI_Icon: "3",
        thumbnail: "MyWhoosh Race Shoe - White.png"
    },
    {
        ID: "5",
        Name: "MyWhoosh Race Shoe",
        Description:
            "MyWhoosh Race Shoes are crafted to optimize performance, stiffness and comfort for cyclists during intense efforts.",
        RowId: "",
        itemType: "0",
        coins: "0",
        gems: "0",
        purchasingType: "E_Coins",
        totalKilometers: "0.000000",
        itemUnlockingType: "E_Base",
        unlockingValue: "0",
        MeshId: "1",
        AtlasId: "1",
        U_transform: "0",
        V_transform: "4",
        U_offset: "0.125000",
        V_offset: "0.125000",
        Gender: "E_Male",
        UI_Icon: "4",
        thumbnail: "MyWhoosh Race Shoe - Yellow.png"
    },
    {
        ID: "11",
        Name: "Ekoi R4 Road Shoe",
        Description:
            "If you seek the perfect pedal stroke in a subtle race shoe offering exceptional comfort and high-performance, then the new Ekoi R4 road shoes have been designed for you.",
        RowId: "",
        itemType: "0",
        coins: "0",
        gems: "0",
        purchasingType: "E_Coins",
        totalKilometers: "0.000000",
        itemUnlockingType: "E_Base",
        unlockingValue: "0",
        MeshId: "3",
        AtlasId: "3",
        U_transform: "0",
        V_transform: "0",
        U_offset: "0.125000",
        V_offset: "0.125000",
        Gender: "E_Male",
        UI_Icon: "5",
        thumbnail: "Ekoi R4 Road Shoe - Blue.png"
    },
    {
        ID: "12",
        Name: "Ekoi Tri R4 Shoe",
        Description:
            "The new Ekoi R4 Tri shoe is aimed at regular triathletes looking for lightweight, comfortable, high-performance equipment maximizing drying and optimizing transition times.",
        RowId: "",
        itemType: "0",
        coins: "0",
        gems: "0",
        purchasingType: "E_Coins",
        totalKilometers: "0.000000",
        itemUnlockingType: "E_Base",
        unlockingValue: "0",
        MeshId: "3",
        AtlasId: "3",
        U_transform: "0",
        V_transform: "1",
        U_offset: "0.125000",
        V_offset: "0.125000",
        Gender: "E_Male",
        UI_Icon: "6",
        thumbnail: "Ekoi R4 Tri Shoe.png"
    },
    {
        ID: "15",
        Name: "VanRyselRCRPro_Black",
        Description:
            "The Van Rysel RCR Pro Shoe is characterised by a comfy fit and a highly rigid, ventilated 100% carbon sole. Developed with and chosen by pros.",
        RowId: "",
        itemType: "0",
        coins: "0",
        gems: "0",
        purchasingType: "E_Coins",
        totalKilometers: "0.000000",
        itemUnlockingType: "E_Base",
        unlockingValue: "0",
        MeshId: "5",
        AtlasId: "3",
        U_transform: "0",
        V_transform: "0",
        U_offset: "0.125000",
        V_offset: "0.125000",
        Gender: "E_Male",
        UI_Icon: "7",
        thumbnail: "Van Rysel RCR Pro Shoe - Black.png"
    },
    {
        ID: "16",
        Name: "VanRyselRCRPro_White",
        Description:
            "The Van Rysel RCR Pro Shoe is characterised by a comfy fit and a highly rigid, ventilated 100% carbon sole. Developed with and chosen by pros.",
        RowId: "",
        itemType: "0",
        coins: "0",
        gems: "0",
        purchasingType: "E_Coins",
        totalKilometers: "0.000000",
        itemUnlockingType: "E_Base",
        unlockingValue: "0",
        MeshId: "5",
        AtlasId: "3",
        U_transform: "0",
        V_transform: "1",
        U_offset: "0.125000",
        V_offset: "0.125000",
        Gender: "E_Male",
        UI_Icon: "8",
        thumbnail: "Van Rysel RCR Pro Shoe - White.png"
    },
    {
        ID: "17",
        Name: "VELOKICKS Season 1",
        Description:
            "Designed to be bold,  these shoes were created so that the only limit on their expression is you.",
        RowId: "",
        itemType: "0",
        coins: "0",
        gems: "0",
        purchasingType: "E_Coins",
        totalKilometers: "0.000000",
        itemUnlockingType: "E_Base",
        unlockingValue: "0",
        MeshId: "7",
        AtlasId: "3",
        U_transform: "0",
        V_transform: "0",
        U_offset: "0.125000",
        V_offset: "0.125000",
        Gender: "E_Male",
        UI_Icon: "9",
        thumbnail: "VELOKICKS Season 1.png"
    },
    {
        ID: "18",
        Name: "VELOKICKS Season 2",
        Description:
            "Designed to be bold,  these shoes were created so that the only limit on their expression is you.",
        RowId: "",
        itemType: "0",
        coins: "0",
        gems: "0",
        purchasingType: "E_Coins",
        totalKilometers: "0.000000",
        itemUnlockingType: "E_Base",
        unlockingValue: "0",
        MeshId: "7",
        AtlasId: "3",
        U_transform: "0",
        V_transform: "1",
        U_offset: "0.125000",
        V_offset: "0.125000",
        Gender: "E_Male",
        UI_Icon: "10",
        thumbnail: "VELOKICKS Season 2.png"
    },
    {
        ID: "23",
        Name: "MyWhoosh Radiance Shoe",
        Description:
            "MyWhoosh Radiance Shoe, style and striking design, with the fit and functionality of a premium race shoe.",
        RowId: "",
        itemType: "0",
        coins: "0",
        gems: "0",
        purchasingType: "E_Coins",
        totalKilometers: "0.000000",
        itemUnlockingType: "E_Base",
        unlockingValue: "0",
        MeshId: "9",
        AtlasId: "3",
        U_transform: "0",
        V_transform: "0",
        U_offset: "0.125000",
        V_offset: "0.125000",
        Gender: "E_Male",
        UI_Icon: "11",
        thumbnail: "MyWhoosh Radiance Shoe.png"
    },
    {
        ID: "24",
        Name: "MyWhoosh Desert Sand Shoe",
        Description:
            "MyWhoosh Desert Sand Shoe, style and striking design, with the fit and functionality of a premium race shoe.",
        RowId: "",
        itemType: "0",
        coins: "0",
        gems: "0",
        purchasingType: "E_Coins",
        totalKilometers: "0.000000",
        itemUnlockingType: "E_Base",
        unlockingValue: "0",
        MeshId: "10",
        AtlasId: "3",
        U_transform: "0",
        V_transform: "0",
        U_offset: "0.125000",
        V_offset: "0.125000",
        Gender: "E_Male",
        UI_Icon: "12",
        thumbnail: "MyWhoosh Desert Sand Shoe.png"
    },
];
