export const HeadSet = [
    {
        ID: 0,
        Name: "Skin Tone 1",
        Description:
            "Choose your avatar to personalize your virtual representation in MyWhoosh. When others see your avatar, they associate it with you.",
        UiIconId: "0",
        U_transform: "0",
        V_transform: "1",
        U_offset: "0.250000",
        V_offset: "0.250000",
        ColorValues:
            "((Color=(R=0.321854,G=0.588542,B=0.283423,A=1.000000),SkinTone=0.000000),(Color=(R=0.390625,G=0.281524,B=0.188604,A=1.000000),SkinTone=0.000000),(Color=(R=0.078125,G=0.057165,B=0.022948,A=0.940000),SkinTone=0.000000))",
        UnlockingType: "E_Base",
    },
    {
        ID: "1",
        Name: "Skin Tone 2",
        Description:
            "Choose your avatar to personalize your virtual representation in MyWhoosh. When others see your avatar, they associate it with you.",
        UiIconId: "1",
        U_transform: "0",
        V_transform: "0",
        U_offset: "0.250000",
        V_offset: "0.250000",
        ColorValues:
            "((Color=(R=0.395833,G=0.235686,B=0.035596,A=0.770000),SkinTone=0.000000),(Color=(R=0.234375,G=0.144892,B=0.015559,A=0.987000),SkinTone=0.000000),(Color=(R=0.364583,G=0.266770,B=0.107091,A=1.000000),SkinTone=0.000000))",
        UnlockingType: "E_Base",
    },
    {
        ID: "2",
        Name: "Skin Tone 3",
        Description:
            "Choose your avatar to personalize your virtual representation in MyWhoosh. When others see your avatar, they associate it with you.",
        UiIconId: "2",
        U_transform: "1",
        V_transform: "0",
        U_offset: "0.250000",
        V_offset: "0.250000",
        ColorValues:
            "((Color=(R=0.321854,G=0.588542,B=0.283423,A=1.000000),SkinTone=0.000000),(Color=(R=0.390625,G=0.281524,B=0.188604,A=1.000000),SkinTone=0.000000),(Color=(R=0.078125,G=0.057165,B=0.022948,A=0.940000),SkinTone=0.000000))",
        UnlockingType: "E_Base",
    },
    {
        ID: "3",
        Name: "Skin Tone 4",
        Description:
            "Choose your avatar to personalize your virtual representation in MyWhoosh. When others see your avatar, they associate it with you.",
        UiIconId: "3",
        U_transform: "1",
        V_transform: "1",
        U_offset: "0.250000",
        V_offset: "0.250000",
        ColorValues:
            "((Color=(R=0.332324,G=0.515625,B=0.056530,A=0.995000),SkinTone=0.000000),(Color=(R=0.011030,G=0.234375,B=0.175340,A=1.000000),SkinTone=0.000000),(Color=(R=0.364583,G=0.178813,B=0.000000,A=1.000000),SkinTone=0.000000))",
        UnlockingType: "E_Base",
    },
    {
        ID: "4",
        Name: "Pogacar",
        Description: "A custom avatar, just for Pogacar",
        UiIconId: "4",
        U_transform: "0",
        V_transform: "0",
        U_offset: "0.250000",
        V_offset: "0.250000",
        ColorValues:
            "((Color=(R=0.395833,G=0.235686,B=0.035596,A=0.770000),SkinTone=0.000000),(Color=(R=0.234375,G=0.144892,B=0.015559,A=0.987000),SkinTone=0.000000),(Color=(R=0.364583,G=0.266770,B=0.107091,A=1.000000),SkinTone=0.000000))",
        UnlockingType: "E_CustomAvatar",
    },
];
