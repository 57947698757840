import moment from "moment";
export const overviewMixin = {
    methods:{
        async fetchBarChartData(durationType , dataType) {

            const payload = {
                type: durationType,
                ...this.getDurationCollection()
            }

            const response = await this.$store.dispatch('ride/over_view_bar_chart_data', payload);

            if(response.success){
                return this.formatResponseForBarChart(durationType, dataType, response.data);
            }

            return false;
        },

        formatResponseForBarChart(durationType, dataType, data = []) {
            const labels = data.map(item => {

                if(durationType == 'week') {
                    return moment().date(item.date).format('DD/MM/YYYY')
                }else if(durationType == 'month'){
                    return 'W'+item.date
                }else if(durationType == 'year'){
                    return moment().month(item.date - 1).format("MMM");
                }


                return new Date().getFullYear()

            })

            const datasets = data.map(item => dataType === 'elevation' ? item.elevation : item.distance);

            return {
                labels: labels,
                datasets: [{
                    label: dataType === 'elevation' ? 'Elevation' : 'Distance',
                    backgroundColor: dataType === 'elevation' ? '#fcb813' : '#357fdd',
                    data: datasets,
                    borderColor: '#ffffff',
                    borderWidth: 2,
                    barThickness: 30,
                    maxBarThickness: 50,
                }],
            };
        },

        getDurationCollection(){
            return {
                year: new Date().getFullYear(),
                month: new Date().getMonth() + 1,
                //  week: this.getWeekNumber(),
              //  week: moment(new Date()).week() - moment(new Date()).startOf('month').week() + 1,
                week:  moment().startOf('week').diff(moment().startOf('month').startOf('week'), 'weeks') + 1,
            }
        },

        getWeekNumber() {
            const date = new Date();
            const oneJan = new Date(date.getFullYear(), 0, 1);
            const milliSecsInDay = 86400000;
            const numberOfDays = Math.ceil((date - oneJan) / milliSecsInDay);
            return Math.ceil((date.getDay() + 1 + numberOfDays) / 7);
        }
    }
}