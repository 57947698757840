export const GlassesSet = [
    {
        "Name": "MyWhoosh Half-Frame - Black",
        "ID": 1,
        "Description": "MyWhoosh Half-frame provide a modern and minimalist aesthetic, with a lightweight construction and unobstructed Vision.",
        "itemType": 0,
        "coins": 0,
        "gems": 0,
        "totalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 1,
        "MeshId": 1,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 1,
        thumbnail: "MyWhoosh Half-Frame - Black.png"
    },
    {
        "Name": "MyWhoosh Full-Frame - Black",
        "ID": 2,
        "Description": "MyWhoosh Full-Frame feature a lightweight and aerodynamic design, offering a comfortable fit and streamlined design.",
        "itemType": 0,
        "coins": 0,
        "gems": 0,
        "totalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 1,
        "MeshId": 2,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 2,
        thumbnail: "MyWhoosh Full-Frame - Black.png"
    },
    {
        "Name": "MyWhoosh Full-Frame - Yellow",
        "ID": 3,
        "Description": "MyWhoosh Full-Frame feature a lightweight and aerodynamic design, offering a comfortable fit and streamlined design.",
        "itemType": 0,
        "coins": 0,
        "gems": 0,
        "totalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 1,
        "MeshId": 2,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 1,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 3,
        thumbnail: "MyWhoosh Full-Frame - Yellow.png"
    },
    {
        "Name": "MyWhoosh Casual",
        "ID": 4,
        "Description": "MyWhoosh Casual prioritize comfort, style, and practicality with a blend of functionality and style.",
        "itemType": 0,
        "coins": 0,
        "gems": 0,
        "totalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 2,
        "MeshId": 3,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 4,
        thumbnail: "MyWhoosh Casual.png"
    },
    {
        "Name": "Ekoi Premium 80 Revo Red",
        "ID": 5,
        "Description": "Ekoi Premium 80 designed with aerodynamics and airflow in mind to produce a performance eyewear product for the most intense rides.",
        "itemType": 0,
        "coins": 0,
        "gems": 0,
        "totalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 3,
        "MeshId": 4,
        "AtlasId": 3,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 5,
        thumbnail: "Ekoi Premium 80 Revo Red.png"
    },
    {
        "Name": "Ekoi Premium 90 Saphire",
        "ID": 6,
        "Description": "Ekoi Premium 90 designed for the most demanding competitive riders looking for a 100% performance product, they have an ultra-stable frame and wrap-around, hyper-protective lens.",
        "itemType": 0,
        "coins": 0,
        "gems": 0,
        "totalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 3,
        "MeshId": 5,
        "AtlasId": 3,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 6,
        thumbnail: "Ekoi Premium 90 Saphire.png"
    },
    {
        "Name": "Van Rysel Perf 900 Light",
        "ID": 7,
        "Description": "Lightweight, ventilated, and with a sporty look, the 900 Perf Light glasses fall into the featherweight category.",
        "itemType": 0,
        "coins": 0,
        "gems": 0,
        "totalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 4,
        "MeshId": 6,
        "AtlasId": 2,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 7,
        thumbnail: "Van Rysel Perf 900 Light.png"
    },
    {
        "Name": "SCICON Aeroscope Performance",
        "ID": 8,
        "Description": "The Aeroscope Performance sunglasses extends beyond the expected and delivers tailored precision for the ultimate sports experience.",
        "itemType": 0,
        "coins": 0,
        "gems": 0,
        "totalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 5,
        "MeshId": 7,
        "AtlasId": 2,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 8,
        thumbnail: "SCICON Aeroscope Performance.png"
    },
    {
        "Name": "SCICON Aeroshade Kunken",
        "ID": 9,
        "Description": "Developed with vision enhancing engineering, the Aeroshade Kunken Sport Performance Sunglasses with a cylindrical shield lens will improve your vision and performance.",
        "itemType": 0,
        "coins": 0,
        "gems": 0,
        "totalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 6,
        "MeshId": 8,
        "AtlasId": 2,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.125,
        "V_offset": 0.125,
        "UI_Icon": 9,
        thumbnail: "SCICON Aeroshade Kunken.png"
    }
]