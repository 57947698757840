<template>
  <div>


    <b-row v-if="products.length > 0">
      <b-col
          cols="6"
          sm="6"
          md="6"
          lg="4"
          v-for="(item, index) in products"
          :key="index"
      >
        <div
            :id="item?.id"
            class="card"
            :class="{ selected: selectedProduct === index }"
            @click="selectShopItem(index)"
        >
          <div class="card-body">
            <h3 class="card-title">
              {{ item?.Name }}
            </h3>

            <div class="card-image">
              <img
                  :src="item?.thumbnail"
                  :alt="item?.Name"
                  @error="setFallbackImage($event)"
              />
            </div>

            <div class="card-price">
              <img
                  src="/assets/images/shop/icons/coin.png"
                  alt="Coin"
              />
              <span>{{ item?.UnlockValue }}</span>
            </div>

            <div class="card-cta">
              <b-button
                  class="btn btn-warning"
                  @click.stop="quickView(index)"
              >
                <img
                    src="/assets/images/shop/icons/view.png"
                    alt="Icon"
                />
                <span>Quick View</span>
              </b-button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row v-else>
      <b-col cols="12"><br>Sorry, no product available here at this moment.</b-col>
    </b-row>

    <!-- Product Details Popup Model -->
    <div v-if="showModal" class="product-detail">
      <div
          class="product-content"
          :class="{ 'animate-rtl': showModal === true }"
      >
        <button class="btn-close" type="button" @click="closeModal">
          &nbsp;
        </button>

        <!-- Account Topup Action -->
        <div class="topup">
          <div class="gem">
            <img
                src="/assets/images/shop/icons/gem.png"
                alt="Gem Icon"
            />
            <span>{{ userGems }}</span>
            <b-button
                class="btn-add"
                type="button"
                @click="activateChildTab(1)"
            >+</b-button
            >
          </div>

          <div class="coin">
            <img
                src="/assets/images/shop/icons/coin.png"
                alt="Coin Icon"
            />
            <span>{{ userCoins }}</span>
            <b-button
                class="btn-add"
                type="button"
                @click="activateChildTab(0)"
            >+</b-button
            >
          </div>
        </div>

        <div class="product-body">
          <h3 class="product-title">
            <button
                class="btn-back"
                type="button"
                @click="closeModal"
            >
              &nbsp;
            </button>

            {{ selectedProductDetail?.Name }}
          </h3>

          <div class="product-image">
            <img
                :src="selectedProductDetail?.thumbnail"
                :alt="selectedProductDetail?.Name"
            />
          </div>

          <div
              v-if="[0, 1].includes(selectedProductDetail.Type)"
              class="d-flex align-items-center justify-content-between mb-3"
          >
            <span class="progress-title">AERO</span>
            <b-progress
                class="w-100"
                :value="selectedProductDetail?.Aerodynamics"
                :max="100"
                variant="info"
            ></b-progress>
          </div>

          <div
              v-if="[0, 1].includes(selectedProductDetail.Type)"
              class="d-flex align-items-center justify-content-between mb-3"
          >
            <span class="progress-title">WEIGHT</span>
            <b-progress
                class="w-100"
                :value="selectedProductDetail?.Weight"
                :max="100"
                variant="dark"
            ></b-progress>
          </div>

          <p class="product-description">
            {{ selectedProductDetail?.Description }}
          </p>

          <p class="product-price">
            <img
                src="/assets/images/shop/icons/coin.png"
                alt="Coin Icon"
            />
            <span>{{ selectedProductDetail.UnlockValue }}</span>
          </p>

          <div>
            <p
                v-if="showPurchaseError"
                class="alert alert-danger text-center"
            >
              <strong>{{ showPurchaseError }}</strong>
            </p>

            <b-button
                v-else-if="showPurchaseBtn && !showPurchaseError"
                class="btn btn-buy"
                variant="dark"
                @click="purchaseItem"
            >
              Buy Now
            </b-button>

            <p
                v-if="showPurchaseMessage"
                class="text-bg-info mt-3 text-center"
            >
              <strong>{{ showPurchaseMessage }}</strong>
            </p>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "Product",
  props: ["products", "userGems", "userCoins"],

  data() {
    return {
      selectedProduct: null,
      showPurchaseError: null,
      showPurchaseMessage: null,
      showModal: false,
      showPurchaseBtn: true,
    };
  },

  computed: {
    selectedProductDetail() {
      return this.products[this.selectedProduct] || {};
    },
  },

  methods: {
    async purchaseItem() {
      try {
        const product = this.products[this.selectedProduct];

        const res = await this.$store.dispatch(
            "gameshop/purchaseItem",
            {
              item_id: product.Id,
              Type: product.Type,
            }
        );

        if (res.status) {
          this.showPurchaseBtn = false;
          this.showPurchaseMessage = res.message || "Purchase success.";
         // this.triggerCelebration(); // Trigger the confetti celebration
          this.closeModal(); // Close the modal
          // Delay to allow the confetti celebration to run for a bit
          setTimeout(() => {

            Swal.fire({
              icon: "success",
              title: "Purchase Successful!",
              text: "Congratulations on your purchase!",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              customClass: {
                icon: 'custom-swal-icon' // Add your custom class here
              }
            }).then(() => {
              location.reload(); // Reload the page on OK click
            });

          }, 700); // Adjust this timing to match the duration of your confetti animation

        } else {
          this.showPurchaseMessage = res.message || "Purchase failed.";
        }
      } catch (error) {
        console.error("Error purchasing item:", error);
        this.showPurchaseMessage =
            "An error occurred while processing your request.";
      }
    },

    triggerCelebration() {

      const confettiContainer = document.getElementById('confetti-container');
      const confettiColors = ["#FFC700", "#FF0000", "#2E3192", "#662D91", "#00AEEF"];
      for (let i = 0; i < 150; i++) {
        const confetti = document.createElement('div');
        confetti.classList.add('confetti');
        confetti.style.left = Math.random() * 100 + "vw";
        confetti.style.animationDuration = Math.random() * 3 + 2 + "s";
        confetti.style.backgroundColor = confettiColors[Math.floor(Math.random() * confettiColors.length)];
        const size = Math.random() * 10 + 5 + "px";
        confetti.style.width = size;
        confetti.style.height = size;
        confettiContainer.appendChild(confetti);

        confetti.addEventListener('animationend', () => {
          confetti.remove();
        });


      }
    },

    activateChildTab(tabIndex) {
      this.$emit("activateModalChild", tabIndex);
      this.showModal = false;
    },

    setFallbackImage(event) {
      event.target.src = "/assets/images/shop/icons/placeholder.png";
    },

    async quickView(index) {
      try {
        const selectedProduct = this.products[index];

        const res = await this.$store.dispatch(
            "gameshop/checkUserPurchaseItem",
            {
              item_id: selectedProduct.Id,
              item_price: selectedProduct.UnlockValue,
            }
        );

        this.selectedProduct = index;
        this.showModal = true;
        this.toggleBodyScroll(true);
        this.showPurchaseError = null;
        this.showPurchaseMessage = null;
        this.userGems = res?.data?.user_gems;
        this.userCoins = res?.data?.user_coins;

        if (res?.data?.error) {
          this.showPurchaseBtn = false;
          this.showPurchaseError = res.message;
        } else {
          this.showPurchaseBtn = true;
        }
      } catch (error) {
        console.error("Error checking user purchase item:", error);
        this.showPurchaseError =
            "An error occurred while processing your request.";
        this.showPurchaseBtn = false;
      }
    },

    closeModal() {
      this.showModal = false;
      this.selectedProduct = null;
      this.toggleBodyScroll(false);
    },

    toggleBodyScroll(disableScroll) {
      if (disableScroll) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    },

    redirectToStripe() {
      // Replace with your actual Stripe payment URL or integration
      // window.location.href = "https://stripe.com/pay";
    },
  },

  beforeDestroy() {
    // Ensure to clean up in case the component is destroyed while the modal is open
    this.toggleBodyScroll(false);
  },
};
</script>


<style scoped>
.swal2-icon{
  width: 5em;
  height: 5em;
}
.btn {
    align-items: center;
    background-color: #fcb913;
    border-radius: 6px;
    color: #36425b;
    display: flex;
    font-family: "Roboto";
    font-size: 20px;
    font-weight: 400;
    height: 38px;
    justify-content: center;
    line-height: 24px;
    margin: initial;
    padding: 6px 12px;
    width: 210px;
}

.btn:hover,
.btn:focus,
.btn-warning:not(:disabled):not(.disabled):active {
    border-color: #36425b;
    background-color: #36425b;
    color: #ffffff;
}

.btn-warning:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem rgba(80, 80, 80, 0.5);
}

.btn img,
.btn img {
    transition: all ease-in-out 0.3s;
}

.btn:hover img,
.btn:focus img {
    filter: brightness(0) invert(1);
}

.btn img + span {
    margin-left: 13px;
}

.card {
    border: 0;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    /* height: 178px; */
    margin-bottom: 30px;
    overflow: hidden;
    padding: 0;
    position: relative;
}

.card .card-body {
    padding: 16px;
}

.card .card-title {
    color: #23292e;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
}

.card .card-image {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100px;
    margin: 15px 0;
}

.card .card-image img {
    height: 100px;
    object-fit: contain;
    object-position: center;
}

.card .card-image img[src*="placeholder"] {
    height: 50px;
}

.card .card-price {
    align-items: center;
    color: #36425b;
    display: flex;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    justify-content: center;
    text-transform: uppercase;
}

.card .card-price img + span {
    margin-left: 8px;
}

.card .card-cta {
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    /* opacity: 0; */
    transition: all ease-in-out 0.3s;
}

.card:hover .card-cta,
.card.selected .card-cta {
    background-color: rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(5px);
    opacity: 1;
    z-index: 9;
}

.card .card-cta > .btn {
    transition: all ease-in-out 0.3s;
    transform: translateY(200px);
}

.card:hover .card-cta > .btn,
.card.selected .card-cta > .btn {
    transform: translateY(0);
}

.product-detail {
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    color: #23292e;
    display: block;
    height: calc(100vh - 281px);
    height: 100vh;
    left: 0;
    position: fixed;
    right: 0;
    width: 100vw;
    z-index: 9999;
}

.product-detail .btn-back {
    display: none;
}

.product-detail .btn-close {
    background-color: transparent;
    background-image: url("/assets/images/shop/icons/close.png");
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    cursor: pointer;
    display: flex;
    height: 24px;
    margin-left: auto;
    outline: none;
    width: 24px;
}

.product-detail .product-content {
    background: #ffffff;
    bottom: 0;
    height: 100vh;
    max-height: 100vh;
    left: auto;
    max-width: 500px;
    overflow: hidden;
    padding: 20px 30px;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
}

.product-detail .topup {
    align-items: center;
    border-bottom: 1px solid #e2e6eb;
    margin: 15px 0;
    padding: 16px 0;
    position: relative;
}

.topup .gem,
.topup .coin {
    width: auto;
}

.product-detail .product-body {
    /* max-height: 450px; */
    height: calc(100vh - 170px);
    overflow-y: auto;
    padding: 25px 0;
}

.product-detail .product-title {
    color: #23292e;
    font-size: 28px;
    line-height: 24px;
    margin-bottom: 15px;
    text-transform: uppercase;
}

.product-detail .product-image {
    display: block;
    margin: 15px 0;
    max-height: 300px;
    text-align: center;
}

.product-detail .product-image img {
    max-height: 300px;
    object-fit: contain;
    object-position: center;
}

.product-detail .progress-title {
    font-size: 20px;
    text-transform: uppercase;
    display: block;
    width: 120px;
}

.product-detail .progress {
    background: #e2e6eb;
    border-radius: 10px;
    height: 8px;
}

.product-detail .product-description {
    font-size: 20px;
    line-height: 30px;
}

.product-detail .product-price {
    align-items: center;
    display: flex;
    font-size: 20px;
    justify-content: center;
    line-height: 34px;
}

.product-detail .product-price img {
    margin-right: 5px;
}

.product-detail .btn-buy {
    background-color: #23292e;
    border-radius: 30px;
    color: #ffffff;
    width: 100%;
}

.product-detail .btn-outline-dark {
    background-color: transparent;
    border-radius: 30px;
    border: 1px solid #23292e;
    color: #23292e;
    cursor: not-allowed;
    opacity: 0.7;
    width: 100%;
}

.animate-rtl {
    animation: slideRight 0.3s ease-in-out;
}

@keyframes slideRight {
    from {
        transform: translateX(50%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@media only screen and (max-width: 767px) {
    .card {
        margin-bottom: 20px;
    }

    .card .card-body {
        padding: 8px 6px;
    }

    .card .card-title {
        font-size: 12px;
        line-height: 10px;
        margin-bottom: 0;
    }

    .card .card-image {
        height: 80px;
        margin: 0;
    }

    .card .card-image img {
        height: 80px;
    }

    .card .card-image img[src*="/Frames/"] {
        margin-top: 7px;
    }

    .card .card-cta > .btn {
        font-size: 13px;
        height: 25px;
        line-height: 18px;
        padding: 3px 12px;
        width: 140px;
    }

    .card .card-price {
        font-size: 14px;
        line-height: 16px;
    }

    .card .card-price img {
        height: 16px;
    }

    .product-detail .product-content {
        padding: 20px;
    }

    .product-detail .topup {
        padding: 0;
        margin: 0;
    }

    .product-detail .product-body {
        height: calc(100vh - 90px);
    }

    .product-detail .btn-close {
        display: none;
    }

    .product-detail .btn-back {
        align-items: center;
        background-color: transparent;
        background-image: url("/assets/images/shop/icons/left-arrow.png");
        background-position: center;
        background-repeat: no-repeat;
        border: 0;
        cursor: pointer;
        display: inline-flex;
        height: 20px;
        margin-right: 10px;
        outline: none;
        width: 20px;
    }

    .product-detail .product-title {
        font-size: 20px;
    }

    .product-detail .progress-title {
        font-size: 13px;
    }

    .product-detail .product-description {
        font-size: 14px;
        line-height: 24px;
    }
}
</style>
