import Service from "@/services";

export default {

    namespaced: true,

    state: {
        page: {},
        article: {},
        faqs: [],
        setting: {}
    },

    getters: {
        page: (state) => state.page,
        article: (state) => state.article,
        faqs: (state) => state.faqs,
        setting: (state) => state.setting
    },

    actions: {
        async view({commit}, slug) {
            try {
                return await Service().get(`pages/${slug}`).then(res => {
                    commit('SET_PAGE', res.data);
                    return res;
                });
            } catch (e) {
                console.log('Error: ', e);
            }
        },
        async getArticle({commit}, slug) {
            try {
                return await Service().get(`pages/article/${slug}`).then(res => {
                    if(res && res.status) {
                        commit('SET_ARTICLE', res.data);
                    }
                });
            } catch (e) {
                console.log('Error: ', e);
            }
        },
        async setting({commit}) {
            try {
                return await Service().get(`setting`).then(res => {
                    commit('SET_SETTING', res.data);
                    return res;
                });
            } catch (e) {
                console.log('Error: ', e);
            }
        },
        async faqs({commit}) {
            try {
                return await Service().get(`pages/getFaqs`).then(res => {
                    commit('SET_FAQS', res.data);
                    return res;
                });
            } catch (e) {
                console.log('Error: ', e);
            }
        },
    },

    mutations: {
        SET_FAQS: (state, payload) => state.faqs = payload,
        SET_PAGE: (state, payload) => state.page = payload,
        SET_ARTICLE: (state, payload) => state.article = payload,
        SET_SETTING: (state, payload) => state.setting = payload,
    }
}
