<template>
    <div class="modal fade" id="requirementsModal" tabindex="-1" role="dialog" aria-labelledby="requirementsModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="requirementsModalLabel">Event Requirements</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" v-html="requirements"></div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'EventRequirements',
  props: ['requirements'],
}
</script>

<style>
.modal-body {
  height: 600px;
  overflow-y: auto;
  padding: 2rem;
  text-align: left;
  line-height: 24px;
}
.modal-body p, .event-listing-detail p {
  font-size: 16px !important;
}
.modal-body > a {
  padding: 0;
  color: #00c !important;
  background-color: #ffffff !important;
}
.modal-body ul li ul{
  margin-left: 20px !important;
}
</style>

