<template>
  <section>
    <h3>ACTIVITIES</h3>
    <p class="px-5">Download your activities.</p>

    <div class="p-3" v-if="files.length === 0">
      <div class="alert alert-warning">
        <p class="my-2">No Files found for this user</p>
      </div>
    </div>

    <div class="card card-stats" v-for="(file, i) in files" :key="i">
      <div class="card-body">
        <div class="img-thumbnail rounded">
          <img src="/favicon.png" class="text-center" alt="thumbnail">
        </div>

        <div>
          <h5>{{ file.type }}</h5>
          <small class="text-muted">{{ file.date }}</small>
          <small class="mx-2">|</small>
          <small>{{ file.distance | formatNumber }} Km</small>
          <small class="mx-2">|</small>
          <small>{{ file.elevation | formatNumber }} Meters</small>
        </div>

        <a href="javascript:" class="download" @click="downloadFile(file.id)">
          <i class="icon-download"></i>
          <span>download {{ file.type }}</span>

        </a>
      </div>
    </div>
  </section>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: 'PlayerActivities',
  props: ['files'],

  data() {
    return {}
  },

  filters: {
    formatDate: function (date) {
      return dayjs(date).format("DD MMM, YYYY hh:MM A");
    },

    formatNumber: function(value) {
      return Math.round(value * 100) / 100
    }
  },

  methods: {
    downloadFile(id) {
      this.$store.dispatch('user/downloadFile', { id }).then(res => {
        if (res && res.status) {
          this.downloadUserFile(res.data.data, res.data.ext);
          return;
        }

        alert('Error download file. Please try again')
      });
    },

    downloadUserFile(data, ext) {
      if (ext !== 'fit') {
        data = "data:text/json;charset=utf-8," + encodeURIComponent(data);
      }

      const node = document.createElement('a');
      node.setAttribute("href", data);
      node.setAttribute("download", `activity.${ext}`);
      document.body.appendChild(node); // required for firefox
      node.click();
      node.remove();
    },

  }
}
</script>

<style scoped>
.img-thumbnail img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.card {
  border: 0;
  border-radius: 0;
  color: #282828;
  text-align: left;
}

.card:nth-child(even) {
  background: #f2f2f2;
}

.card-body {
  align-items: center;
  display: flex;
  padding: 15px;
}

h5 {
  font-size: 16px;
  font-family: 'Poppins', sans-serif !important;
  text-transform: uppercase;
}

small {
  color: #5a5a5a;
}

.img-thumbnail {
  background-color: #f7f7f7;
  font-size: 12px;
  height: 60px;
  margin-right: 15px;
  overflow: hidden;
  width: 60px;
}

.download {
  align-items: center;
  background-color: #d8d8d8;
  border-radius: 7px;
  color: #5a5a5a;
  display: inline-flex;
  font-size: 14px;
  font-weight: 500;
  margin-left: auto;
  padding: 8px 12px;
  text-transform: uppercase;
  text-decoration: none;
  transition: all ease-in-out .2s;
}

.download:hover,
.download:focus {
  background-color: #c8c8c8;
}

.icon-download {
  background-image: url("/assets/images/download.png");
  background-repeat: no-repeat;
  background-size: 18px;
  display: inline-block;
  filter: invert(.3);
  margin-right: 7px;
  height: 18px;
  width: 18px;
}
</style>