<template>

  <section class="profile" v-if="(articles)">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <section class="col-md-12">

            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" :class="{'active': i === 0}" v-for="(category, i) in articles" :key="i">
                <a class="nav-link" :id="`tab-${category.id}`" data-toggle="tab" :href="`#tabContent-${category.id}`"
                   role="tab"
                   aria-controls="profile" aria-selected="true">{{ category.name }}
                </a>
              </li>
            </ul>

            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show" :class="{'active': i === 0}" v-for="(category, i) in articles" :key="i"
                   :id="`tabContent-${category.id}`" role="tabpanel" aria-labelledby="profile-tab">
                <carousel v-if="(category.articles.length > 0)" :key="i" class="pg support-carousel" :autoplay="true"
                          :margin="20"
                          :responsive="{0:{items:1,},600:{items:2,},1000:{items:3,nav:true,dots:false}}">

                  <div class="card" v-for="(article, j) in category.articles" :key="j">
                    <div class="position-relative">
                      <img
                          class="card-img-top"
                          v-lazy="makeImage(article.image)"
                          :alt="article.title"
                      />
                      <img class="imgIcon" :src="`${assetsUrl()}/images/cycleIcon.png`">
                    </div>
                    <div class="card-body cardContent">
                      <h4 class="card-title cardHeading">{{ article.title }}</h4>
                      <p class="card-text" v-html="article.content"></p>
                      <router-link :to="{name: 'article', params: {slug: article.slug} }">LEARN MORE</router-link>
                    </div>
                  </div>

                  <template slot="prev">
                    <button class="owl-prev" role="presentation" type="button"><span aria-label="Previous">‹</span>
                    </button>
                  </template>

                  <template slot="next">
                    <button class="owl-next" role="presentation" type="button"><span aria-label="Next">›</span>
                    </button>
                  </template>

                </carousel>

                <p v-else class="text-warning p-3">There are no articles for this category</p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
import carousel from "vue-owl-carousel";
import {commonMixin} from '@/mixins/commonMixin';

export default {
  name: "Articles",
  mixins: [commonMixin],

  props: ['articles'],
  components: {
    carousel,
  },
}
</script>

<style scoped>
.nav-tabs {
  justify-content: center !important;
}
.cardContent a{color: #121113 !important;}
.cardContent a:hover{color: white !important;}
</style>