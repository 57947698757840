<template>
  <div v-if="page">
    <vue-title title="Events - Whoosh! Your Indoor Cycling Solution"></vue-title>

    <!-- <Banner :page="page" /> -->

    <section class="event-main events-page-wrap clearfix">
      <div class="container">
        <div class="row">
          <section class="col-md-12">

            <div id="myTabContent" class="tab-content">
              <div
                id="upcoming"
                aria-labelledby="upcoming-tab"
                class="tab-pane fade"
                :class="{ 'show active': $route.query.event !== 'dailyrides' }"
                role="tabpanel"
              >
                <EventList
                  v-if="featuredEvents.length > 0"
                  :events="featuredEvents"
                />
                <EventList v-if="allEvents.length > 0" :events="allEvents" />
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventList from "@/components/event/EventList";

export default {
  name: "Events",

  components: {
    EventList,
  },

  data() {
    return {
      activeId: -1,
    };
  },

  computed: {
    ...mapGetters("event", ["events"]),
    ...mapGetters("page", ["page"]),

    allEvents() {
      if (!this.events) return [];
      return this.events.filter(
        (event) => event.event_type === "all" || event.event_type === "open"
      );
    },

    featuredEvents() {
      if (!this.events) return [];
      return this.events.filter((event) => event.event_type === "featured");
    },
  },

  mounted() {
    document.body.classList.add("contact");
    this.$store.dispatch("event/events");
    this.$store.dispatch("page/view", "events");
  },

  destroyed() {
    document.body.classList.remove("contact");
  },
};
</script>

<style scoped>
#myTab > li {
  width: 50%;
}
</style>
