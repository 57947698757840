<template>
  <div class="event-reg-form">
<!--    <h4>REGISTRATION TYPE</h4>-->
    <div class="row align-items-center">
      <section class="col-md-6 pr-lg-5">
        <div class="basic-info">

          <div class="select-field form-group mb-0">
            <label>Registration Type</label>
            <v-select 
              class="style-chooser" 
              label="label"
              :options="[{label: 'I have a team', value: 'y'}, {label: 'Individual', value: 'i'}]" 
              v-model="team"
              :reduce="e => e.value"
              :clearable="false"
            >
            </v-select>

            <small class="text-help-info">All team members are also eligible for individual prize money</small>
            <div class="error-message" v-if="getError('team')">Please select registration Type</div>
          </div>


          <div class="input-field form-group mt-3" v-if="isTeam">
            <input class="form-control" type="text" v-model.trim="teamName" placeholder="Team Name"/>
            <small class="text-help-info">{{ event.team_help_text }}</small>
            <div class="error-message" v-if="getError('teamName')">required</div>
          </div>

        </div>
      </section>

    </div>
  </div>
</template>

<script>
import {required, requiredIf} from 'vuelidate/lib/validators'

export default {
  name: "TeamForm",
  props: ["event"],

  data() {
    return {
      team: 'i',
      teamName: null,
      isTeam: false,
    }
  },

  validations: {
    team: {required},
    teamName: {
      required: requiredIf(function () {
        return this.team !== 'i';
      }),
    },
    form: ['team', 'teamName'],
  },

  watch: {
    'team': {
      deep: true,
      handler: function (nv) {
        this.isTeam = nv === 'y';
      }
    },
  },

  methods: {
    validate() {
      this.$v.form.$touch();
      const isValid = !this.$v.form.$invalid
      this.$emit('on-validate', this.$data, isValid)
      return isValid
    },

    getError(field) {
      return this.$v[field].$error;
    },
  }
}
</script>

<style scoped>
.error-message {
  font-size: 12px;
  color: #ffffff;
  background-color: #dc3545;
  padding: 2px 2px 2px 10px;
}
</style>