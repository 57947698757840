export const WheelsSet = [
    {
        "Name": "MyWhoosh SC-30",
        "ID": 1,
        "Brandtitle": "MyWhoosh",
        "Description": "Standard Carbon 30mm wheelset. Aimed at saving weight and designed with the hills in mind.",
        "Aerodynamics": 20,
        "Weight": 1.3500000238418579,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 1,
        "isEnabled": true,
        "MeshIdFront": 1,
        "MeshIdBack": 2,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.5,
        "UI_Icon": 0,
        "WeightUI": 40,
        thumbnail: "MyWhoosh SC-30.png"
    },
    {
        "Name": "MyWhoosh SC-50",
        "ID": 2,
        "Brandtitle": "MyWhoosh",
        "Description": "Standard Carbon 50mm wheelset. A balanced blend of weight and aero. Your all-round wheelset.",
        "Aerodynamics": 30,
        "Weight": 1.4500000476837158,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 1,
        "isEnabled": true,
        "MeshIdFront": 3,
        "MeshIdBack": 4,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.5,
        "UI_Icon": 1,
        "WeightUI": 30,
        thumbnail: "MyWhoosh SC-50.png"
    },
    {
        "Name": "MyWhoosh SC-65",
        "ID": 3,
        "Brandtitle": "MyWhoosh",
        "Description": "Standard Carbon 65mm wheelset. A wheelset that reduces drag with deep-section rims that optimizes aero efficiency for flat-out speed.",
        "Aerodynamics": 50,
        "Weight": 1.5499999523162842,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 1,
        "isEnabled": true,
        "MeshIdFront": 5,
        "MeshIdBack": 6,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.5,
        "UI_Icon": 2,
        "WeightUI": 20,
        thumbnail: "MyWhoosh SC-65.png"
    },
    {
        "Name": "MyWhoosh SC-Aero",
        "ID": 4,
        "Brandtitle": "MyWhoosh",
        "Description": "Standard Carbon Aero Disc wheelset. Fast and Aero, this wheelset combines a 65mm Standard Carbon front wheel with an Aero Disc rear wheel. Ride these when speed is the focus!",
        "Aerodynamics": 70,
        "Weight": 1.6499999761581421,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 1,
        "isEnabled": true,
        "MeshIdFront": 7,
        "MeshIdBack": 8,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.5,
        "UI_Icon": 3,
        "WeightUI": 15,
        thumbnail: "MyWhoosh SC-Aero.png"
    },
    {
        "Name": "MyWhoosh SC-Gravel",
        "ID": 5,
        "Brandtitle": "MyWhoosh",
        "Description": "Standard Carbon Gravel Wheelset. All-road performance, light, aerodynamic and capable of going wherever you choose.",
        "Aerodynamics": 20,
        "Weight": 1.3999999761581421,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 1,
        "isEnabled": true,
        "MeshIdFront": 9,
        "MeshIdBack": 10,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.5,
        "UI_Icon": 4,
        "WeightUI": 35,
        thumbnail: "MyWhoosh SC-Gravel.png"
    },
    {
        "Name": "Corima MCC DX 47mm",
        "ID": 6,
        "Brandtitle": "Corima ",
        "Description": "Corima MCC DX 47mm. Lightweight for fast climbing, optimal stiffness for improved performance, an unprecedented level of responsiveness for fast acceleration and optimized aerodynamics. The MCC DX pushes the limits of performance.",
        "Aerodynamics": 50,
        "Weight": 1.3500000238418579,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 1,
        "isEnabled": true,
        "MeshIdFront": 11,
        "MeshIdBack": 12,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.5,
        "UI_Icon": 5,
        "WeightUI": 40,
        thumbnail: "Corima MCC DX 47mm.png"
    },
    {
        "Name": "Corima Essentia 40",
        "ID": 7,
        "Brandtitle": "Corima ",
        "Description": "Corima Essentia 40 is the ultimate all-road wheel. Destined to erase the borders between road and gravel, fast, lightweight, ultra-versatile. With hookless rims and wider tires it is designed to maximize comfort and control.",
        "Aerodynamics": 30,
        "Weight": 1.3999999761581421,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 1,
        "isEnabled": true,
        "MeshIdFront": 13,
        "MeshIdBack": 14,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.5,
        "UI_Icon": 6,
        "WeightUI": 35,
        thumbnail: "Corima Essentia 40.png"
    },
    {
        "Name": "Swiss Side Hadron Ultimate 500",
        "ID": 8,
        "Brandtitle": "Swiss Side",
        "Description": "Swiss Side Hadron Ultimate 500, perfectly engineered stiffness to weight ratio, tubeless ready, hooked construction, designed to deliver the optimum balance of rolling resistance and aero performance.",
        "Aerodynamics": 60,
        "Weight": 1.3500000238418579,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 1,
        "isEnabled": true,
        "MeshIdFront": 15,
        "MeshIdBack": 16,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.5,
        "UI_Icon": 7,
        "WeightUI": 40,
        thumbnail: "Swiss Side Hadron Ultimate 500.png"
    },
    {
        "Name": "MyWhoosh SC-40 Desert Sand",
        "ID": 9,
        "Brandtitle": "MyWhoosh",
        "Description": "Standard Carbon 40mm wheelset. Light, stiff and fast on the desert flats.",
        "Aerodynamics": 30,
        "Weight": 1.3500000238418579,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 1,
        "isEnabled": true,
        "MeshIdFront": 17,
        "MeshIdBack": 18,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.5,
        "UI_Icon": 8,
        "WeightUI": 35,
        thumbnail: "MyWhoosh SC-40 Desert Sand.png"
    },
    {
        "Name": "MyWhoosh PC-44 Rouleur",
        "ID": 10,
        "Brandtitle": "MyWhoosh",
        "Description": "Premium Carbon 44mm wheelset. Designed with a focus on aerodynamics and handling, the ability to go fast on any road.",
        "Aerodynamics": 50,
        "Weight": 1.2999999523162842,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 1,
        "isEnabled": true,
        "MeshIdFront": 19,
        "MeshIdBack": 20,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.5,
        "UI_Icon": 9,
        "WeightUI": 45,
        thumbnail: "MyWhoosh PC-44 Rouleur.png"
    },
    {
        "Name": "MyWhoosh PC-40 Radiance",
        "ID": 11,
        "Brandtitle": "MyWhoosh",
        "Description": "Premium Carbon 40mm wheelset. Lightweight and responsive, a premium wheelset designed to go fast.",
        "Aerodynamics": 50,
        "Weight": 1.25,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 1,
        "isEnabled": true,
        "MeshIdFront": 21,
        "MeshIdBack": 22,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.5,
        "UI_Icon": 10,
        "WeightUI": 50,
        thumbnail: "MyWhoosh PC-40 Radiance.png"
    },
    {
        "Name": "MyWhoosh PC-40 Desert Sand",
        "ID": 12,
        "Brandtitle": "MyWhoosh",
        "Description": "Premium Carbon 40mm wheelset. Lightweight and responsive, a premium wheelset designed to go fast.",
        "Aerodynamics": 50,
        "Weight": 1.25,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 1,
        "isEnabled": true,
        "MeshIdFront": 23,
        "MeshIdBack": 24,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.5,
        "UI_Icon": 11,
        "WeightUI": 50,
        thumbnail: "MyWhoosh PC-40 Desert Sand.png"
    },
    {
        "Name": "ENVE Pink decals",
        "ID": 13,
        "Brandtitle": "ENVE Pink decals",
        "Description": "Designed to deliver more speed in a lighter package with pink touch to celebrate Tadej Pogacar win in the 2024 Giro d’Italia",
        "Aerodynamics": 30,
        "Weight": 1.4500000476837158,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 1,
        "isEnabled": true,
        "MeshIdFront": 25,
        "MeshIdBack": 26,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.5,
        "UI_Icon": 12,
        "WeightUI": 30,
        thumbnail: "ENVE Pink decals.png"
    },
    {
        "Name": "ENVE SES 4.5",
        "ID": 15,
        "Brandtitle": "ENVE SES 4.5 ",
        "Description": "Designed to deliver more speed in a lighter package. When speed across most any terrain is priority number one, look no further than the ENVE SES 4.5.",
        "Aerodynamics": 65,
        "Weight": 1.4500000476837158,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 1,
        "isEnabled": true,
        "MeshIdFront": 25,
        "MeshIdBack": 26,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.5,
        "UI_Icon": 12,
        "WeightUI": 30,
        thumbnail: "ENVE SES 4.5.png"
    },
    {
        "Name": "Cadex 50 Ultra Disc",
        "ID": 16,
        "Brandtitle": "Cadex 50 Ultra Disc",
        "Description": "Climbs and descents. Fast, flat sections and tricky corners. Group dynamics and solo moves. The new CADEX 50 Ultra Disc Wheelset targets all these factors.",
        "Aerodynamics": 60,
        "Weight": 1.3000000476837158,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 1,
        "isEnabled": true,
        "MeshIdFront": 25,
        "MeshIdBack": 26,
        "AtlasId": 1,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0.25,
        "V_offset": 0.5,
        "UI_Icon": 12,
        "WeightUI": 30,
        thumbnail: "Cadex 50 Ultra Disc.png"
    },
    {
        "ID": 17,
        "Brandtitle": "MyWhoosh",
        "Name": "MyWhoosh PC-38 Sapphire",
        "Description": "MyWhoosh PC-38 Sapphire is a lightweight, high-end carbon wheelset crafted to distinguish itself with exceptional design and maximum performance.",
        "Aerodynamics": 55,
        "Weight": 1.4,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 0,
        "isEnabled": true,
        "MeshIdFront": 33,
        "MeshIdBack": 34,
        "AtlasId": 0,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0,
        "V_offset": 0,
        "UI_Icon": 16,
        "WeightUI": 45,
        thumbnail: "Cadex 50 Ultra Disc.png"
    },
    {
        "ID": 18,
        "Brandtitle": "MyWhoosh",
        "Name": "MyWhoosh PC-38 FireOpal",
        "Description": "MyWhoosh PC-38 FireOpal is a lightweight, high-end carbon wheelset crafted to distinguish itself with exceptional design and maximum performance.",
        "Aerodynamics": 55,
        "Weight": 1.4,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 0,
        "isEnabled": true,
        "MeshIdFront": 35,
        "MeshIdBack": 36,
        "AtlasId": 0,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0,
        "V_offset": 0,
        "UI_Icon": 17,
        "WeightUI": 45,
        thumbnail: "MyWhoosh PC-38 FireOpal.png"
    },
    {
        "ID": 19,
        "Brandtitle": "MyWhoosh",
        "Name": "MyWhoosh PC-38 Emerald",
        "Description": "MyWhoosh PC-38 Emerald is a lightweight, high-end carbon wheelset crafted to distinguish itself with exceptional design and maximum performance.",
        "Aerodynamics": 55,
        "Weight": 1.4,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 0,
        "isEnabled": true,
        "MeshIdFront": 37,
        "MeshIdBack": 38,
        "AtlasId": 0,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0,
        "V_offset": 0,
        "UI_Icon": 18,
        "WeightUI": 45,
        thumbnail: "MyWhoosh PC-38 Emerald.png"
    },
    {
        "ID": 20,
        "Brandtitle": "MyWhoosh",
        "Name": "MyWhoosh PC-38 Amethyst",
        "Description": "MyWhoosh PC-38 Amethyst is a lightweight, high-end carbon wheelset crafted to distinguish itself with exceptional design and maximum performance.",
        "Aerodynamics": 55,
        "Weight": 1.4,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 0,
        "isEnabled": true,
        "MeshIdFront": 39,
        "MeshIdBack": 40,
        "AtlasId": 0,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0,
        "V_offset": 0,
        "UI_Icon": 19,
        "WeightUI": 45,
        thumbnail: "MyWhoosh PC-38 Amethyst.png"
    },
    {
        "ID": 21,
        "Brandtitle": "ENVE SES4.5",
        "Name": "ENVE SES 4.5 Gold",
        "Description": "Designed to deliver more speed in a lighter package. When speed across most any terrain is priority number one, look no further than the ENVE SES 4.5.",
        "Aerodynamics": 65,
        "Weight": 1.3,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 0,
        "isEnabled": true,
        "MeshIdFront": 41,
        "MeshIdBack": 42,
        "AtlasId": 0,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0,
        "V_offset": 0,
        "UI_Icon": 20,
        "WeightUI": 40,
        thumbnail: "ENVE SES 4.5 Gold.png"
    },
    {
        "ID": 22,
        "Brandtitle": "ENVE SES4.5",
        "Name": "ENVE SES 4.5 TDF Edition",
        "Description": "Designed to deliver more speed in a lighter package with Yellow touch to celebrate Tadej Pogacar win in the 2024 Tour de France.",
        "Aerodynamics": 65,
        "Weight": 1.3,
        "ItemType": 0,
        "Coins": 0,
        "Gems": 0,
        "TotalKilometers": 0,
        "UnlockingType": "E_Level",
        "UnlockingValue": 0,
        "isEnabled": true,
        "MeshIdFront": 41,
        "MeshIdBack": 42,
        "AtlasId": 0,
        "U_transform": 0,
        "V_transform": 0,
        "U_offset": 0,
        "V_offset": 0,
        "UI_Icon": 20,
        "WeightUI": 40,
        thumbnail: "ENVE SES 4.5 TDF Edition.png"
    }
]