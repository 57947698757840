<template>
  <div class="event-reg-form">
    <div class="row align-items-center">

      <section class="col-md-8 pr-lg-12">

        <div class="row">
          <div class="col-md-6">
            <div class="input-field form-group">

              <vue-tel-input v-model="form.phone" :disabled="otpVerifyStatus" @input="validatePhone"
                @country-changed="countryChanged" class="form-control"
                :class="{ 'is-invalid': submittedOtpForm && getError('phone') || !phone.valid }">
              </vue-tel-input>

              <div class="error-message" v-if="submittedOtpForm && getError('phone') || !phone.valid">
                Please enter a valid phone number
              </div>

              <small class="text-help-info" style="line-height: 2px;">
                We'll use your number only for verification. This will not be visible to other users.
              </small>
            </div>

          </div>
          <div class="col-md-6">
            <div class="display-phone" v-if="!otpVerifyStatus && form.phone">
              <strong class="text-center">{{ phone.number }}</strong>
            </div>
          </div>
        </div>

        <div class="custom-radio-btn mt-1 mb-1" v-if="showOtpOptions">
          <div class="custom-control custom-radio custom-control-inline text-right">
            <input type="radio" class="custom-control-input" id="smsOtp" value="sms" checked />
            <label class="custom-control-label" for="smsOtp"> OTP via Sms</label>
          </div>

          <button type="button" class="btn-universal" :class="{ otpActive: form.phone }" v-if="!otpSentStatus"
            @click="sendOtp">
            <i class="fa fa-spinner fa-spin" v-if="getLoading"></i> Send OTP
          </button>

          <button type="button" class="btn-universal" :class="{ otpActive: form.phone }"
            v-if="otpSentStatus && !otpVerifyStatus" @click="sendOtp">
            <i class="fa fa-spinner fa-spin" v-if="getLoading"></i> Resend OTP
          </button>
        </div>

        <div class="input-field rounded border bg-light p-3 mt-4 text-12" v-if="otpSentStatus && !otpVerifyStatus">
          Verify OTP
          <div class="input-group mb-3">
            <input type="text" class="form-control bg-white"
              :class="{ 'is-invalid': submittedVerifyForm && getVerifyError('otp') }" v-model="verifyForm.otp"
              placeholder="Please enter OTP here" />
            <div class="input-group-append">
              <button type="button" class="btn-universal border-0" @click="verifyOtp" style="height: 55px;">
                VERIFY OTP
              </button>
            </div>
          </div>

        </div>

        <div class="mt-3 text-success" role="alert" v-if="otpVerifyStatus">
          Your phone number has been verified.
        </div>

        <div class="text-button-wrap" v-if="otpVerifyStatus" style="margin-left:0px;">
          <div class="custom-control custom-checkbox">
            <input id="agree" v-model="acceptCheck.agree" class="custom-control-input" type="checkbox" />
            <label class="custom-control-label text-12 mt-3 terms-condition-label" for="agree">
              By clicking submit button, I agree to your
              <router-link target="_blank" :to="{ name: 'terms' }">Terms and Conditions</router-link>
              and
              <router-link target="_blank" :to="{ name: 'privacy' }">Privacy Policy</router-link>
            </label>
          </div>
          <small v-if="!acceptCheck.agree" class="text-danger ml-4">Please agree to the terms and conditions</small>
        </div>


      </section>

    </div>
  </div>
</template>

<style>
.vue-tel-input.disabled {
  background: #e9ecef;
}

.display-phone {
  background: #f8f9fa;
  margin: 0;
  color: #070000;
  padding: 16px 20px;
  border-radius: 5px;
  text-align: center;
  border: 1px solid #dee2e6 !important;
}

.custom-checkbox .custom-control-input:checked~.terms-condition-label::before {
  background-color: #fbb814 !important;
}
</style>

<script>
import { mapGetters } from 'vuex';
import { minLength, maxLength, numeric, required, sameAs } from 'vuelidate/lib/validators'

export default {
  name: 'EventOtpForm',
  props: ["event", "user"],

  data() {
    return {
      country: {},
      phone: {},

      form: {},
      allowAgree: false,
      verifyForm: {},
      countriesData: [],
      submittedOtpForm: false,
      submittedVerifyForm: false,
      acceptCheck: {
        agree: false,
      }
    }
  },

  computed: {
    ...mapGetters("form", ['countries']),
    ...mapGetters("event", ['otpSentStatus', 'otpVerifyStatus']),
    ...mapGetters("common", ['getLoading']),
  },

  validations: {
    form: {
      phone: { required, numeric, minLength: minLength(6), maxLength: maxLength(11) },
      code: { required },
    },
    verifyForm: {
      otp: { required, numeric, minLength: minLength(6) },
    },
    acceptCheck: {
      agree: { sameAs: sameAs(() => true) },
    }
  },

  mounted() {
    this.form = {
      code: this.user.country?.toString() || "971",
      phone: this.user.phone?.toString() || "",
    }
    
    this.verifyForm = {
      otp: null,
    }

    this.$store.dispatch("form/countries").then(res => {
      this.countriesData = res.data.filter(x => {
        return this.event.countries.includes(x.value);
      });
    });
  },

  methods: {

    countryChanged(country) {
      this.country = country;
      this.form.code = country.dialCode;
    },

    validatePhone(number, phone) {
      this.phone = phone;
      this.form.phone = phone?.nationalNumber;
    },

    validate() {
      this.$v.form.$touch();
      this.$v.acceptCheck.$touch();
      const isValid = !this.$v.form.$invalid && !this.$v.acceptCheck.$invalid && this.phone.valid;
      // const isValid = !this.$v.form.$invalid && !this.$v.acceptCheck.$invalid;

      this.form.agree = true;
      this.$emit('on-validate', this.form, isValid)
      return isValid
    },

    showOtpOptions() {
      return !this.otpVerifyStatus || !this.event.phone || !this.form.phone;
    },

    sendOtp() {
      this.submittedOtpForm = true;
      this.$v.form.$touch();
      if (this.$v.form.$invalid || !this.phone.valid) return;

      this.$store.dispatch("event/sendOtp", this.form).then(res => {
        if (res && res.data) {
          this.verifyForm.key = res.data.key;
        }
      });
    },

    verifyOtp() {
      this.$v.verifyForm.$touch();
      if (this.$v.verifyForm.$invalid) return;

      this.verifyForm.phone = `${this.form.code}${this.form.phone}`;
      this.$store.dispatch("event/verifyOtp", this.verifyForm).then(() => {
        this.$emit('getEventOtpForm', this.form);
      });
    },

    getError(field) {
      return this.$v.form[field].$invalid;
    },

    getVerifyError(field) {
      return this.$v.verifyForm[field].$invalid;
    },

  },
}
</script>
<style scoped>
.otpActive {
  background-color: #fab81d !important;
  color: #1d1d1d !important;
}
</style>