<template>
  <div
      class="tab-pane fade"
      :class="{ 'show active': isActive }"
      id="nav-home"
      role="tabpanel"
      aria-labelledby="nav-home-tab"
      tabindex="0"
  >
    <div class="alert-custom alert alert-primary left-brdr shadow" v-if="!thirdPartyData">
      <div class="d-flex justify-content-between align-items-center">
        <div class="">
          You can also import your data from other platforms into MyWhoosh.
        </div>
        <div class="">
          <button type="button" class="btnSuccess" @click="openThirdPartyModal">
            Import
          </button>
        </div>
      </div>
    </div>

    <div class="alert-custom alert alert-review left-brdr shadow"
         v-else-if="thirdPartyData?.status === 'submitted'" >
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex justify-content-between align-items-center">
          Our team is reviewing your application, we will get back to you soon! <img class="mx-1" src="/assets/images/review.png" />
        </div>
      </div>
    </div>

    <div
        ref="gotItParent"
        class="alert-custom alert alert-success left-brdr shadow" v-else-if="thirdPartyData?.status === 'approved'
    && thirdPartyData?.has_seen !== 1" >
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          Congratulations!, your data has been imported successfully
          <img class="mx-1" src="/assets/images/celeb.png" />
        </div>
        <div class="d-flex align-items-center">
          <button type="button" @click="setUserHasSeen" class="btn btn-success ok float-end">
            Got It
          </button>
        </div>
      </div>
    </div>


    <div
        ref="gotItParentReject"
        v-else-if="thirdPartyData?.status === 'rejected'"
        class="alert-custom alert alert-reImport left-brdr shadow">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          Sorry! Your application has been declined due to inaccurate information provided. Please try import with correct details.
        </div>
        <div class="d-flex align-items-center">
          <button type="button"  @click="openThirdPartyModal" class="btn btnSuccess Import float-end">
            re- import
          </button>
        </div>
      </div>
    </div>




    <ModalThirdPartyImport ref="modalThirdParty" />

    <h1>Progress:</h1>
    <div class="tbl-wrpr-overAll table-responsive">
      <table>
        <tbody>
        <tr>
          <th></th>
          <th>Overall</th>
          <th v-if="thirdPartyData && thirdPartyData?.status === 'approved'">Other Platform</th>
          <th>Yearly ({{ currentYear }})</th>
          <th>
            Monthly
            <span style="text-transform: capitalize"
            >({{ currentMonth.format("MMM") }})</span
            >
          </th>
          <th>Weekly ({{ currentWeek }})</th>
        </tr>

        <!-- loop tableData   -->
        <tr v-for="(data, key) in tableData" :key="key">
          <td>{{ camelCaseToWords(key) }}</td>

        <template v-if="key === 'distance' && thirdPartyData && thirdPartyData?.status === 'approved'">

          <td  >

            {{
              (userData?.data?.PlayerData?.PlayerPersonalStruct?.TotalKilometers
              )?.toFixed(
                  2 )

            }} KM
          </td>

          <td  >

            {{
              thirdPartyDistance?.toFixed(
                  2 )

            }} KM
          </td>
        </template>
        <template v-else-if="key === 'distance' && thirdPartyData?.status !== 'approved'">
          <td  >

            {{
              (userData?.data?.PlayerData?.PlayerPersonalStruct?.TotalKilometers
              )?.toFixed(
                  2 )

            }} KM
          </td>
        </template>

          <template v-if="key === 'timeSpend' && thirdPartyData && thirdPartyData?.status === 'approved'">

            <td  >
              {{
                (
                    parseFloat(userData?.data?.PlayerData?.PlayerPersonalStruct
                        ?.TotalRideTime / 3600)
                )?.toFixed(2)
              }}
              h
            </td>

            <td  >

              {{
                thirdPartyTime?.toFixed(
                    2 )

              }} h
            </td>
          </template>
          <template  v-else-if="key === 'timeSpend' && thirdPartyData?.status !== 'approved'">
            <td >
              {{
                (
                    parseFloat(userData?.data?.PlayerData?.PlayerPersonalStruct
                        ?.TotalRideTime / 3600)
                )?.toFixed(2)
              }}
              h
            </td>
          </template>


          <template v-if="key === 'elevation' && thirdPartyData && thirdPartyData?.status === 'approved'">


            <td >
              {{

                parseFloat(userData?.data?.PlayerData.PlayerPersonalStruct?.TotalElevation)
                    ?.toFixed(
                        2
                    )

              }}
              M
            </td>

            <td >
              {{

                parseFloat(thirdPartyElevation)?.toFixed(
                    2
                )

              }}
              M
            </td>
          </template>
          <template v-else-if="key === 'elevation' && thirdPartyData?.status !== 'approved'">
            <td >
              {{

                parseFloat(userData?.data?.PlayerData.PlayerPersonalStruct?.TotalElevation)
                    ?.toFixed(
                        2
                    )

              }}
              M
            </td>
          </template>

          <template v-if="key === 'races' && thirdPartyData && thirdPartyData?.status === 'approved'">

            <td >{{ totalEvents }}</td>
            <td >--</td>

          </template>
          <template v-else-if="key === 'races' && thirdPartyData?.status !== 'approved'">
            <td >{{ totalEvents }}</td>
          </template>


          <td>{{ data.year }}</td>
          <td>{{ data.month }}</td>
          <td>{{ data.week }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <section>
      <div class="searchMainWrpr-dayWeek my-3">
        <div class="row justify-content-between">
          <div class="col-sm-12 col-md-12 col-lg-8 col-xl-7 col-xxl-7">
            <div class="select my-1">
              <select
                  v-model="durationType"
                  class="form-select duration-type"
                  aria-label="Default select example"
                  name="duration-type"
                  id="duration-type"
              >
                <option value="week">THIS WEEK</option>
                <option value="month">THIS MONTH</option>
                <option value="year" selected>THIS YEAR</option>
              </select>
            </div>
            <div class="select my-1 float-end">
              <select
                  v-model="dataType"
                  name="data-type"
                  id="data-type"
                  class="data-type"
              >
                <option value="distance" selected>DISTANCE</option>
                <option value="elevation">ELEVATION</option>
              </select>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-4 col-xl-5 col-xxl-5"></div>
        </div>
      </div>
    </section>

    <section class="chart-div">
      <div class="container">
        <BarChartGenerator
            :chart-data="getChartData"
            :chart-options="getChartOption"
        />
      </div>
    </section>
  </div>
</template>
<script>
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { Bar as BarChartGenerator } from "vue-chartjs/legacy";
import { overviewMixin } from "@/mixins/overviewMixin";
import moment from "moment";
import ModalThirdPartyImport from "@/components/rider/ModalThirdPartyImport";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
export default {
  name: "OverView",
  components: {
    ModalThirdPartyImport,
    BarChartGenerator,
  },
  props: ["isActive", "userData", "totalEvents","thirdPartyData"],
  mixins: [overviewMixin],
  data() {
    return {
      thirdPartyDistance:0,
      thirdPartyTime:0,
      thirdPartyElevation:0,
      currentYear: new Date().getFullYear(),
      currentMonth: moment(new Date()),
      currentWeek:
          moment()
              .startOf("week")
              .diff(moment().startOf("month").startOf("week"), "weeks") + 1,
      durationType: "year", // Default duration type
      dataType: "distance", // Default data type
      barChartOptions: {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            mode: "index",
            intersect: false,
          },
          crosshair: false,
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            beginAtZero: true,
          },
          y: {
            beginAtZero: true,
            min: 0,
          },
        },
      },
      tableData: {},
      barChartDataSet: {
        labels: [],
        datasets: [
          {
            label: "Elevation",
            backgroundColor: "#357fdd",
            data: [],
            borderColor: "#ffffff",
            borderWidth: 2,
            barThickness: 30,
            maxBarThickness: 50,
          },
        ],
      },
    };
  },
  async mounted() {
    await this.fetchData();
    await this.handleDataChange();
    await this.addThirdPartyData();
  },
  computed: {
    getChartData() {
      return this.barChartDataSet;
    },
    getChartOption() {
      return this.barChartOptions;
    },
  },
  watch: {
    durationType: "handleDataChange",
    dataType: "handleDataChange",
  },
  methods: {
    openThirdPartyModal() {
      this.$refs.modalThirdParty.openModal();
    },
    camelCaseToWords(s) {
      const rowName = {
        timeSpend: "Time spent",
        allRides: "All rides",
        distance: "Distance",
        elevation: "Elevation ",
        longestRide: "Longest ride",
        races: "Race",
      };

      return rowName[s];
    },
    async fetchData() {
      try {
        const response = await this.$store.dispatch(
            "ride/over_grid_data",
            this.getDurationCollection()
        );

        if (!response.success) {
          return;
        }

        const getData = response.data;
        const formatValue = (value, unit) =>
            `${parseFloat(value)?.toFixed(2)} ${unit}`;
        const unitMap = {
          distance: "KM",
          timeSpend: "h",
          elevation: "M",
          longestRide: "KM",
        };
        const sortedData = {
          distance: {},
          timeSpend: {},
          elevation: {},
          longestRide: {},
          races: {},
          allRides: {},
        };

        Object.keys(getData).forEach((key) => {
          const data = getData[key];

          Object.keys(data).forEach((dataKey) => {
            if (dataKey === "name") return;

            if (unitMap[dataKey]) {
              data[dataKey] = formatValue(
                  data[dataKey]?.toFixed(2),
                  unitMap[dataKey]
              );
            }
            if (dataKey == "timeSpend") {
              sortedData[dataKey][key] =
                  (parseFloat(data[dataKey]) / 60)?.toFixed(2) + " h";
            } else {
              sortedData[dataKey][key] = data[dataKey];
            }
          });
        });

        this.tableData = sortedData;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async handleDataChange() {
      this.barChartDataSet = await this.fetchBarChartData(
          this.durationType,
          this.dataType
      );
    },
    setUserHasSeen(){
      this.$store
          .dispatch("user/setUserHasSeen")
          .then(() => {
            this.$refs.gotItParent.style.display = "none";

          })
    },
    addThirdPartyData(){

      if(this.thirdPartyData?.status === 'approved'){

        this.thirdPartyDistance = this.thirdPartyData.total_distance
        const duration = moment.duration(this.thirdPartyData.total_ride_time, 'seconds');
        this.thirdPartyTime = duration.asHours()
        this.thirdPartyElevation = this.thirdPartyData.total_elevation

      }
    }
  },
};
</script>
<style>
#nav-home > div > table > tbody > tr:nth-child(5),
#nav-home > div > table > tbody > tr:nth-child(7) {
  display: none;
}
</style>
