import Service from "@/services";

export default {
  namespaced: true,

  state: {
    authStatus: false
  },

  getters: {
    getAuthStatus(state) {
      return state.authStatus;
    },
  },

  actions: {
    async authorization(_, payload) {
      try {
        return await Service().post(`oauth`, payload);
      } catch (e) {
        console.log('oAuth Error: ', e.message);
      }
    },

    async checkNetworkAuthorize(_, payload) {
      try {
        return await Service().post('../api/oauth/check', payload);
      } catch (e) {
        console.log('oAuth Error: ', e.message);
      }
    },

    async networkAuthorize(_, payload) {
      try {
        return await Service().post('../api/oauth/authorize/code', payload);
      } catch (e) {
        console.log('oAuth Error: ', e.message);
      }
    },

  },

  mutations: {
    SET_LOADING(state, status) {
      state.authStatus = status;
    },
  }
}
